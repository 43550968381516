import React from "react";

import styles from "./alumni.module.scss";

const AlumniCardSkeleton = () => {
  return (
    <summary className={styles.skeletonCard}>
      <figure>
        <div className={styles.skeletonImage} />
        <figcaption>
          <div className={styles.skeletonTitle} />
          <div className={styles.skeletonDescription} />
        </figcaption>
      </figure>
      <div className={styles.skeletonLinkedInButton} />
    </summary>
  );
};

export default AlumniCardSkeleton;
