import { useRouter } from "next/navigation";
import React from "react";

import styles from "./notification.module.scss";
import Components from "@/components";
import utils from "@/utils";

const NotificationItem = ({ item }) => {
  const {
    type,
    user,
    course,
    video,
    createdAt,
    feedback,
    request,
    comment,
    message,
    entityId,
  } = item;
  const { name, profileImage, username } = user;
  const router = useRouter();

  const getLink = (type, entityId) => {
    switch (type) {
      case "comment":
      case "comment-like":
      case "comment-reply":
      case "comment-mention":
        if (!comment || !comment.length) break;
        if (comment?.[0]?.course)
          return `/courses/${comment[0]?.course?.seoTitle}/${comment[0]?.video?.seoTitle}#${entityId}`;
        else return `/videos/${comment[0]?.video?.seoTitle}`;
      case "video-like":
        return `/courses/${course?.seoTitle}/${video?.seoTitle}`;
      case "assignment-rejected":
        return `/courses/${course?.seoTitle}/${video?.seoTitle}`;
      case "assignment-accepted":
        return `/courses/${course?.seoTitle}/${video?.seoTitle}`;
      case "course-enrollment":
        return `https://www.admin.cipherschools.com/cipherschools/data/insights?type=course&id=${entityId}`;
      case "feedback":
        return "https://www.admin.cipherschools.com/cipherschools/data/feedbacks?filter=all";
      case "creator-request":
        return "https://www.admin.cipherschools.com/cipherschools/data/requests?filter=all";
      case "follow":
        return `/profile/${username}`;
      case "order-status-updated":
        return `/redeem/history`;
      default:
        break;
    }
  };

  return (
    <div className={styles.content}>
      <Components.Avatar
        src={profileImage}
        alt={name}
        loader="md"
        onClick={() => router.push(`/profile/${username}`)}
        errStyle={{ fontSize: "1.5rem" }}
        className={styles.icon}
      />
      <div className={styles.body}>
        <a href={getLink(type, entityId)} target="_blank">
          <p className={styles.oneliner}>
            <span>{name}</span>
            &nbsp;
            {type === "assignment-accepted" || type === "assignment-rejected"
              ? `, Assignment report: ${message}`
              : message}
          </p>
          {type === "feedback" && feedback?.length && (
            <p className={styles.oneliner}>{feedback[0]?.message}</p>
          )}
          {type === "creator-request" && request?.length && (
            <p className={styles.oneliner}>{request[0]?.formEntity.message}</p>
          )}
          {type === "certificate" && request?.length && (
            <p className={styles.oneliner}>{request[0]?.title}</p>
          )}
          {type === "report" && report?.length && (
            <p className={styles.oneliner}>{report[0]?.title}</p>
          )}
          {(type === "comment" ||
            type === "comment-reply" ||
            type === "comment-mention") &&
            comment?.length && (
              <p className={styles.oneliner}>{comment[0]?.text}</p>
            )}
        </a>
        <span className={styles.oneliner}>
          {utils.timeDifference(createdAt)} ago
        </span>
      </div>
    </div>
  );
};

export default NotificationItem;
