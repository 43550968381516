import React from "react";

import styles from "./video.module.scss";
import Components from "@/components";

function UploadedVideoCard({ video, onEditCB, onRemoveCB }) {
  const { _id, title, about, thumbnailImage } = video;
  return (
    <article className={styles["uvc-card-box"]}>
      <Components.LazyImage
        src={thumbnailImage}
        width="100%"
        height="100%"
        alt="uploaded-thumb"
        className={styles["uvc-card-image"]}
      />
      <div className={styles["uvc-card-details"]}>
        <div className={styles["left"]}>
          <h2>{title}</h2>
          <p>{about}</p>
        </div>
        <div className={styles["right"]}>
          <button className={styles["edit"]} onClick={() => onEditCB(_id)}>
            Edit
          </button>
          <button className={styles["delete"]} onClick={onRemoveCB}>
            Remove
          </button>
        </div>
      </div>
    </article>
  );
}

export default UploadedVideoCard;
