import { useParams } from "next/navigation";
import { useDispatch } from "react-redux";
import { Icon } from "@iconify/react";
import React from "react";

import plutonn from "@/assets/images/Plutonn_logo_192.png";
import courses from "@/data/premiumCourseData";
import styles from "./links.module.scss";
import services from "@/store/services";
import Image from "next/image";

const ResourceModal = () => {
  const { courseid } = useParams();
  const dispatch = useDispatch();

  const course = courses.liveCourses.find((c) => c.courseId === courseid);

  return (
    <section className={styles.resources}>
      <article>
        <p>
          Join your relevant course whatsapp group for further communication.
        </p>
        <a href={course.groupLink} target="_blank">
          <button>
            <Icon icon="mdi:whatsapp" />
            <span>Join whatsapp</span>
          </button>
        </a>
      </article>
      <article>
        <p>Microsoft teams link for live sessions.</p>
        <a href={course.teamsLink} target="_blank">
          <button>
            <Icon icon="mdi:microsoft-teams" />
            <span>Live Class Link</span>
          </button>
        </a>
      </article>
      <article>
        <p>
          Join your relevant plutonn community to interact with your mentors and
          fellow learners.
        </p>
        <a href={course.communityLink} target="_blank">
          <button>
            <Image src={plutonn} alt="plutonn" width={50} height={50} />
            <span>Join Plutonn</span>
          </button>
        </a>
      </article>
      <article>
        <p>Link to watch the recordings of your live sessions.</p>
        <a
          onClick={() =>
            dispatch(
              services.infoAlert("Check your whatsapp group description!")
            )
          }
          target="_blank"
        >
          <button>
            <Icon icon="fluent:video-recording-20-regular" />
            <span>Session Recordings</span>
          </button>
        </a>
      </article>
    </section>
  );
};

export default ResourceModal;
