"use client";

import { Icon } from "@iconify/react";
import { Box } from "@mui/material";
import Image from "next/image";
import React from "react";

import styles from "./search.module.scss";
import Components from "@/components";
import utils from "@/utils";

function SearchCard({ entity }) {
  const getRouteLink = () => {
    if (entity?.entityType === "course") {
      return `/courses/${entity?.seoTitle}/${entity.videoSeoTitle}`;
    } else if (entity?.entityType === "video") {
      if (entity?.courseInfo?.[0]) {
        return `/courses/${entity?.courseInfo[0]?.seoTitle}/${entity.videoSeoTitle}`;
      } else {
        return `/videos/${entity.videoSeoTitle}`;
      }
    }
  };

  return (
    <Box className={styles["container"]}>
      <a href={getRouteLink()} className={styles["entityImage"]}>
        <Image
          src={entity.thumbnailImage}
          alt="entity-thumbnail"
          width={400}
          height={300}
        />
      </a>
      <section className={styles["text-wrapper"]}>
        <a href={getRouteLink()}>
          <div className={styles["meta"]}>
            <div className={styles["title-wrapper"]}>
              <Components.Tooltip title={entity?.title} placement="top" arrow>
                <h2 className={styles["entity-title"]}>{entity?.title}</h2>
              </Components.Tooltip>
              <div className={styles["menu"]}>
                <Icon
                  icon={"pepicons-pencil:dots-y"}
                  className={styles["options"]}
                />
              </div>
            </div>
            <div className={styles["byline-saperator"]}>
              <div className={styles.metadata}>
                <span className={styles["views"]}>
                  {utils.formatNumber(entity?.meta?.count)}{" "}
                  <span>{entity?.meta?.text}</span>
                </span>
                <span className={styles["saperator"]}>&bull;</span>
                <span className={styles["timestamp"]}>
                  {utils.timeDifference(entity?.timestamp)} ago
                </span>
              </div>
              <div className={styles["channel-info"]}>
                <Components.Avatar
                  src={entity?.creatorInfo?.[0]?.profileImage}
                  alt={entity?.creatorInfo?.[0]?.name}
                  loader="vsm"
                  errStyle={{ fontSize: "3rem" }}
                  className={styles["channel-image"]}
                />
                <h5>{entity?.creatorInfo?.[0]?.name}</h5>
              </div>
              <div className={styles["badge"]}>
                <h4>{entity?.visibility || entity?.courseInfo?.[0]?.name}</h4>
              </div>
            </div>
          </div>
          <p className={styles["description"]}>{entity?.about}</p>
        </a>
      </section>
      <section className={styles["mb-text-wrapper"]}>
        <a href={`/profile/${entity?.creatorInfo?.[0]?.username}`}>
          <Components.Avatar
            src={entity?.creatorInfo?.[0]?.profileImage}
            alt={entity?.creatorInfo?.[0]?.name}
            loader="sm"
            errStyle={{ fontSize: "1rem" }}
            className={styles["channel-image"]}
          />
        </a>
        <a href={getRouteLink()}>
          <div className={styles["content-section"]}>
            <div className={styles["title-wrapper"]}>
              <Components.Tooltip title={entity?.title} placement="top" arrow>
                <h2 className={styles["entity-title"]}>{entity?.title}</h2>
              </Components.Tooltip>
              <div className={styles["menu"]}>
                <Icon
                  icon={"pepicons-pencil:dots-y"}
                  className={styles["options"]}
                />
              </div>
            </div>
            <div className={styles.metadata}>
              <span>{entity?.creatorInfo?.[0]?.name}</span>
              <span className={styles["saperator"]}>&bull;</span>
              <span className={styles["views"]}>
                {utils.formatNumber(entity?.meta?.count)} {entity?.meta?.text}
              </span>
              <span className={styles["saperator"]}>&bull;</span>
              <span className={styles["timestamp"]}>
                {utils.timeDifference(entity?.timestamp)} ago
              </span>
            </div>
          </div>
        </a>
      </section>
    </Box>
  );
}

export default SearchCard;
