"use client";

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";

import services from "@/store/services";
import Components from "@/components";
import "./password.scss";
import api from "@/api";

function PassModal({ load }) {
  const user = useSelector((state) => state.user.users);
  const [confPass, setConfPass] = useState("");
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setOldPass("");
    setNewPass("");
    setConfPass("");
    setError("");
  }, [user]);

  const handleShowPass = (id) => {
    const passField = document.querySelector(id);
    if (passField.type === "password") passField.type = "text";
    else passField.type = "password";
  };

  const handleModalSave = async () => {
    if (newPass === confPass) {
      load(1);
      const response = await api.updatepassword({
        oldPassword: oldPass,
        password: newPass,
      });
      if (response.data?.success < 400) {
        dispatch(services.successAlert("Password changed successfully!"));
        setError("");
        load(0);
        dispatch(services.fetchUserDetails());
        dispatch(services.fetchUser());
        dispatch(services.togglePasswordModal());
      } else {
        dispatch(services.failureAlert("Change unsuccessful!"));
        load(0);
      }
    } else {
      setError("Password and confirm password are not same!");
    }
  };

  return (
    <section>
      <div className="pdm-login-error">
        <span>{error}</span>
      </div>
      <form className="pm-input-box">
        <div className="pdm-input-password">
          <Components.TextInput
            type="password"
            placeholder="Current Password"
            value={oldPass}
            onChange={(e) => setOldPass(e)}
          />
          <Icon icon={"mdi:eye"} onClick={() => handleShowPass("#oldpass")} />
        </div>
      </form>
      <div className="pm-input-box">
        <div className="pdm-input-password">
          <Components.TextInput
            type="password"
            placeholder="New Password"
            value={newPass}
            onChange={(e) => setNewPass(e)}
          />
          <Icon icon={"mdi:eye"} onClick={() => handleShowPass("#newpass")} />
        </div>
      </div>
      <div className="pm-input-box">
        <div className="pdm-input-password">
          <Components.TextInput
            type="password"
            placeholder="Confirm Password"
            value={confPass}
            onChange={(e) => setConfPass(e)}
          />
          <Icon icon={"mdi:eye"} onClick={() => handleShowPass("#cnfpass")} />
        </div>
      </div>
      <footer className="pm-pass-btns">
        <button
          className="pm-cancel-btns"
          onClick={() => dispatch(services.togglePasswordModal())}
        >
          Cancel
        </button>
        <button className="pm-save-btns" onClick={handleModalSave}>
          Save
        </button>
      </footer>
    </section>
  );
}

export default PassModal;
