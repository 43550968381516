import React from "react";
import styles from "./paid.module.scss";

function PaidModal(props) {
  const { message, handleClick } = props;
  return (
    <div className={styles.PaidModal}>
      <p>{message}</p>
      <div>
        <button onClick={handleClick} className={styles.btn}>
          Continue learning
        </button>
      </div>
    </div>
  );
}

export default PaidModal;
