const PRE_ENROLLMENT_TOUR_DATA = [
  {
    content: (
      <p>
        Complete your learning journey, enroll now and earn a certificate to
        showcase your achievement!
      </p>
    ),
    title: <h3>Watched 3 Videos? Enroll Now!</h3>,
    placement:
      typeof window === "undefined"
        ? "top"
        : window.innerWidth < 961
          ? window.innerWidth < 360
            ? "center"
            : "top"
          : "top",
    target:
      typeof window === "undefined"
        ? "#vd-enroll-btn"
        : window.innerWidth < 961
          ? window.innerWidth < 360
            ? body
            : "#vd-enroll-btn"
          : "#vd-enroll-btn",
  },
];

export default PRE_ENROLLMENT_TOUR_DATA;
