"use client";

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "next/navigation";

import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";

import { socketFeedbackSend } from "@/socket";
import SocketContext from "@/socket/Context";
import services from "@/store/services";
import Components from "@/components";
import "./feedback.scss";
import api from "@/api";

function FeedbackModal() {
  const { socket } = useContext(SocketContext).SocketState;

  const { courseInfo, videoInfo } = useSelector((state) => state.video);
  const { courseName, videoTitle } = useParams();
  const [FeedbackList, setFeedbackList] = useState([]);

  const [reqText, setReqText] = useState({
    type: courseName ? "Video Report" : "",
    name: "",
    email: "",
    contact: "",
    feedback: "",
    suggestion: "",
  });
  const user = useSelector((state) => state?.user?.users);
  const [loading, setLoading] = useState(false);
  const [onNext, setOnNext] = useState(false);
  const dispatch = useDispatch();

  // let FeedbackList = [];
  useEffect(() => {
    let FeedbackList;

    if (courseName) {
      FeedbackList = [
        {
          value: "Comments",
        },
        {
          value: "Questions",
        },
        {
          value: "Bug Reports",
        },
        {
          value: "Feature Requests",
        },
        {
          value: "Video Report",
        },
        {
          value: "Others",
        },
      ];
    } else {
      FeedbackList = [
        {
          value: "Comments",
        },
        {
          value: "Questions",
        },
        {
          value: "Bug Reports",
        },
        {
          value: "Feature Requests",
        },
        {
          value: "Others",
        },
      ];
    }

    setFeedbackList(FeedbackList);
  }, []);

  useEffect(() => {
    if (user.email)
      setReqText((prev) => ({
        ...prev,
        name: user.name,
        email: user.email,
        contact: user.mobileNo || "",
      }));
  }, [user]);

  const defaultProps = {
    options: FeedbackList,
    getOptionLabel: (option) => option.value ?? "",
  };

  const isEmpty = () => {
    if (
      reqText.name === "" ||
      reqText.email === "" ||
      reqText.contact === "" ||
      reqText.message === "" ||
      reqText.type === ""
    )
      return true;
    else return false;
  };

  const handleOnSubmit = async () => {
    if (!isEmpty()) {
      setLoading(true);
      let newFeedback = {
        feedbackType: reqText.type,
        message: reqText.message,
        suggestion: reqText.suggestion,
        type: "feedback",
        userEntity: {
          name: reqText.name,
          email: reqText.email,
          contact: reqText.contact,
          id: user?._id || null,
        },
      };
      if (courseName) newFeedback.courseId = courseInfo?._id;

      if (videoTitle) newFeedback.videoId = videoInfo._id;

      try {
        const response = await api.postFeedback(newFeedback);
        if (response.data?.success < 400) {
          socketFeedbackSend(
            {
              userId: user?._id,
              entityId: response.data?.result?._id,
            },
            socket
          );
          dispatch(
            services.successAlert(
              "Feedback submitted, Our team will take needful action."
            )
          );
          setReqText({
            type: "",
            name: "",
            email: "",
            contact: "",
            feedback: "",
            suggestion: "",
          });
          setOnNext(false);
          setLoading(false);
          dispatch(services.toggleFeedbackModal());
        } else {
          setLoading(false);
          dispatch(
            services.failureAlert(
              response.data?.error ||
                response.data?.data?.error ||
                "Something went wrong"
            )
          );
        }
      } catch (error) {
        dispatch(
          services.failureAlert(
            "Your feedback can't be submit at this point of time"
          )
        );
        setLoading(false);
      }
    }
  };

  return (
    <section className="feedback">
      <div className="sf-textfield-row">
        <Autocomplete
          {...defaultProps}
          className="sf-textfield"
          defaultValue={reqText?.type ?? ""}
          disablePortal
          onChange={(e, v) =>
            setReqText((prev) => ({ ...prev, type: v.value }))
          }
          autoHighlight
          blurOnSelect
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Feedback Type."
              variant="standard"
            />
          )}
        />
      </div>
      <div className="sf-textfield-row">
        <input
          placeholder="Enter Your Name"
          value={reqText.name}
          className="sf-textfield"
          onChange={(e) =>
            setReqText((prev) => ({
              ...prev,
              name: e.target.value,
            }))
          }
        />
      </div>

      <div className="sf-textfield-row">
        <input
          placeholder="Email Address"
          value={reqText.email}
          className="sf-textfield"
          onChange={(e) =>
            setReqText((prev) => ({ ...prev, email: e.target.value }))
          }
        />
      </div>

      <div className="sf-textfield-row">
        <input
          placeholder="Contact Number"
          value={reqText.contact}
          className="sf-textfield"
          onChange={(e) =>
            setReqText((prev) => ({ ...prev, contact: e.target.value }))
          }
        />
      </div>

      <div className="sf-textfield-msg">
        {!onNext ? (
          <textarea
            className="sf-msg-cont"
            value={reqText.message}
            onChange={(e) =>
              setReqText((prev) => ({
                ...prev,
                message: e.target.value,
              }))
            }
            placeholder="Enter your message here..."
          />
        ) : (
          <textarea
            className="sf-msg-cont"
            value={reqText.suggestion}
            onChange={(e) =>
              setReqText((prev) => ({
                ...prev,
                suggestion: e.target.value,
              }))
            }
            placeholder="Suggestions for improvement"
          />
        )}
      </div>
      <div className="sf-btns-cont">
        <button
          className={isEmpty() ? "sf-msg-btn1" : "sf-msg-btn1 sf-btn-active"}
          disabled={isEmpty()}
          onClick={() => setOnNext((prev) => !prev)}
        >
          {onNext ? "Back" : "Next"}
        </button>
        {onNext ? (
          <Components.LoadingBtn
            loading={loading}
            className="sf-msg-btn sf-btn-active"
          >
            <button
              disabled={isEmpty()}
              className={isEmpty() ? "sf-msg-btn" : "sf-msg-btn sf-btn-active"}
              onClick={handleOnSubmit}
            >
              Submit
            </button>
          </Components.LoadingBtn>
        ) : null}
      </div>
    </section>
  );
}

export default FeedbackModal;
