import { useRouter } from "next/navigation";
import { useDispatch } from "react-redux";
import { Icon } from "@iconify/react";
import Image from "next/image";
import React from "react";

import styles from "./resume.module.scss";
import services from "@/store/services";
import Components from "@/components";
import Images from "@/assets/images";
import utils from "@/utils";
import apis from "@/api";

function ResumeCard(props) {
  const {
    _id,
    templateId,
    personalInfo,
    updatedAt,
    handleEdit,
    handleDelete,
    ...other
  } = props;

  const dispatch = useDispatch();
  const router = useRouter();

  const templateImage = {
    1: Images.Template1,
    2: Images.Template2,
    3: Images.Template3,
  };

  const templateName = {
    1: "Fresher",
    2: "Intermediate",
    3: "Experience",
  };

  const handleView = () => {
    utils.deletebycollection("pdfBlob");
    apis
      .getFirstPdf(_id, templateId)
      .then((res) => {
        utils.createdb("pdfBlob");
        const blob = new Blob([res.data], { type: "application/pdf" });
        utils.addbykey("pdfBlob", blob, "resumeBlob").then(() => {
          dispatch(services.successAlert("Resume is ready to download."));

          router.push(
            `/resume-builder/${utils.getTemplateById(templateId)}/download`
          );
        });
      })
      .catch((err) => {
        dispatch(services.failureAlert(err.message || "Something went wrong!"));
      });
  };

  return (
    <article
      className={styles.container}
      onClick={handleView}
      id={`PD_ResumeCard`}
    >
      <section className={styles.left}>
        <div className={styles.top}>
          <p className={styles.date}>
            {new Date(updatedAt).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </p>
          <h2>{`${personalInfo.firstName} ${personalInfo.lastName}`}</h2>
          <p className={styles.category}>{templateName[templateId]}</p>
        </div>
        <div className={styles.footer}>
          <button
            id={`ResumeCard_Delete_Btn`}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
          >
            Delete
          </button>
          <button
            id={`ResumeCard_Edit_Btn`}
            onClick={(e) => {
              e.stopPropagation();
              handleEdit({ resumeId: _id, templateId });
            }}
          >
            Edit
          </button>
        </div>
      </section>
      <section className={styles.right}>
        <Image
          src={templateImage[templateId]}
          alt={"Fresher-template"}
          width={400}
          height={200}
        />
        <div className={styles.action}>
          <Components.Tooltip title="Edit" placement="left">
            <Icon
              icon="mdi:pencil"
              onClick={(e) => {
                e.stopPropagation();
                handleEdit({ resumeId: _id, templateId });
              }}
            />
          </Components.Tooltip>
          <Components.Tooltip title="Delete" placement="left">
            <Icon
              icon="mdi:delete"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete();
              }}
            />
          </Components.Tooltip>
        </div>
      </section>
    </article>
  );
}

export default ResumeCard;
