import React from "react";

import styles from "./header.module.scss";

function Header(props) {
  const { active, setActive } = props;

  return (
    <header className={styles["container"]}>
      <div className={styles["header"]}>
        <h3>Make your course notes.</h3>
      </div>
      <ul>
        <li
          className={active === "video" ? styles["active"] : ""}
          onClick={() => setActive("video")}
        >
          Video Notes
        </li>
        <li
          className={active === "course" ? styles["active"] : ""}
          onClick={() => setActive("course")}
        >
          All Notes
        </li>
      </ul>
    </header>
  );
}

export default Header;
