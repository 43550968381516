import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";

// import { PlayerAttributes } from "@/utilities/Player";
import styles from "./field.module.scss";
import services from "@/store/services";
import utils from "@/utils";
import apis from "@/api";
import { PlayerAttributes } from "@/utilities/Player";

const wordLimit = 1000;

function NoteField(props) {
  const { noteInfo, load } = props;

  const { videoInfo } = useSelector((state) => state.video);
  const [timestamp, setTimestamp] = useState(noteInfo?.timestamp || 0);
  const [show, setShow] = useState(noteInfo?.title ? true : false);
  const [title, setTitle] = useState(noteInfo?.title || "");
  const [text, setText] = useState(noteInfo?.text || "");
  const textAreaRef = useRef(null);
  const dispatch = useDispatch();

  const handleAddClick = async () => {
    // Placeholder for adding functionality
    if (!title) {
      dispatch(services.warningAlert("Title is missing"));
    } else if (title.split(" ").length < 2) {
      dispatch(
        services.warningAlert("Notes title should have at least 2 words!")
      );
    } else if (!text) {
      dispatch(services.warningAlert("Notes text is missing"));
    } else if (text.split(" ").length < 8) {
      dispatch(
        services.warningAlert("Notes text should have at least 8 words!")
      );
    } else {
      let response;
      if (!noteInfo) {
        response = await apis.addNote({
          title,
          text,
          timestamp,
          videoId: videoInfo._id,
          courseId: videoInfo.courseId,
        });
      } else {
        response = await apis.updateNote(noteInfo._id, {
          title,
          text,
          timestamp,
        });
      }
      if (response.status < 400) {
        setTitle("");
        setText("");
        setTimestamp(0);
        dispatch(
          services.successAlert(
            `note ${noteInfo ? "updated" : "added"} successfully!`
          )
        );
        setShow(false);
        load();
      } else
        dispatch(
          services.failureAlert(response.data.error || "Something went wrong!")
        );
    }
  };

  const handleCancelClick = () => {
    // Placeholder for canceling functionality
    setTimestamp(0);
    setShow(false);
    setTitle("");
    setText("");
  };

  useEffect(() => {
    if (noteInfo?.title && !show) {
      setShow(true);
      setText(noteInfo.text);
      setTitle(noteInfo.title);
      setTimestamp(noteInfo.timestamp);
    }
  }, [noteInfo]);

  useEffect(() => {
    const ply = PlayerAttributes();
    const player = ply.getCurrentPlayer();
    if (show && player) setTimestamp(player.currentTime());
  }, [show]);

  return (
    <article className={styles["container"]}>
      <button onClick={() => setShow((prev) => !prev)} id="vd_TakeNotes_Btn">
        <h3>Take Notes</h3>
        <Icon icon={"gg:add"} />
      </button>
      {show ? (
        <div className={`${styles["field"]} ${show ? styles["show"] : ""}`}>
          <div className={styles["title"]}>
            <input
              type="text"
              value={title}
              placeholder="Notes title"
              onChange={(e) => setTitle(e.target.value)}
            />
            <div className={styles["label"]}>
              <div className={styles["left"]}>
                <Icon icon={"mdi:alarm"} />
                <p>{utils.secondsToHMS(timestamp)}</p>
              </div>
              <span className={styles["right"]}>
                {wordLimit - text.length} words left
              </span>
            </div>
          </div>
          <textarea
            type="text"
            value={text}
            ref={textAreaRef}
            spellCheck="false"
            placeholder="Make your notes here."
            onChange={(e) => {
              setText(e.target.value);
            }}
            className={styles["text"]}
          />
          <footer>
            <div className={styles["left"]}>
              {/* <button
              className={styles["bold"]}
              onClick={() => handleFormatClick("bold")}
            >
              B
            </button>
            <button
              className={styles["italic"]}
              onClick={() => handleFormatClick("italic")}
            >
              I
            </button> */}
            </div>
            <div className={styles["right"]}>
              <button onClick={handleAddClick} id="vd_Notes_Add">
                Add
              </button>
              <button onClick={handleCancelClick} id="vd_Notes_Cancel">
                Cancel
              </button>
            </div>
          </footer>
        </div>
      ) : null}
    </article>
  );
}

export default NoteField;
