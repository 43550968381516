import React from "react";
import styles from "./button.module.scss";

const Button = ({ handleClick, name, className, icon, disable, id }) => {
  return (
    <button
      id={id}
      disabled={disable}
      onClick={handleClick}
      className={`${styles.button} ${className}`}
    >
      {name}
      {icon}
    </button>
  );
};

export default Button;
