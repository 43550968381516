/*
props:
label - string (for the label string)
leftIcon - component (if any icon is to be added)
TODO: cancel - boolean (if the user needs cancel/clear button)

!Functional props
value -> value to display
onChange -> onChange handler method

TODO:
classname property
*/

import React from "react";
import { Icon } from "@iconify/react";

import styles from "./input.module.scss";

function TextInput(props) {
  const { label, leftIcon, onChange, type, children, ...others } = props;

  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <article className={styles["profileModal-input-field-container"]}>
      {label?.length > 0 && (
        <div className={styles["input-label"]}>{label}</div>
      )}
      <div className={styles["input-field"]}>
        {leftIcon && (
          <div className={styles["left-icon"]}>
            <Icon icon={leftIcon} className={styles["left-icon"]} />
          </div>
        )}
        <input
          disabled={others.disabled || false}
          type={type ?? "text"}
          onChange={handleChange}
          className={styles["normal-text-input"]}
          {...others}
        />
        {children}
      </div>
    </article>
  );
}

export default TextInput;
