"use client";

import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { usePathname } from "next/navigation";
import { Icon } from "@iconify/react";
import Link from "next/link";
import React from "react";

import { CourseUpload, CreatorItems } from "@/data/ProfileSidebarItem";
import styles from "./creators.module.scss";

function CreatorsMenu(props) {
  const { isOpen, uploadMenu, handleClick } = props;
  const pathname = usePathname();

  return (
    <List>
      <header
        className={
          isOpen ? styles["divider-title"] : styles["divider-title-close"]
        }
      >
        Instructor
      </header>
      {CreatorItems.map((item, i) => (
        <React.Fragment key={i}>
          <ListItem
            id={item.id}
            href={item.path}
            onClick={() => handleClick(item)}
            component={item.path ? Link : "button"}
            className={` ${styles["list-item"]} ${
              pathname === item.path ? styles.active : styles.inactive
            }`}
          >
            <ListItemIcon className={styles["item-icon"]}>
              <div className={item.type === "new" ? styles["ms-ripple"] : ""}>
                {item.icon}
              </div>
              {!isOpen ? (
                <div className={styles["item-text"]}>{item.subtitle}</div>
              ) : null}
            </ListItemIcon>
            {isOpen ? <ListItemText>{item.title}</ListItemText> : null}
            {item.title === "New Upload" ? (
              <Icon
                icon={"ic:round-keyboard-arrow-down"}
                className={uploadMenu ? styles["close"] : styles["open"]}
              />
            ) : null}
          </ListItem>
          {item.title === "New Upload" ? (
            <Collapse key={i} in={uploadMenu} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                className={styles["upload-menu"]}
              >
                {CourseUpload.map((item, i) => (
                  <ListItem
                    key={i}
                    id={item.id}
                    href={item.path}
                    onClick={() => handleClick(item)}
                    component={item.path ? Link : "button"}
                    className={` ${styles["list-item"]} ${
                      pathname === item.path ? styles.active : styles.inactive
                    }`}
                  >
                    <ListItemIcon className={styles["item-icon"]}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText>{item.title}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          ) : null}
        </React.Fragment>
      ))}
    </List>
  );
}

export default CreatorsMenu;
