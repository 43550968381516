import React from "react";

import styles from "./premium-course.module.scss";
import Button from "@/components/atoms/buttons/Button";

function PremiumCourseEnrollWarn() {
  return (
    <section className={styles.container}>
      <p>
        This course is part of our premium collection. To continue your learning
        journey, enroll now on our official platform.
      </p>
      <Button
        handleClick={() =>
          window.open("https://www.cs.cipherschools.com/", "_blank")
        }
        className={styles.btn}
        name={"Enroll Now"}
      />
    </section>
  );
}

export default PremiumCourseEnrollWarn;
