import React from "react";

import styles from "./intro.module.scss";

function Intro() {
  return (
    <section className={styles.container}>
      <article>
        <h2>What is CipherPoints?</h2>
        <p>
          CipherPoints are a type of credit you earn on the platform. You can
          accumulate these points by completing various daily missions and
          watching videos. Each activity rewards you with a certain number of
          CipherPoints, which you can then use for various benefits or rewards
          within the platform. This system encourages regular engagement and
          motivates users to complete the courses they have enrolled in.{" "}
        </p>
      </article>
      <article>
        <h2>How you can earn CipherPoints?</h2>
        <p>
          You can earn CipherPoints by investing your time in learning on the
          platform. For every minute you spend watching videos, you'll receive 1
          CipherPoint. Additionally, you can earn CipherPoints by completing
          your daily missions. To explore more, visit{" "}
          <a href="/redeem/earnpoints">this link</a>.
        </p>
      </article>
      <article>
        <h2>How you can redeem CipherPoints?</h2>
        <p>
          You can redeem CipherPoints for rewards like T-shirts. After redeeming
          once, you will need to complete another course from scratch before you
          can use your CipherPoints to redeem your next reward.
        </p>
      </article>
      <article>
        <h2>Is there any expiration time?</h2>
        <p>
          Yes, there is an expiration of <span>60 days</span> for your daily
          earned CipherPoints.
        </p>
      </article>
    </section>
  );
}

export default Intro;
