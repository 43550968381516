import React from "react";
import { Alert } from "@mui/material";

import "./confirmation.scss";

function ConfirmationAlert({
  onClose,
  children,
  onCancel,
  onNo,
  onContinue,
  type,
}) {
  return (
    <Alert
      onClose={onClose || onCancel}
      severity="info"
      color="warning"
      className="tab-container"
    >
      <div className="tab-box">
        <div className="tab-left">{children}</div>
        <div className="tab-right">
          <button className="tab-btn" onClick={onContinue}>
            {type === "3" ? "Draft" : "Yes"}
          </button>
          {type === "2" || type === "3" ? (
            <button className="tab-btn tab-second-btn" onClick={onNo}>
              {type === "3" ? "New" : "NO"}
            </button>
          ) : null}
        </div>
      </div>
    </Alert>
  );
}

export default ConfirmationAlert;
