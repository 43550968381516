"use client";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { Icon } from "@iconify/react";

import "react-datepicker/dist/react-datepicker.css";
import styles from "./date-picker.module.scss";
import "./date-picker.css";

const CustomInputWithIcon = React.forwardRef(
  ({ value, onClick, placeholder }, ref) => (
    <div className={styles["custom-input-container"]} onClick={onClick}>
      <input
        type="text"
        readOnly
        value={value}
        ref={ref}
        placeholder={placeholder}
        className={styles["date-input"]}
      />
      <Icon icon="mdi:calendar" className={styles["calendar-icon"]} />
    </div>
  )
);

CustomInputWithIcon.displayName = "CustomInputWithIcon";

const MyDatePicker = (props) => {
  const {
    label,
    onChange,
    value,
    maxDate,
    minDate,
    className,
    placeholder,
    format,
    type = "date",
  } = props;

  const [selectedDate, setSelectedDate] = useState(null);
  const showMonthYearPicker = format === "MM/yyyy";
  const showYearPicker = format.toLowerCase() === "yyyy";

  const theme = useSelector((state) => state.theme);

  useEffect(() => {
    let selectedDate = "";
    if (value === "present") selectedDate = null;
    else selectedDate = value ? new Date(value) : null;

    setSelectedDate(selectedDate ? new Date(selectedDate) : "");
  }, [value]);

  const handleDateChange = (date) => {
    if (date) {
      let adjustedDate;
      if (showYearPicker) {
        adjustedDate = new Date(date.getFullYear(), 11, 31, 23, 59, 59, 999);
      } else if (showMonthYearPicker) {
        adjustedDate = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0,
          23,
          59,
          59,
          999
        );
      } else {
        adjustedDate = date;
      }
      onChange(adjustedDate.toISOString());
    } else {
      onChange(null);
    }
  };

  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

  return (
    <article className={styles["profileModal-input-field-container"]}>
      {label && <div className={styles["input-label"]}>{label}</div>}
      <DatePicker
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={format}
        selected={selectedDate}
        showTwoColumnMonthYearPicker
        placeholderText={placeholder}
        showYearPicker={showYearPicker}
        fixedHeight
        renderMonthContent={renderMonthContent}
        showMonthYearPicker={showMonthYearPicker}
        onChange={(date) => handleDateChange(date)}
        className={`${styles["date-input"]} ${className}`}
        customInput={<CustomInputWithIcon />}
      />
    </article>
  );
};

export default MyDatePicker;
