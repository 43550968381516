"use client";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useGetUploadCourseStatus = () => {
  const course = useSelector((state) => state?.courseDetails?.courseInfo);
  const [value, setValue] = useState([]);

  useEffect(() => {
    if (course) {
      const {
        // course fields
        title,
        ctfTitle,
        visibility,
        // landing page details
        about,
        price,
        // creator message details
        welcomeEmail,
        congratulationsEmail,
        // curriculum
        curriculum,
        mode,
        addOn,
        referralDiscount,
      } = course;

      const isCourseCreated =
        title?.length && ctfTitle?.length && visibility?.length;
      const landingPageStatus = about?.length;
      const creatorMessageStatus =
        welcomeEmail?._id && congratulationsEmail?._id;
      let curriculumStatus;
      if (mode === "online" || mode === "offline") {
        curriculumStatus =
          curriculum?.length &&
          curriculum?.find((item) => item?.subTopics?.length > 0);
      } else {
        curriculumStatus =
          curriculum?.length &&
          curriculum?.find((item) => item?.content?.length > 0);
      }
      const promotionStatus = addOn?.length && referralDiscount?.sender;
      const newValue = [];
      if (isCourseCreated) newValue.push("course-structure");
      if (landingPageStatus) newValue.push("landing-page");
      if (curriculumStatus) newValue.push("curriculam");
      if (price && price !== "") newValue.push("pricing");
      if (creatorMessageStatus) newValue.push("creator-message");
      if (promotionStatus) newValue.push("promotions");

      setValue([...newValue]);
    }
  }, [course]);

  return value;
};

export default useGetUploadCourseStatus;
