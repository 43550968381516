import React, { useEffect, useState } from "react";

import SuggestedUserCard from "./SuggestedUserCard";
import styles from "./users.module.scss";
import apis from "@/api";

function TopLearners() {
  const [topLearners, setTopLearners] = useState([]);

  const getTopLearners = async () => {
    const res = await apis.getTopLearners();
    if (res.status === 200) {
      setTopLearners(res?.data?.result);
    }
  };

  useEffect(() => {
    getTopLearners();
  }, []);

  return (
    <article className={styles["container"]}>
      <h2>Top learners</h2>
      <div className={styles["cards"]}>
        {topLearners.length > 0
          ? topLearners?.map((learner, i) => (
              <React.Fragment key={i}>
                <SuggestedUserCard
                  showCP={true}
                  key={learner?._id}
                  user={learner?.userInfo}
                  points={learner?.totalPoints}
                />
                <div className={styles["divider"]}></div>
              </React.Fragment>
            ))
          : null}
      </div>
    </article>
  );
}

export default React.memo(TopLearners);
