import { useRouter } from "next/navigation";
import { useSelector } from "react-redux";
import React from "react";

import styles from "./redeem.module.scss";
import Components from "@/components";

const RedeemModal = () => {
  const user = useSelector((state) => state.user.users);
  const router = useRouter();

  const progress = Math.floor((user?.profileCpStatus?.length / 7) * 100);

  return (
    <section className={styles.redeemModal}>
      <p>
        Your profile is {progress}% completed only. You must complete 60% of
        your profile to claim reward.
      </p>
      <footer>
        <Components.Button
          className={styles.btn}
          name="Update"
          handleClick={() => router.push("/profile/me")}
        />
        <Components.Button
          className={styles.btn}
          name="Explore"
          handleClick={() => location.reload()}
        />
      </footer>
    </section>
  );
};

export default RedeemModal;
