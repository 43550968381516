import React from "react";

import Tooltip from "@/components/atoms/tooltip";
import Image from "@/components/shared/avatar";
import Components from "@/components";
import utils from "@/utils";

import styles from "./creator.module.scss";

function SearchCreator({ entity }) {
  return (
    <article className={styles["container"]}>
      <a
        href={`/profile/${entity?.username}`}
        className={styles["avatar-section"]}
      >
        <Image
          loader="xxxl"
          alt={entity?.name}
          className={styles["avatar"]}
          src={entity?.profileImage}
          errStyle={{ fontSize: "3rem" }}
        />
      </a>
      <div className={styles["info-section"]}>
        <a
          href={`/profile/${entity?.username}`}
          className={styles["profile-link"]}
        >
          <Tooltip placement="bottom" title={entity?.name}>
            <h3>{entity.name}</h3>
          </Tooltip>
          <div className={styles["meta"]}>
            <span>@{entity.username}</span>
            <span className={styles["saperator"]}>&bull;</span>
            <span>
              {utils.formatNumber(entity?.meta?.count)} {entity?.meta?.text}
            </span>
          </div>
          <p className={styles["about"]}>{entity.about}</p>
        </a>
        <div className={styles["action-section"]}>
          <Components.FollowBtn
            user={entity}
            className={styles["follow-btn"]}
          />
        </div>
      </div>
      <div className={styles["mb-info-section"]}>
        <a
          href={`/profile/${entity?.username}`}
          className={styles["profile-link"]}
        >
          <Tooltip placement="bottom" title={entity?.name}>
            <h3>{entity.name}</h3>
          </Tooltip>
          <div className={styles["meta-name"]}>@{entity.username}</div>
          <div className={styles["meta-details"]}>
            {utils.formatNumber(entity?.meta?.count)} {entity?.meta?.text}
          </div>
        </a>
        <div className={styles["action-section"]}>
          <Components.FollowBtn
            user={entity}
            className={styles["follow-btn"]}
          />
        </div>
      </div>
    </article>
  );
}

export default SearchCreator;
