"use client";

import React, { useState, useRef, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Icon } from "@iconify/react";
import Image from "next/image";

import styles from "./carousals.module.scss";
import sk from "@/skeletons";
import apis from "@/api";

function RightArrow({ onClick, hasNext }) {
  return (
    <button
      className={`${styles["nav"]} ${styles["right"]}`}
      id={`Carousle_Right_Arrow_Btn`}
      onClick={onClick}
      disabled={!hasNext}
    >
      <Icon icon={"mdi:keyboard-arrow-right"} className={styles["svg"]} />
    </button>
  );
}

function LeftArrow({ onClick, hasPrev }) {
  return (
    <button
      id={`Carousle_Left_Arrow_Btn`}
      className={` ${styles["nav"]} ${styles["left"]}`}
      onClick={onClick}
      disabled={!hasPrev}
    >
      <Icon icon={"mdi:keyboard-arrow-left"} className={styles["svg"]} />
    </button>
  );
}
function Carousals() {
  const [loading, setLoading] = useState(false);
  const crousalMenu = useRef([]);

  useEffect(() => {
    async function fetchCarousel() {
      setLoading(true);
      const { data } = await apis.carousals();
      data?.result?.map((carousel, i) =>
        crousalMenu.current.push({
          key: i,
          title: carousel.title,
          tagline: carousel.subheading,
          type: carousel.type,
          btnText: carousel.btnText,
          clickLink: carousel.clickLink,
          image: carousel.image,
        })
      );
      setLoading(false);
    }

    fetchCarousel();
  }, []);

  return (
    <>
      {!loading ? (
        <Carousel
          showIndicators={false}
          showArrows={true}
          autoPlay={false}
          interval={6000}
          showThumbs={false}
          showStatus={false}
          stopOnHover={true}
          infiniteLoop={true}
          transitionTime={1000}
          className={styles.rootCarousel}
          renderArrowPrev={(onClickHandler, hasPrev) => (
            <LeftArrow onClick={onClickHandler} hasPrev={hasPrev} />
          )}
          renderArrowNext={(onClickHandler, hasNext) => (
            <RightArrow onClick={onClickHandler} hasNext={hasNext} />
          )}
        >
          {crousalMenu.current?.map((item, index) => (
            <article key={index} className={styles.carousel}>
              <div className={styles["carausal-laptop"]}>
                <Image
                  src={item?.image[1]}
                  alt={"Carousel-image"}
                  width={1920}
                  height={660}
                />
              </div>
              <div className={styles["carausal-mobile"]}>
                <Image
                  src={item?.image[0]}
                  alt={"Carousel-Image"}
                  width={1920}
                  height={1080}
                />
              </div>
              <div className={styles.content}>
                <h2 className={styles.heading}>{item.title}</h2>
                <p className={styles["para-heading"]}>{item.tagline}</p>
                <a
                  href={item.clickLink}
                  className={styles["button"]}
                  id={`CP_Carousle_${index}_${item.btnText}_Btn`}
                  target={item.type !== "courses" ? "_blank" : ""}
                >
                  {item.btnText}
                </a>
              </div>
            </article>
          ))}
        </Carousel>
      ) : (
        <div className={styles["cr-skeleton"]}>
          <div className={`skeleton ${styles["carausal-image"]}`}></div>
          <div className={styles.dskcontent}>
            <sk.SkeletonElement type="sk-crousal-headline" />
            <sk.SkeletonElement type="sk-crousal-subheading" />
            <sk.SkeletonElement type="sk-crousal-subheading" />
            <sk.SkeletonElement type="sk-watch-btn" />
          </div>
          <div className={styles.mbcontent}>
            <sk.SkeletonElement type="sk-crousal-headline" />
            <sk.SkeletonElement type="sk-crousal-subheading" />
            <sk.SkeletonElement type="sk-crousal-subheading" />
            <sk.SkeletonElement type="sk-watch-btn" />
          </div>
        </div>
      )}
    </>
  );
}

export default Carousals;
