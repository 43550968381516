"use client";

import React, { useCallback, useState, useEffect, useContext } from "react";
import { useSearchParams, useRouter } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { Icon } from "@iconify/react";
import Compressor from "compressorjs";

import SocketContext from "@/socket/Context";
import services from "@/store/services";
import Components from "@/components";
import "./personal.scss";
import api from "@/api";

function PersonalDetails() {
  const userDetails = useSelector((state) => state.userDetails);
  const user = useSelector((state) => state?.user?.users);
  const { socket } = useContext(SocketContext).SocketState;
  const [alternateEmail, setAlternateEmail] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [userName, setUserName] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");
  const [error, setError] = useState("");
  const [city, setCity] = useState("");
  const [DOB, setDOB] = useState("");
  const dispatch = useDispatch();

  const searchParams = useSearchParams();

  const router = useRouter();

  const [isAlert, setIsAlert] = useState({
    show: false,
    status: "",
    key1: "",
    key2: "",
  });

  useEffect(() => {
    if (user.email) {
      setAlternateEmail(user.alternateEmail);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setUserName(user.username);
      setMobileNo(user.mobileNo);
    }
  }, [user]);

  useEffect(() => {
    setDOB(userDetails.contact.birthDate);
    setCity(userDetails.contact.address.city);
    setCountry(userDetails.contact.address.country);
    setPincode(userDetails.contact.address.pincode);
    setAddress(userDetails.contact.address.addressText);
  }, [userDetails]);

  const compressImage = async (file) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        mimeType: "image/webp",
        success: (result) => {
          resolve(new File([result], file.name, { type: result.type }));
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    setLoading(true);
    const compressedFile = await compressImage(acceptedFiles[0]);
    const response = await api.profileUpload([compressedFile]);
    if (response.data?.success < 400) {
      dispatch(services.fetchUser());
      dispatch(services.successAlert("Profile picture updated successfully!"));
    } else {
      dispatch(services.failureAlert("Can't update profile picture!"));
    }
    setLoading(false);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  //Username update
  const handleCheckUsername = async () => {
    if (userName) {
      if (userName.trim().toLowerCase() === user.username) {
        setError("username is same!");
        return;
      }
      const response = await api.checkusername(userName.trim());
      if (response.status === 200) {
        setError(`You can change username ${user?.canChange} times!`);
        setIsValid(true);
      } else if (response.status === 400) setError(response.data?.error);
      else if (response.status === 409) {
        setError("Username already exists!");
        setSuggestions(response.data?.result);
      } else {
        setError("Something went wrong!");
      }
    }
  };

  const chechUpdateCp = (slug) => {
    const cpStateArray = user?.profileCpStatus;
    if (Array.isArray(cpStateArray)) {
      if (cpStateArray.includes(slug)) {
        return false;
      }
    }
    return true;
  };

  const handleModalSave = async () => {
    setLoading(true);
    const payload = {
      firstName,
      lastName,
      mobileNo,
      alternateEmail,
      contact: {
        birthDate: DOB,
        address: {
          addressText: address,
          pincode,
          city,
          country,
        },
      },
    };

    let response = await api.updatepersonaldetails(payload);
    if (response.status < 400) {
      setLoading(false);
      dispatch(services.fetchUser());
      dispatch(services.fetchUserDetails());
      dispatch(services.toggleProfileModal());
      dispatch(services.successAlert("Profile updated successfully"));
      if (searchParams.get("redirectUri")) {
        router.push(searchParams.get("redirectUri"));
        return;
      }
    } else {
      dispatch(
        services.failureAlert(response.data.error || "Something went wrong!")
      );
      setLoading(false);
      return;
    }
    const updateCp = chechUpdateCp("contact");
    if (updateCp) {
      const cpStateRes = await api.updateProfileCpStatus("contact");
      if (cpStateRes?.status === 200) {
        dispatch(services.updateEngagement(300, socket, user?._id));
      }
    }
    setLoading(false);
    dispatch(services.toggleProfileModal());
  };

  const handleUpdateUsername = async () => {
    if (userName) {
      const response = await api.updateusername(userName.trim());
      if (response.status === 201) {
        dispatch(services.fetchUser());
        dispatch(services.togglePasswordModal());
      } else if (response.status === 400) setError(error?.data?.error);
      else if (response.status === 409) {
        setError("Username already exists!");
        setSuggestions(response.data?.result);
      } else {
        setError("Something went wrong!");
      }
    }
  };

  const handlePincodeChange = async (e) => {
    if (e?.toString().length <= 6) {
      setPincode(e);
      if (e?.toString()?.length === 6) {
        const res = await fetch(
          "https://api.postalpincode.in/pincode/" + e?.toString()
        );
        const pincodeInfo = await res.json();
        if (pincodeInfo[0].Status === "Success") {
          setCity(pincodeInfo[0]?.PostOffice[0]?.District);
          setCountry(pincodeInfo[0]?.PostOffice[0]?.Country);
        }
      }
    }
  };

  return (
    <section>
      {isAlert.show ? (
        <Components.StatusAlert
          status={isAlert.status}
          key1={isAlert.key1}
          key2={isAlert.key2}
          onbtnClick={() => setIsAlert((prev) => ({ ...prev, show: false }))}
        />
      ) : null}
      <div className="ep-container">
        <div className="ep-left-container">
          <div className="ep-user-profile-box">
            <Components.Avatar
              src={user.profileImage}
              alt={user.name}
              loader="xl"
              errStyle={{ fontSize: "3rem" }}
              className={"ep-user-profile"}
            />
            <div {...getRootProps()} className="ep-user-profile-pencil">
              <input {...getInputProps()} />
              <Icon icon="mdi:pencil" />
            </div>
          </div>
          <div className="ep-user-email">
            <h4>{user.email}</h4>
          </div>
        </div>
        <div className="ep-right-container">
          <div className="ep-horizontal">
            <div className="ep-input-box">
              <Components.TextInput
                type="text"
                label={"First Name"}
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e)}
              />
            </div>
            <div className="ep-input-box">
              <Components.TextInput
                type="text"
                value={lastName}
                label="Last Name"
                placeholder="Last Name"
                onChange={(e) => setLastName(e)}
              />
            </div>
          </div>

          <div className="ep-horizontal">
            <div className="ep-input-box">
              <Components.TextInput
                type="email"
                value={alternateEmail}
                label="Alternate Email"
                placeholder="Alternate Email"
                onChange={(e) => setAlternateEmail(e)}
              />
            </div>
            <div className="ep-input-box">
              <Components.TextInput
                type="text"
                value={mobileNo}
                label="Mobile Number"
                placeholder="Mobile Number"
                onChange={(e) => setMobileNo(e)}
              />
            </div>
          </div>

          <div className="ep-horizontal">
            <div className="ep-input-box">
              <Components.DateInput
                value={DOB}
                label={"Date of Birth"}
                onChange={(e) => setDOB(e)}
              />
            </div>
            <div className="ep-input-box">
              <span className="ep-error-text">{error}</span>
              <div className="ep-input-username-box">
                <div className="ep-input-username-box-left">
                  <Components.TextInput
                    type="text"
                    label="Username"
                    value={userName}
                    placeholder="Username"
                    onChange={(e) => setUserName(e)}
                  />
                </div>
                <div className="ep-input-username-box-right">
                  <button
                    className="ep-username-btn"
                    onClick={
                      isValid ? handleUpdateUsername : handleCheckUsername
                    }
                  >
                    {isValid ? "Update" : "Check"}
                  </button>
                </div>
              </div>
              {suggestions.length ? (
                <ul className={"pm-list"}>
                  {suggestions.map((name, i) => (
                    <button
                      key={i}
                      className={"ep-username-sugg-btn"}
                      onClick={() => {
                        setUserName(e.target.value);
                        setIsValid(true);
                        setError(
                          `You can change username ${user?.canChange} times!`
                        );
                      }}
                    >
                      {name}
                    </button>
                  ))}
                </ul>
              ) : null}
            </div>
          </div>

          <div className="ep-horizontal">
            <div className="ep-input-box">
              <Components.TextInput
                label={"Address"}
                placeholder={"address"}
                value={address}
                onChange={(e) => setAddress(e)}
              />
            </div>
            <div className="ep-input-box">
              <Components.TextInput
                label={"Pincode"}
                placeholder={"pincode"}
                type="number"
                value={pincode}
                onChange={handlePincodeChange}
              />
            </div>
          </div>

          <div className="ep-horizontal">
            <div className="ep-input-box">
              <Components.TextInput
                label={"City"}
                placeholder={"City"}
                value={city}
                onChange={(e) => setCity(e)}
              />
            </div>

            <div className="ep-input-box">
              <Components.TextInput
                label={"Country"}
                placeholder={"Country"}
                value={country}
                onChange={(e) => setCountry(e)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="sf-btns-cont">
        <button
          className="sf-msg-btn1 sf-btn-active"
          onClick={() => {
            dispatch(services.toggleProfileModal());
            if (searchParams.get("redirectUri"))
              router.push(searchParams.get("redirectUri"));
          }}
        >
          Cancel
        </button>
        <Components.LoadingBtn
          loading={loading}
          className={"sf-msg-btn sf-btn-active"}
        >
          <button
            className="sf-msg-btn sf-btn-active"
            onClick={handleModalSave}
          >
            Save
          </button>
        </Components.LoadingBtn>
      </div>
    </section>
  );
}

export default PersonalDetails;
