import React from "react";
import "./videoCardSK.scss";

function VideoCardSk() {
  return (
    <article className="vidcardsk-container">
      <figure className="vidcardsk-image"></figure>
      <summary className="vidcardsk-texts">
        <span vidcardsk="vidcardsk-tag"></span>
        <h1 className="vidcardsk-title"></h1>
        <h1 className="vidcardsk-title"></h1>
        <p className="vidcardsk-para"></p>
        <p className="vidcardsk-para"></p>
        <div className="vidcardsk-creator">
          <span className="vidcardsk-profile"></span>
          <div className="vidcardsk-infos">
            <h3 className="vidcardsk-name"></h3>
            <p className="vidcardsk-role"></p>
          </div>
        </div>
      </summary>
    </article>
  );
}

export default VideoCardSk;
