"use client";

import React, { useState } from "react";
import { useDispatch } from "react-redux";

import styles from "./rated.module.scss";
import services from "@/store/services";

function SkillBox({ onRemoval, skillName, displayRating, rating }) {
  const handleRemoval = (e) => {
    e.preventDefault();
    onRemoval(skillName, rating);
  };

  return (
    <div className={styles["skill-box"]}>
      <span>{skillName}</span>
      <span className={styles["rating"]}>{rating}</span>
      <button onClick={handleRemoval} className={styles["skill-box-btn"]}>
        X
      </button>
    </div>
  );
}

function RatedSkillsInput(props) {
  const dispatch = useDispatch();
  const { label, allValues, onAdd, onRemove } = props;

  const [skill, setSkill] = useState("");
  const [rating, setRating] = useState();

  const handleAddSkill = (e) => {
    e.preventDefault();
    if (skill === "" || !rating || rating === "") {
      const validRating = rating > 0 && rating <= 5;
      if (!validRating) {
        dispatch(services.failureAlert("Please enter valid rating (1 to 5)!"));
      } else {
        dispatch(services.failureAlert("Please enter the skill name"));
      }
      return;
    }
    onAdd({
      title: skill.charAt(0).toUpperCase() + skill.slice(1),
      rating: rating,
    });
    setSkill("");
    setRating(0);
  };

  const handleRemoveSkill = (_skill, _rating) => {
    onRemove(_skill, _rating);
  };

  return (
    <div className={styles["profileModal-input-field-container"]}>
      <div className={styles["input-label"]}>{label}</div>

      <div className={styles["input-divider"]}>
        <div className={styles["input-divider-section-left"]}>
          <div className={styles["input-field"]}>
            <input
              type="text"
              className={styles["normal-text-input"]}
              placeholder={"python, react, etc..."}
              value={skill}
              onChange={(e) => setSkill(e.target.value)}
            />
          </div>
        </div>
        <div className={styles["input-divider-section-right"]}>
          <div className={styles["input-field"]}>
            <input
              type="number"
              max={5}
              min={1}
              className={styles["normal-text-input"]}
              placeholder={"1-5"}
              value={rating}
              onChange={(e) => {
                if (e.target.value <= 5) {
                  setRating(e.target.value);
                } else {
                  return;
                }
              }}
            />
            <button
              onClick={handleAddSkill}
              className={styles["btn-add-skill"]}
            >
              Add
            </button>
          </div>
        </div>
      </div>
      <div className={styles["skills-display"]}>
        {allValues.map((item, i) => (
          <SkillBox
            skillName={item.title}
            displayRating={true}
            rating={item.rating}
            key={i}
            onRemoval={handleRemoveSkill}
          />
        ))}
      </div>
    </div>
  );
}

export default RatedSkillsInput;
