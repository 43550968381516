import React from "react";

import styles from "./dash.module.scss";
import utils from "@/utils";
import Image from "next/image";

function ProfileDashCard({ title, number, icon, backColor }) {
  const myStyle = {
    background: `linear-gradient(180deg, ${backColor[0]} 5.88%, ${backColor[1]} 170.88%)`,
  };

  const handleClick = () => {
    if (title === "Enrolled Courses") {
      return "/profile/enrollments";
    } else if (title === "Active Courses") {
      return "/profile/enrollments?type=notcomplete";
    } else if (title === "My Certificates") {
      return "/profile/certificates";
    } else {
      return "/profile/enrollments?type=completed";
    }
  };

  return (
    <a href={handleClick()} id={`PD_${title}_Btn`}>
      <article className={styles["card-container"]} style={myStyle}>
        <Image src={icon} alt={`${title}-icon`} width={60} height={60} />
        <h3>{title}</h3>
        <div className={styles["number"]}>{utils.formatNumber(number)}</div>
      </article>
    </a>
  );
}

export default ProfileDashCard;
