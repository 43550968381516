const POST_ENROLLMENT_TOUR_DATA = [
  {
    content: (
      <>
        <p>
          Complete{" "}
          {parseFloat(process.env.NEXT_PUBLIC_COURSE_COMPLETION_CRITERIA) * 100}
          % of the course to redeem your certificate and showcase your
          achievement.
        </p>
      </>
    ),
    title: <h2>Unlock Your Certificate!</h2>,
    placement:
      typeof window === "undefined"
        ? "top"
        : window.innerWidth < 961
          ? window.innerWidth < 360
            ? "center"
            : "top"
          : "top",
    target:
      typeof window === "undefined"
        ? "#vd_Certificate_Request_Btn"
        : window.innerWidth < 961
          ? window.innerWidth < 360
            ? body
            : "#vd_Certificate_Request_Btn"
          : "#vd_Certificate_Request_Btn",
  },
  {
    content: (
      <>
        <p>
          Follow a structured learning path with videos divided into stages for
          easy progress.
        </p>
      </>
    ),
    title: <h2>Learn Step by Step!</h2>,
    placement:
      typeof window === "undefined"
        ? "left"
        : window.innerWidth < 961
          ? window.innerWidth < 360
            ? "center"
            : "top"
          : "left",
    target:
      typeof window === "undefined"
        ? "#video-list"
        : window.innerWidth < 961
          ? window.innerWidth < 360
            ? body
            : "#vd_TabMobile_Next"
          : "#video-list",
  },
  {
    content: (
      <>
        <p>Ask questions, share insights, and engage in the comment section.</p>
      </>
    ),
    title: <h2>Engage & Share Your Thoughts!</h2>,
    placement:
      typeof window === "undefined"
        ? "left"
        : window.innerWidth < 961
          ? window.innerWidth < 360
            ? "center"
            : "top"
          : "left",
    target:
      typeof window === "undefined"
        ? "#comments"
        : window.innerWidth < 961
          ? window.innerWidth < 360
            ? body
            : "#vd_TabMobile_Comments"
          : "#comments",
  },
  {
    content: (
      <>
        <p>
          Save important points, revisit key concepts, and download notes as a
          PDF anytime.
        </p>
      </>
    ),
    title: <h2>Take & Download Notes!</h2>,
    placement:
      typeof window === "undefined"
        ? "left"
        : window.innerWidth < 961
          ? window.innerWidth < 360
            ? "center"
            : "top"
          : "left",
    target:
      typeof window === "undefined"
        ? "#notes"
        : window.innerWidth < 961
          ? window.innerWidth < 360
            ? body
            : "#vd_TabMobile_Notes"
          : "#notes",
  },
];

export default POST_ENROLLMENT_TOUR_DATA;
