"use client";

import { Icon } from "@iconify/react";
import React from "react";

import Button from "@/components/atoms/buttons/Button";
import styles from "./last-attempt.module.scss";
import assets from "@/assets/images";
import Image from "next/image";

function LastAttemptModal(props) {
  const { closeHandler, currBestMarks, avgMarks, totalMarks } = props;

  return (
    <section className={styles["container"]}>
      <div className={styles["cross-div"]} onClick={closeHandler}>
        <Icon icon="bitcoin-icons:cross-outline" className={styles.icon} />
      </div>
      <article>
        <Image
          src={assets.LastAttempt}
          alt="test-image"
          width={400}
          height={400}
        />
        <div className={styles["right"]}>
          <h3>Attention: Final Attempt for the test</h3>
          <ul>
            <li>
              Your current best score is{" "}
              <strong>
                {currBestMarks}/{totalMarks}
              </strong>
              .
            </li>
            <li>
              Your average score upto this stage is <strong>{avgMarks}</strong>.
            </li>
            <li>
              To redeem the certificate for this course, your average score must
              be at least 50%.
            </li>
            {/* <li>
              You are currently below the required threshold. Make this attempt
              count to improve your average!
            </li> */}
          </ul>
          <p>
            You're on track! Maintain or improve your score in this final
            attempt.
          </p>

          <div className={styles["btn-div"]}>
            <Button name={"Start Test"} />
          </div>
        </div>
      </article>
    </section>
  );
}

export default LastAttemptModal;
