import React from "react";

import styles from "./category.module.scss";
import Components from "@/components";

function Category({ category, className }) {
  return (
    <a
      id={`CP_${category.title}_BTN`}
      className={`${styles["container"]} ${className}`}
      href={`/courses/${category.title.toLowerCase().replace(" ", "-")}`}
    >
      <Components.Tooltip title={category.title} placement="bottom">
        <h2>{category.title}</h2>
      </Components.Tooltip>
    </a>
  );
}

export default Category;
