import { useRouter } from "next/navigation";
import React from "react";

import Components from "@/components";
import utils from "@/utils";

import styles from "./following.module.scss";

function FollowingCard({ followinguser }) {
  const router = useRouter();

  return (
    <article className={styles["fc-container"]}>
      <div className={styles["fc-btn-row"]}>
        <Components.FollowBtn user={followinguser} />
      </div>
      <div className={styles["fc-main"]}>
        <Components.Avatar
          src={followinguser?.profileImage}
          alt={followinguser?.name ?? ""}
          className={styles["fc-profile-pic"]}
          loader={"xl"}
          errStyle={{ fontSize: "1.3em" }}
          onClick={() => router.push(`/profile/${followinguser?.username}`)}
        />
        <div className={styles["fc-details"]}>
          <Components.Tooltip title={followinguser?.name} placement="bottom">
            <h3>{followinguser?.name}</h3>
          </Components.Tooltip>
          <p>{utils.formatNumber(followinguser?.followers)} followers</p>
        </div>
      </div>
    </article>
  );
}

export default FollowingCard;
