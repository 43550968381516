"use client";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useGetUploadBundleStatus = () => {
  const bundle = useSelector((state) => state.bundleDetails.bundleInfo);
  const [value, setValue] = useState([]);

  useEffect(() => {
    if (bundle) {
      const {
        // bundle fields
        title,
        ctfTitle,
        // landing page details
        about,
        //pricing page details
        price,
        // creator message details
        welcomeEmail,
        congratulationsEmail,
        // curriculum
        courses,
      } = bundle;

      const isBundleCreated = title?.length && ctfTitle?.length;
      const landingPageStatus = about?.length;

      const creatorMessageStatus =
        welcomeEmail?._id && congratulationsEmail?._id;

      const curriculumStatus = courses?.length;

      const newValue = [];

      if (isBundleCreated) newValue.push("bundle-structure");
      if (landingPageStatus) newValue.push("landing-page");
      if (curriculumStatus) newValue.push("curriculam");
      if (price && price !== "") newValue.push("pricing");
      if (creatorMessageStatus) newValue.push("creator-message");

      setValue([...newValue]);
    }
  }, [bundle]);

  return value;
};

export default useGetUploadBundleStatus;
