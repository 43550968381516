import React from "react";

import LeftComponent from "./sections/LeftComponent";
import RightComponent from "./sections/RightComponent";
import SearchComponent from "./sections/SearchComponent";
import styles from "./navbar.module.scss";

function Navbar({ onSideCall, watchPoint, className }) {
  return (
    <section className={`${styles["navbox"]} ${className}`} id="header">
      <LeftComponent onSideCall={onSideCall} />
      <SearchComponent />
      <RightComponent points={watchPoint} />
    </section>
  );
}

export default Navbar;
