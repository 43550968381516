"use client";

import { useRouter, useSearchParams } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";

import services from "@/store/services";
import Components from "@/components";

import styles from "./details.module.scss";

function ConfirmDetails() {
  const searchParams = useSearchParams();
  const dispatch = useDispatch();
  const router = useRouter();

  // const education = useSelector((state) => state?.userDetails.education);
  const user = useSelector((state) => state?.user?.users);

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    // college: "",
  });

  // const [onNext, setOnNext] = useState(false);

  useEffect(() => {
    if (user.email) {
      // education.sort((a, b) => new Date(b.endDate) - new Date(a.endDate));
      setData((prev) => ({
        ...prev,
        firstName: user.firstName,
        lastName: user.lastName,
        // college: education[0]?.school || "",
      }));
    }
  }, [
    user,
    // education
  ]);

  const handleOnSubmit = () => {
    dispatch(services.toggleConfirmDetailsModal());
    if (!searchParams.has("detailsUpdated")) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("detailsUpdated", "true");
      router.push(`?${newSearchParams.toString()}`, { replace: true });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const redirectUri = `redirectUri=${encodeURIComponent(
      `/certificate/preview?id=${searchParams.get("id")}${
        searchParams.get("type") ? `&type=${searchParams.get("type")}` : ""
      }&detailsUpdated=true`
    )}`;
    // if (onNext) {
    //   // update education info
    //   router.push(`/profile/me?showEducation=true&${redirectUri}`);
    // } else {
    // update personal details
    router.push(`/profile/me?showPersonal=true&${redirectUri}`);
    // }
  };

  return (
    <section className={styles.container}>
      <div className={styles.textContainer}>
        {/* {!onNext ? ( */}
        <>
          <Components.Input
            id="first-name"
            name="first-name"
            labelTitle="First Name"
            value={data.firstName}
            type="text"
            disabled={true}
            className={styles.feedbackInput}
            icon={<Icon icon="ph-user" className={styles.icon2} />}
          />
          <Components.Input
            type="text"
            id="last-name"
            disabled={true}
            name="last-name"
            labelTitle="Last Name"
            value={data.lastName}
            className={styles.feedbackInput}
            icon={<Icon icon="ph-user" className={styles.icon2} />}
          />
        </>
        {/* ) : (
          <Components.Input
            id="college"
            type="text"
            name="college"
            disabled={true}
            labelTitle="College"
            value={data.college}
            className={styles.feedbackInput}
            icon={<Icon icon="ph-building" className={styles.icon2} />}
          />
        )} */}
      </div>

      <div className={styles.btnContainer}>
        <button className={styles.controlBtn} onClick={(e) => handleUpdate(e)}>
          Update
        </button>

        {/* {!onNext ? (
          <button onClick={() => setOnNext((prev) => !prev)}>Next</button>
        ) : null} */}

        {/* {onNext ? ( */}
        <Components.LoadingBtn>
          <button onClick={handleOnSubmit}>Submit</button>
        </Components.LoadingBtn>
        {/* ) : null} */}
      </div>
    </section>
  );
}

export default ConfirmDetails;
