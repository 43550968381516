import React from "react";

import styles from "./input.module.scss";

const Input = (props) => {
  const {
    type,
    name,
    id,
    disabled,
    placeholder,
    value,
    handleChange,
    className,
    labelTitle,
    icon,
    scrollRef,
    checked,
    requiredField = false,
  } = props;

  return (
    <label htmlFor={id} className={styles.label}>
      {labelTitle && (
        <h2 className={styles.labelTitle}>
          {labelTitle} <span className={styles.requiredField}>*</span>
        </h2>
      )}
      <div className={`${styles.inputContainer} ${className}`}>
        <input
          id={id}
          name={name}
          type={type}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          onChange={handleChange}
          className={styles.formInput}
          checked={checked}
          ref={scrollRef}
          autoFocus
        />
        {icon}
      </div>
    </label>
  );
};

export default Input;
