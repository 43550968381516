import { useRouter } from "next/navigation";
import React from "react";

import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";

import "./drop-menu.scss";

function DropDownMenu({ open, menuRef, setOpen, menu, type }) {
  const router = useRouter();

  const handleClose = (event, ref, setOpen) => {
    if (ref && ref.contains(event.target)) {
      return;
    }
    if (type !== "nav") setOpen((prev) => ({ ...prev, anchor: false }));
    else setOpen(false);
  };

  const handleListKeyDown = (event, setOpen) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleMenuItemClick = (e, ref, setOpen, item) => {
    if (type !== "nav") {
      setOpen((prev) => ({ ...prev, value: item.title }));
      handleClose(e, ref, setOpen);
    } else {
      handleClose(e, ref, setOpen);
      router.push(item.path);
    }
  };

  return (
    <>
      <Popper
        open={open}
        anchorEl={menuRef}
        role={undefined}
        transition
        disablePortal
        className={
          type === "search" ? "ddm-smenu-container" : "ddm-menu-container"
        }
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className="ddm-cont">
              <ClickAwayListener
                onClickAway={(e) => handleClose(e, menuRef, setOpen)}
              >
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={(e) => handleListKeyDown(e, setOpen)}
                  style={{ marginTop: 5 }}
                >
                  {menu?.map((item, i) => {
                    return (
                      <MenuItem
                        key={i}
                        onClick={(e) =>
                          handleMenuItemClick(e, menuRef, setOpen, item)
                        }
                        className="ddm-menu-item"
                      >
                        {item.title}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default DropDownMenu;
