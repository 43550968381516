"use client";

import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useContext } from "react";

import { certificateValues, setDefaultValues } from "../initialValues";
import SocketContext from "@/socket/Context";
import services from "@/store/services";
import Components from "@/components";
import api from "@/api";

import styles from "./certificates.module.scss";

function AddCertificateModal({ resetModalEdit, details, rewardModalToggle }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.users);
  const { socket } = useContext(SocketContext).SocketState;

  const [isUpdateDialog, setIsUpdateDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [certificate, setCertificate] = useState(certificateValues);
  const [alert, setAlert] = useState({
    showAlert: false,
    message: "",
  });

  useEffect(
    () =>
      setDefaultValues(
        "certificates",
        details,
        setCertificate,
        setIsUpdateDialog
      ),
    []
  );

  const handleAddSkill = (skill) => {
    setCertificate((prevCertificate) => ({
      ...prevCertificate,
      skills: [...prevCertificate.skills, skill],
    }));
  };

  const handleRemoveSkill = (skill) => {
    const newFilteredSkills = certificate.skills.filter((e) => e !== skill);
    setCertificate({ ...certificate, skills: newFilteredSkills });
  };

  const isEmpty = () => {
    if (certificate.title === "" || certificate.organization === "")
      return true;
    return false;
  };

  const chechUpdateCp = (slug) => {
    const cpStateArray = user?.profileCpStatus;
    if (Array.isArray(cpStateArray)) {
      if (cpStateArray.includes(slug)) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!isEmpty()) {
      if (certificate.startDate > certificate.endDate) {
        dispatch(services.failureAlert("The start and end dates are invalid"));
        setLoading(false);
        return;
      }
      // !make API call
      let response;
      if (isUpdateDialog) {
        response = await api.updateCertificates({
          certificate: { ...certificate },
        });
      } else {
        response = await api.createCertificate({
          certificate: { ...certificate },
        });
      }

      if (response.status < 400) {
        const updateCp = chechUpdateCp("certificates");
        if (updateCp) {
          const cpStateRes = await api.updateProfileCpStatus("certificates");
          if (cpStateRes?.status === 200) {
            dispatch(services.updateEngagement(300, socket, user._id));
            dispatch(services.fetchUser());
            rewardModalToggle(300);
          }
        }
        dispatch(services.successAlert("Certificate updated!"));
        dispatch(services.fetchUserDetails());
        setLoading(false);
        resetModalEdit();
        dispatch(services.toggleAddCertificateModal());
        return;
      } else {
        setAlert({
          showAlert: true,
          message:
            response.data.error ?? "Can't update certificate, please try again",
        });
      }
    } else {
      dispatch(services.failureAlert("Some fields are empty!"));
    }
    setLoading(false);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (isUpdateDialog) {
      setLoading(true);

      const response = await api.deleteCertificate({
        certificateCreatedAt: certificate.createdAt,
      });

      if (response.status < 400) {
        dispatch(services.successAlert("Certificate Deleted!"));
        resetModalEdit();
        setLoading(false);
        dispatch(services.fetchUserDetails());
        dispatch(services.toggleAddCertificateModal());
        return;
      } else {
        setAlert({
          showAlert: true,
          message:
            response.data.error ??
            "Can't delete certifiacate, please try again",
        });
      }
    }
    setLoading(false);
  };

  return (
    <section>
      {loading ? <Components.PageLoader type="page" /> : null}
      {alert.showAlert && (
        <p className={styles.errorHeading}>
          {alert.message[0].toUpperCase() + alert.message.slice(1)}
        </p>
      )}
      <form className={styles.profileModal}>
        <div className={styles.row}>
          <Components.TextInput
            label={"Certificate title*"}
            placeholder={"React.js advance course"}
            value={certificate.title}
            onChange={(e) => setCertificate({ ...certificate, title: e })}
          />

          <Components.TextInput
            label={"Issued By*"}
            placeholder={"CipherSchools"}
            value={certificate.organization}
            onChange={(e) =>
              setCertificate({ ...certificate, organization: e })
            }
          />
        </div>

        <div className={styles.row}>
          <Components.TextInput
            label={"Certificate URL"}
            placeholder={"Certificate URL"}
            value={certificate.cftUrl}
            onChange={(e) => setCertificate({ ...certificate, cftUrl: e })}
          />
        </div>

        <div className={styles.durationDates}>
          <div className={styles.durationDate}>
            <Components.MyDatePicker
              label={"Start date"}
              onChange={(date) =>
                setCertificate({ ...certificate, startDate: date })
              }
              value={certificate.startDate}
              format="MM/yyyy"
              placeholder="MM/YYYY"
            />
          </div>
          <div className={styles.durationDate}>
            <Components.MyDatePicker
              label={"End date"}
              onChange={(date) =>
                setCertificate({ ...certificate, endDate: date })
              }
              value={certificate.endDate}
              format="MM/yyyy"
              placeholder="MM/YYYY"
            />
          </div>
        </div>

        <Components.SkillsInput
          label={"Skills"}
          allValues={certificate.skills}
          onAdd={handleAddSkill}
          onRemove={handleRemoveSkill}
        />

        <div className={styles["am-btns"]}>
          {!isUpdateDialog ? (
            <button
              className={styles["am-cancel-btn"]}
              onClick={(e) => {
                e.preventDefault();
                resetModalEdit();
                dispatch(services.toggleAddCertificateModal());
              }}
            >
              Cancel
            </button>
          ) : (
            <button className={styles["am-cancel-btn"]} onClick={handleDelete}>
              Delete
            </button>
          )}
          <button className={styles["am-save-btn"]} onClick={handleSubmit}>
            Save
          </button>
        </div>
      </form>
    </section>
  );
}

export default AddCertificateModal;
