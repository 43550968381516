"use client";

import React, { useState } from "react";

import styles from "./text.module.scss";

function TextArea(props) {
  const {
    label,
    value,
    rows,
    onChange,
    className,
    placeholder,
    maxCharacters = null,
  } = props;

  const [characterCount, setCharacterCount] = useState(value?.length ?? 0);

  const handleChange = (e) => {
    if (onChange) {
      if (maxCharacters && e.target.value?.length > maxCharacters) return;
      onChange(e);
      if (maxCharacters) setCharacterCount(e.target.value?.length);
    }
  };

  return (
    <div className={styles["profileModal-input-field-container"]}>
      <div className={styles["input-label"]}>{label}</div>
      <div className={`${styles["text-area-container"]} ${className}`}>
        <textarea
          value={value}
          spellCheck={false}
          rows={rows || 3}
          onChange={handleChange}
          placeholder={placeholder}
          className={styles["text-area-input"]}
        />
      </div>
      {maxCharacters && (
        <p className={styles["text-area-wordCount"]}>
          {characterCount}/{maxCharacters}
        </p>
      )}
    </div>
  );
}

export default React.memo(TextArea);
