"use client";

import React from "react";

import styles from "./styles.module.scss";
import Components from "@/components";

function ConfirmationModal(props) {
  const { btn1, btn2, message, handleOnProceed, handleToggle } = props;

  return (
    <section className={styles.container}>
      <div className={styles.body}>
        <p>{message ?? "Are you sure you want to proceed?"}</p>
      </div>
      <div className={styles.footer}>
        <Components.Button
          name={btn1 || "Proceed"}
          className={`${styles.btn} ${styles.proceed}`}
          handleClick={() => {
            handleOnProceed();
            handleToggle();
          }}
        />
        <Components.Button
          name={btn2 || "Cancel"}
          className={`${styles.btn} ${styles.cancel}`}
          handleClick={() => handleToggle()}
        />
      </div>
    </section>
  );
}

export default ConfirmationModal;
