"use client";

import { useSearchParams, useRouter } from "next/navigation";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { signIn } from "next-auth/react";
import { Icon } from "@iconify/react";
import Image from "next/image";

import Button from "@/components/atoms/buttons/Button";
import styles from "./login.module.scss";
import services from "@/store/services";
import Components from "@/components";
import Images from "@/assets/images";
import utils from "@/utils";
import apis from "@/api";

function Login() {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const [LoginDetail, setLoginDetail] = useState({
    email: "",
    password: "",
  });
  const [signUpDetail, setSignUpDetail] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    password: "",
    googleId: "",
    blogConsent: true,
    newsLetterConsent: true,
  });
  const [isActive, setIsActive] = useState(false);
  const [openOtp, setOpenOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [respMsg, setRespMsg] = useState("");
  const [timer, setTimer] = useState(30);
  const [otp, setOtp] = useState();

  const intervalRef = useRef(null);
  const resendCount = useRef(0);

  const searchParams = useSearchParams();
  const dispatch = useDispatch();
  const router = useRouter();

  const handleGoogleLogin = () => {
    signIn("google", { redirect: true });
  };

  const checkSameEmail = ({ email }) => {
    const validEmail = searchParams.get("email");
    if (validEmail && validEmail !== "") {
      if (validEmail === email) {
        setRespMsg("");
        return true;
      }
      setRespMsg(
        "Email don't match. Enter the email you received course access!"
      );
      return false;
    }

    setRespMsg("");
    return true;
  };

  const handleLogin = async (details) => {
    if (!checkSameEmail({ email: LoginDetail.email })) return;
    setLoading(true);
    const response = await apis.login(details);
    if (response.status === 200) {
      dispatch(services.fetchUser());
      dispatch(services.fetchUserDetails());
      setLoginDetail({ email: "", password: "" });
      dispatch(services.successAlert("Login successful"));
      setRespMsg("");
      if (searchParams.get("redirect")) {
        dispatch(services.toggleSigninModal());
        setLoading(false);
        router.push(`/${searchParams.get("redirect")}`);
        return;
      }
      setLoading(false);
      dispatch(services.toggleSigninModal());
    } else {
      setRespMsg(response.data?.error);
      setLoading(false);
      dispatch(services.failureAlert("Login failed"));
    }
  };

  const handleResendOtp = async (details) => {
    if (resendCount.current > 4) return;
    try {
      setLoading(true);
      setRespMsg("");
      const data = {
        firstName: details.firstName,
        lastName: details.lastName,
        email: details.email,
        mobileNo: details.mobileNo,
        password: details.password,
      };

      const response = await apis.generateOtp(data);
      if (response.status === 201) {
        utils.addToLocalStorage("userEmail", data.email);
        setRespMsg("OTP sent successfully!");
        resendCount.current += 1;
        setTimer(30);
        setIsActive(true);
      } else {
        dispatch(
          services.failureAlert(response.data.error ?? "Failed to send OTP.")
        );
      }
    } catch (error) {
      dispatch(services.failureAlert(error ?? "Couldn't send OTP"));
    } finally {
      setLoading(false);
    }
  };

  const handleVerification = async () => {
    setRespMsg("");
    if (!otp.trim()) return setRespMsg("Please enter OTP");
    if (!/^\d{6}$/.test(otp)) return setRespMsg("Please enter the 6-digit OTP");

    try {
      setLoading(true);
      const response = await apis.verifyOtp({
        otp,
        email: signUpDetail?.email,
      });
      if (response?.status === 201) {
        dispatch(services.successAlert("Signup successful"));
        setRespMsg("");
        utils.addToLocalStorage(
          "username-dialog-status",
          JSON.stringify({ show: "YES" })
        );

        dispatch(services.fetchUser());
        dispatch(services.fetchUserDetails());
        dispatch(services.startNewVideoPageTour());
        setSignUpDetail({
          firstName: "",
          lastName: "",
          email: "",
          mobileNo: "",
          password: "",
          googleId: "",
        });
        dispatch(services.toggleSigninModal());
      } else {
        setRespMsg(response.data?.error ?? "Something went wrong.");
      }
    } catch (error) {
      setRespMsg("Verification failed, Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSignup = async (details) => {
    if (!checkSameEmail({ email: details.email })) return;
    if (
      (details.firstName.length > 25 || details.lastName.length > 25) &&
      details.googleId === ""
    ) {
      setRespMsg(
        (details.firstName.length > 25 ? "Firstname " : "Lastname") +
          " is too long"
      );
    } else if (details.password.length > 20 && details.googleId === "") {
      setRespMsg("Password must be less than 20 chars.");
    } else if (details.password.length < 8 && details.googleId === "") {
      setRespMsg("Password is too short.");
    } else {
      setLoading(true);
      const data = {
        firstName: details.firstName,
        lastName: details.lastName,
        email: details.email,
        mobileNo: details.mobileNo,
        password: details.password,
      };
      const response = await apis.generateOtp(data);
      if (response.status === 201) {
        setOpenOtp(true);
        setTimer(30);
        setIsActive(true);
      } else {
        setRespMsg(response.data.error ?? "Failed to send OTP.");
        dispatch(
          services.failureAlert(response.data.error ?? "Failed to send OTP.")
        );
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (timer && isActive) {
      intervalRef.current = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(intervalRef.current);
  }, [isActive]);

  useEffect(() => {
    if (timer <= 0) {
      setIsActive(false);
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, [timer]);

  //Login Component
  const Login = () => {
    return (
      <section className={styles["container"]}>
        <button
          className={styles["cross-left"]}
          onClick={() => dispatch(services.toggleSigninModal())}
        >
          <Icon icon="iconoir:cancel" />
        </button>
        <div className={styles.content}>
          <header>
            <Image src={Images.NavLogo} alt="cipherschools-logo" />
            <h2>Hey, learner</h2>
            <p>Fill your login credential to login</p>
          </header>
          <main>
            <p>{respMsg}</p>
            <Components.TextInput
              value={LoginDetail.email}
              placeholder={"Email ID"}
              onChange={(e) =>
                setLoginDetail((prev) => ({
                  ...prev,
                  email: e,
                }))
              }
            />
            <Components.TextInput
              placeholder={"Password"}
              value={LoginDetail.password}
              onChange={(e) =>
                setLoginDetail((prev) => ({
                  ...prev,
                  password: e,
                }))
              }
              type={isPasswordVisible ? "text" : "password"}
            >
              <Icon
                icon={!isPasswordVisible ? "mdi:eye-off" : "mdi:eye"}
                className={styles.eye}
                onClick={() => setIsPasswordVisible((prev) => !prev)}
              />
            </Components.TextInput>
            <div
              className={styles["forget-password"]}
              onClick={() => {
                dispatch(services.toggleSigninModal());
                router.push("/forget-password");
              }}
            >
              Forgot Password ?
            </div>
            <Components.LoadingBtn
              loading={loading}
              className={styles["login-btn"]}
            >
              <button
                disabled={!LoginDetail.email || !LoginDetail.password}
                onClick={() => handleLogin(LoginDetail)}
              >
                Signin
              </button>
            </Components.LoadingBtn>
            <div className={styles["signup-text"]}>
              Don't have an account?
              <span
                onClick={() => {
                  setRespMsg("");
                  setOpenSignup(true);
                }}
              >
                Get Started
              </span>
            </div>
            <div className={styles["saperator"]}>
              <span>OR</span>
            </div>
            <button
              id="Google_Login_btn"
              onClick={handleGoogleLogin}
              className={styles["google-btn"]}
            >
              <Icon icon="flat-color-icons:google" />
              Login with Google
            </button>
          </main>
        </div>
      </section>
    );
  };

  //SignUp Component
  const SignUp = () => {
    return (
      <section className={styles["container"]}>
        <button
          className={styles["cross-left"]}
          onClick={() => dispatch(services.toggleSigninModal())}
        >
          <Icon icon="iconoir:cancel" />
        </button>
        <header>
          <Image src={Images.NavLogo} alt="cipherschools-logo" />
          <h2>Hi learner</h2>
          <p>Fill your details to signup</p>
        </header>
        <main>
          <p>{respMsg}</p>
          <div className={styles.row}>
            <Components.TextInput
              value={signUpDetail.firstName}
              // label={"First Name"}
              placeholder={"First Name"}
              onChange={(e) =>
                setSignUpDetail((prev) => ({
                  ...prev,
                  firstName: e,
                }))
              }
            />
            <Components.TextInput
              value={signUpDetail.lastName}
              // label={"Last Name"}
              placeholder={"Last Name"}
              onChange={(e) =>
                setSignUpDetail((prev) => ({
                  ...prev,
                  lastName: e,
                }))
              }
            />
          </div>
          <div className={styles.row}>
            <Components.TextInput
              value={signUpDetail.email}
              // label={"Email Id"}
              placeholder={"Email Id"}
              onChange={(e) =>
                setSignUpDetail((prev) => ({
                  ...prev,
                  email: e,
                }))
              }
            />
          </div>
          <div className={styles.row}>
            <Components.TextInput
              value={signUpDetail.mobileNo}
              // label={"Mobile No"}
              placeholder={"Mobile No"}
              onChange={(e) =>
                setSignUpDetail((prev) => ({
                  ...prev,
                  mobileNo: e,
                }))
              }
            />
            <Components.TextInput
              type={isPasswordVisible ? "text" : "password"}
              placeholder={"Password"}
              value={signUpDetail.password}
              onChange={(e) =>
                setSignUpDetail((prev) => ({
                  ...prev,
                  password: e,
                }))
              }
            >
              <Icon
                className={styles.eye}
                icon={!isPasswordVisible ? "mdi:eye-off" : "mdi:eye"}
                onClick={() => setIsPasswordVisible((prev) => !prev)}
              />
            </Components.TextInput>
          </div>
          <div className={styles.input}>
            <Components.CheckBox
              id={"blog"}
              value={"blog"}
              text={"Receive our latest blogs full of tips and insights"}
              className={styles.checkbox}
              handler={(event) =>
                setSignUpDetail({
                  ...signUpDetail,
                  blogConsent: event.target.checked,
                })
              }
              checked={signUpDetail.blogConsent}
            />

            <Components.CheckBox
              id={"newsletter"}
              value={"newsLetterConsent"}
              text={"Get our exclusive newsletters for updates and offers"}
              className={styles.checkbox}
              handler={(event) =>
                setSignUpDetail({
                  ...signUpDetail,
                  newsLetterConsent: event.target.checked,
                })
              }
              checked={signUpDetail.newsLetterConsent}
            />
          </div>
          <Components.LoadingBtn
            loading={loading}
            className={styles["login-btn"]}
          >
            <button onClick={() => handleSignup(signUpDetail)}>Sign up</button>
          </Components.LoadingBtn>
          <div className={styles["signup-text"]}>
            Already have an account?{" "}
            <span
              onClick={() => {
                setRespMsg("");
                setOpenSignup(false);
              }}
            >
              Sign-in now
            </span>
          </div>

          <div className={styles["saperator"]}>
            <span>OR</span>
          </div>
          <button
            id="Google_Register_btn"
            onClick={handleGoogleLogin}
            className={styles["google-btn"]}
          >
            <Icon icon="flat-color-icons:google" />
            Register with Google
          </button>
        </main>
      </section>
    );
  };

  const VerifyOtp = () => {
    return (
      <section className={styles["container"]}>
        <main className={styles["otp-main"]}>
          <button
            className={styles["back-left"]}
            onClick={() => setOpenOtp(false)}
          >
            <Icon
              icon="material-symbols-light:arrow-back"
              width="24"
              height="24"
            />
          </button>
          <p>You've received a 6-digit OTP on</p>
          <p>{signUpDetail?.email}</p>
          <p className={styles["error"]}>{respMsg}</p>
          <aside className={styles["input-container"]}>
            <Components.OtpInput numInputs={6} value={otp} onChange={setOtp} />
            <Button name={"Verify OTP"} handleClick={handleVerification} />
            <span>Please check your inbox and spam</span>
          </aside>
          <aside className={styles["resend-div"]}>
            <button
              className={styles["resend-btn"]}
              onClick={() => handleResendOtp(signUpDetail)}
              disabled={isActive || resendCount.current >= 4}
            >
              {resendCount.current < 4
                ? "Resend OTP"
                : "Limit Reached ! Please try again after 24 hours"}
            </button>
            {isActive ? <span>{parseInt(timer)}</span> : null}
          </aside>
        </main>
      </section>
    );
  };

  return (
    <>
      {loading ? <Components.PageLoader type="page" /> : null}
      {openSignup ? (openOtp ? VerifyOtp() : SignUp()) : Login()}
    </>
  );
}

export default Login;
