"use client";

import { Fragment, useEffect, useMemo, useState } from "react";
import { RotatingTriangles } from "react-loader-spinner";
import Image from "next/image";

import styles from "./avatar.module.scss";
import { sizes } from "@/utils/values";
import icons from "@/assets/icons";
import utils from "@/utils";

function Avatar(props) {
  const { src, alt, loader, errStyle, className, onClick } = props;
  const color = useMemo(() => utils.getRandomColor(), [src]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  function renderChars() {
    let char = "";
    const endIndex = errStyle.chars || 1;
    const strings = alt?.replaceAll?.(/[^a-zA-Z0-9]+/g, " ")?.split(" ");
    for (let i = 0; i < endIndex; i++) {
      if (strings?.[i]) char += strings?.[i].slice(0, 1);
    }
    return char;
  }

  function getClassName(prefix) {
    if (loading && !error) return styles[prefix + "Loading"];
    else if (!loading && error) return styles[prefix + "Error"];
    return "";
  }

  useEffect(() => {
    const eventListener = (event) => {
      if (event.target && event.target.nodeName === "IMG") {
        event.preventDefault();
      }
    };
    if (typeof window !== "undefined")
      document.addEventListener("contextmenu", eventListener);
    return () => document.removeEventListener("contextmenu", eventListener);
  }, []);

  useEffect(() => {
    setError(false);
  }, [src]);

  return (
    <Fragment>
      {!error && (
        <Image
          src={src === "none" ? icons.AvatarLight : src}
          alt={alt}
          onLoadingComplete={() => {
            setLoading(false);
            setError(false);
          }}
          onError={() => {
            setError(true);
            setLoading(false);
          }}
          onClick={() => onClick?.()}
          width={sizes[loader || vsm]}
          height={sizes[loader || vsm]}
          className={`${styles.image} ${styles.avatar} ${className} ${getClassName("image")}`}
        />
      )}
      {error && (
        <span
          className={`${styles.error} ${className} ${getClassName("error")}`}
          style={{
            color: errStyle.textColor || color.text,
            backgroundColor: errStyle.backColor || color.back,
            fontSize: errStyle.fontSize,
            height: sizes[loader],
            width: sizes[loader],
            borderRadius: "50%",
          }}
          onClick={() => onClick?.()}
        >
          {renderChars()}
        </span>
      )}
      <RotatingTriangles
        visible={loading}
        height={sizes[loader]}
        width={sizes[loader]}
        wrapperClass={`${styles.loader} ${getClassName("loader")}`}
        colors={["var(--primary)", "var(--blue)", "var(--slateblue)"]}
      />
    </Fragment>
  );
}

export default Avatar;
