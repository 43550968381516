"use client";

import React, { useRef, useState } from "react";
import { Icon } from "@iconify/react";

import styles from "./player.module.scss";

function SmallVideo({ poster, videoSrc }) {
  const [isPlay, setIsPlay] = useState(false);
  const videoRef = useRef(null);

  const togglePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlay(true);
    } else {
      videoRef.current.pause();
      setIsPlay(false);
    }
  };

  return (
    <>
      <article className={styles["player"]}>
        <video
          ref={videoRef}
          poster={poster}
          src={videoSrc}
          // playsinline
          playsInline
          preload="none"
          onClick={togglePlay}
        ></video>
        <div className={styles["play-btn-big"]}></div>
        <div className={styles["controls"]}>
          <div className={styles["controls-main"]}>
            <div className={styles["play-btn paused"]} onClick={togglePlay}>
              {isPlay ? (
                <Icon icon={"mdi:pause"} className={styles["play-icon"]} />
              ) : (
                <Icon icon={"mdi:play"} className={styles["play-icon"]} />
              )}
            </div>
          </div>
        </div>
      </article>
    </>
  );
}

export default SmallVideo;
