import utils from "@/utils";

export default [
  {
    id: "welcome",
    text: [
      `
      <h4 class="shephered-custom-title">Hi, Welcome Aboard!</h4>
      <p class="shephered-custom-para">
      This is an educational website tour, the last place you would want to spend your free time after watching motivational videos or dreaming eyes closed.
      <br />
      So, let's quickly help you understand how to use it better. Trust this will be faster than online delivery apps....
      </p>
      <h4 class="shephered-custom-foot">Are you ready? Let's get started.</h4>
        `,
    ],
    attachTo: { element: "", on: "center" },
    classes: "shepherd shepherd-welcome",
    buttons: [
      {
        type: "cancel",
        classes: "shepherd-button-secondary",
        text: "Skip",
      },
      {
        type: "next",
        classes: "shepherd-button-next",
        text: "Next",
      },
    ],
    when: {
      show: () => {
        localStorage.setItem(
          "home-tour-guide",
          JSON.stringify({ show: "YES", step: 0 })
        );
      },
      cancel: () => {
        localStorage.setItem(
          "site-tour-status",
          JSON.stringify({
            ...utils.getFromLocalStorage("site-tour-status"),
            homeTour: "NO",
          })
        );
      },
    },
  },
  // {
  //   id: "Courses",
  //   text: [
  //     `
  //     <h4 class="shephered-custom-title" >This section as the name suggests will help you see the trending courses</h4>
  //     <h4 class="shephered-custom-foot">Click here to see what others are learning.</h4>
  //     `,
  //   ],
  //   attachTo: { element: "#msp-courses", on: "left" },
  //   buttons: [
  //     {
  //       type: "back",
  //       classes: "shepherd-button-secondary",
  //       text: "Back",
  //     },
  //     {
  //       text: "Next",
  //       classes: "shepherd-button-next",
  //       type: "next",
  //     },
  //   ],
  //   when: {
  //     show: () => {
  //       localStorage.setItem(
  //         "home-tour-guide",
  //         JSON.stringify({ show: "YES", step: 0 })
  //       );
  //     },
  //   },
  // },
  {
    id: "Trending",
    text: [
      `
      <h4 class="shephered-custom-title" >This section as the name suggests will help you see the trending courses</h4>
      <h4 class="shephered-custom-foot">Click here to see what others are learning.</h4>
      `,
    ],
    attachTo: { element: "#msp-trending", on: "left" },
    buttons: [
      {
        type: "back",
        classes: "shepherd-button-secondary",
        text: "Back",
      },
      {
        text: "Next",
        classes: "shepherd-button-next",
        type: "next",
      },
    ],
    when: {
      show: () => {
        localStorage.setItem(
          "home-tour-guide",
          JSON.stringify({ show: "YES", step: 0 })
        );
      },
    },
  },
  {
    id: "Following",
    text: [
      `
      <h4 class="shephered-custom-title">Your idols rest here, you'll be able to see people you're following & learning in this section</h4>
      <h4 class="shephered-custom-foot">Click here for the blessings.</h4>
      `,
    ],
    attachTo: { element: "#msp-following", on: "left" },
    buttons: [
      {
        type: "back",
        classes: "shepherd-button-secondary",
        text: "Back",
      },
      {
        type: "next",
        classes: "shepherd-button-next",
        text: "Next",
      },
    ],
    when: {
      show: () => {
        localStorage.setItem(
          "home-tour-guide",
          JSON.stringify({ show: "YES", step: 2 })
        );
      },
    },
  },
  // {
  //   id: "Your Courses",
  //   text: [
  //     `
  //     <h4 class="shephered-custom-title">Here, you can find which courses you are enrolled in and check if they're resting or you are.</h4>
  //       <h4 class="shephered-custom-foot">Click here to become better than Sharma Ji ka beta.        </h4>
  //       `,
  //   ],
  //   attachTo: { element: "#msp-your-courses", on: "left" },
  //   buttons: [
  //     {
  //       type: "back",
  //       classes: "shepherd-button-secondary",
  //       text: "Back",
  //     },
  //     {
  //       type: "next",
  //       classes: "shepherd-button-next",
  //       text: "Next",
  //     },
  //   ],
  //   when: {
  //     show: () => {
  //       localStorage.setItem(
  //         "home-tour-guide",
  //         JSON.stringify({ show: "YES", step: 3 })
  //       );
  //     },
  //   },
  // },
  // {
  //   id: "Profile Dashboard",
  //   text: [
  //     `
  //     <h4 class="shephered-custom-title">Here, you can navigate to your profile page to either brag or procrastinate on Linkedin.</h4>
  //     <h4 class="shephered-custom-foot">Toss here to check your luck!</h4>
  //     `,
  //   ],
  //   attachTo: { element: "#msp-p-dashboard", on: "left" },
  //   buttons: [
  //     {
  //       type: "back",
  //       classes: "shepherd-button-secondary",
  //       text: "Back",
  //     },
  //     {
  //       type: "next",
  //       classes: "shepherd-button-next",
  //       text: "Next",
  //     },
  //   ],
  //   when: {
  //     show: () => {
  //       localStorage.setItem(
  //         "home-tour-guide",
  //         JSON.stringify({ show: "YES", step: 4 })
  //       );
  //     },
  //   },
  // },
  {
    id: "Whatsapp",
    text: [
      `
      <h4 class="shephered-custom-title">
        Join our community to interact with like-minded people, resolve others’ doubts & get yours resolved too.
      </h4>
      <p class="shephered-custom-para">
        Stay updated with the job/internship opportunities that we share, and participate in weekly AMA sessions.
      </p>
        <h4 class="shephered-custom-foot">Click here to join.</h4>
      `,
    ],
    attachTo: { element: "#msp-whatsapp", on: "left" },
    buttons: [
      {
        type: "back",
        classes: "shepherd-button-secondary",
        text: "Back",
      },
      {
        type: "next",
        classes: "shepherd-button-next",
        text: "Next",
      },
    ],
    when: {
      show: () => {
        window.scrollTo(0, 30);
        localStorage.setItem(
          "home-tour-guide",
          JSON.stringify({ show: "YES", step: 4 })
        );
      },
    },
  },
  // {
  //   id: "Blog",
  //   text: [
  //     `
  //     <h4 class="shephered-custom-title">And here, you can read SEO-friendly blogs we share to get recognized by Google search.</h4>
  //     <h4 class="shephered-custom-foot">Click here to read, they're informative & take a lot of effort to compose.</h4>
  //     `,
  //   ],
  //   attachTo: { element: "#msp-blog", on: "left" },
  //   buttons: [
  //     {
  //       type: "back",
  //       classes: "shepherd-button-secondary",
  //       text: "Back",
  //     },
  //     {
  //       type: "next",
  //       classes: "shepherd-button-next",
  //       text: "Next",
  //     },
  //   ],
  //   when: {
  //     show: () => {
  //       window.scrollTo(0, 40);
  //       localStorage.setItem(
  //         "home-tour-guide",
  //         JSON.stringify({ show: "YES", step: 5 })
  //       );
  //     },
  //     hide: () => {
  //       window.scrollTo(0, 0);
  //     },
  //   },
  // },
  {
    id: "search",
    text: [
      `
      <h4 class="shephered-custom-title">Search from the library of the courses we are compiling for you.</h4>
      <h4 class="shephered-custom-foot">Go ahead! Give it a try, the CS logo animation is amazing.</h4>
      `,
    ],
    attachTo: { element: ".nav-search", on: "bottom" },
    buttons: [
      {
        type: "back",
        classes: "shepherd-button-secondary",
        text: "Back",
      },
      {
        type: "next",
        classes: "shepherd-button-next",
        text: "Next",
      },
    ],
    when: {
      show: () => {
        localStorage.setItem(
          "home-tour-guide",
          JSON.stringify({ show: "YES", step: 6 })
        );
      },
    },
  },
  {
    id: "notifications",
    text: [
      `
      <h4 class="shephered-custom-title">The bell icon is to help you stay updated with any information being shared by your favorite Creator or Admin at CipherSchools</h4>
      <h4 class="shephered-custom-foot">Go ahead! Give it a look.</h4>
      `,
    ],
    attachTo: { element: "#nav-notification", on: "bottom" },
    buttons: [
      {
        type: "back",
        classes: "shepherd-button-secondary",
        text: "Back",
      },
      {
        type: "next",
        classes: "shepherd-button-next",
        text: "Next",
      },
    ],
    when: {
      show: () => {
        localStorage.setItem(
          "home-tour-guide",
          JSON.stringify({ show: "YES", step: 7 })
        );
      },
    },
  },
  {
    id: "cipher-time",
    text: [
      `
      <h4 class="shephered-custom-title">
      This is a CipherPoint section, here you'll be able to track the number of minutes you spent sweating while learning.
      </h4>
      <p class="shephered-custom-para">
      To make it simple, here you'll be earning points, the amount of minutes you spend on watching tutorial videos gets converted into equivalent points known as CipherPoints, which later can be redeemed to earn rewards.
      </p>
      <h4 class="shephered-custom-foot">Go ahead! Give it a look.</h4>
      `,
    ],
    attachTo: { element: "#nav-cipher-points", on: "bottom" },
    buttons: [
      {
        type: "back",
        classes: "shepherd-button-secondary",
        text: "Back",
      },
      {
        type: "next",
        classes: "shepherd-button-next",
        text: "Next",
      },
    ],
    when: {
      show: () => {
        localStorage.setItem(
          "home-tour-guide",
          JSON.stringify({ show: "YES", step: 8 })
        );
      },
    },
  },
  {
    id: "theme-change",
    text: [
      `
      <h4 class="shephered-custom-title">Give your eyes the magic of a not so dark theme to help it relax.</h4>
      <h4 class="shephered-custom-foot">Click here to turn the website to dark mode.</h4>
      `,
    ],
    attachTo: { element: "#nav-mode", on: "bottom" },
    buttons: [
      {
        type: "back",
        classes: "shepherd-button-secondary",
        text: "Back",
      },
      {
        type: "next",
        classes: "shepherd-button-next",
        text: "Next",
      },
    ],
    when: {
      show: () => {
        localStorage.setItem(
          "home-tour-guide",
          JSON.stringify({ show: "YES", step: 9 })
        );
      },
    },
  },
  {
    id: "video-dashboard",
    text: [
      `
      <h4 class="shephered-custom-title">
        Hi, Here we will guide you with the video dashboard features, the place you'll be spending most of your time.
      </h4>
      <p class="shephered-custom-para">
        This will take as little as a minute but faster than guessing the next element in the pattern 13 21 34  55 89 __? 
      </p>
      <h4 class="shephered-custom-foot">Are you ready? Let's go.</h4>
      `,
    ],
    attachTo: { element: "#video-card-first", on: "right" },
    buttons: [
      {
        type: "back",
        classes: "shepherd-button-secondary",
        text: "Back",
      },
      {
        type: "next",
        classes: "shepherd-button-next",
        text: "Next",
      },
    ],
    when: {
      show: () => {
        window.scrollTo(0, 150);
        localStorage.setItem(
          "home-tour-guide",
          JSON.stringify({ show: "NO", step: 0 })
        );
      },
      destroy: () => {
        let data = JSON.parse(localStorage.getItem("home-tour-guide"));
        if (data.show === "NO") {
          window.location.assign("/videopage/617bfb4be97a450023b3973c/30");
          window.scrollTo(0, 0);
        } else {
          localStorage.setItem(
            "home-tour-guide",
            JSON.stringify({ show: "NO", step: 0 })
          );
          localStorage.setItem(
            "video-tour-guide",
            JSON.stringify({ show: "NO", step: 0 })
          );
          localStorage.setItem(
            "profile-tour-guide",
            JSON.stringify({ show: "NO", step: 0 })
          );
          window.location.assign("/");
        }
      },
    },
  },
];
