"use client";

import { useRouter, useSearchParams } from "next/navigation";
import React, { useState } from "react";

import styles from "../navbar.module.scss";
import Components from "@/components";

// Full Search Bar
const SearchField = (props) => (
  <div className={`${styles["search-box"]}`}>
    <input
      id="CP_Search_Field"
      type="search"
      placeholder="Search and Learn"
      autoComplete="off"
      spellCheck="false"
      value={props.queryText}
      onKeyDown={(e) => {
        if (e.key === "Enter") props.onSearch();
      }}
      onChange={(e) => props.setState(e.target.value)}
    />
    <button onClick={props.onSearch}>
      <svg
        width="17"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26 26L20.3335 20.3234M23.4737 12.7368C23.4737 15.5844 22.3425 18.3154 20.3289 20.3289C18.3154 22.3425 15.5844 23.4737 12.7368 23.4737C9.88925 23.4737 7.1583 22.3425 5.14475 20.3289C3.1312 18.3154 2 15.5844 2 12.7368C2 9.88925 3.1312 7.1583 5.14475 5.14475C7.1583 3.1312 9.88925 2 12.7368 2C15.5844 2 18.3154 3.1312 20.3289 5.14475C22.3425 7.1583 23.4737 9.88925 23.4737 12.7368ZM23.4737 12.7368L23.4737 12.7368Z"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  </div>
);

function SearchComponent() {
  const [openSearch, setOpenSearch] = useState(false);
  const searchParams = useSearchParams();

  const [searchText, setSearchText] = useState(searchParams.get("query") || "");
  const router = useRouter();

  const handleSearch = () => {
    if (searchText?.length >= 2) {
      router.push(`/search?query=${encodeURIComponent(searchText)}`);
    }
  };

  return (
    <article className={`${styles["search-container"]} search-box`}>
      <Components.Tooltip title="Search Videos">
        <svg
          width="17"
          viewBox="0 0 28 28"
          fill="none"
          className={
            openSearch ? styles["search-icon-open"] : styles["search-icon"]
          }
          id="nav-search"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => setOpenSearch((prev) => !prev)}
        >
          <path
            d="M26 26L20.3335 20.3234M23.4737 12.7368C23.4737 15.5844 22.3425 18.3154 20.3289 20.3289C18.3154 22.3425 15.5844 23.4737 12.7368 23.4737C9.88925 23.4737 7.1583 22.3425 5.14475 20.3289C3.1312 18.3154 2 15.5844 2 12.7368C2 9.88925 3.1312 7.1583 5.14475 5.14475C7.1583 3.1312 9.88925 2 12.7368 2C15.5844 2 18.3154 3.1312 20.3289 5.14475C22.3425 7.1583 23.4737 9.88925 23.4737 12.7368ZM23.4737 12.7368L23.4737 12.7368Z"
            stroke="currentColor"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Components.Tooltip>
      <div className={openSearch ? styles["search"] : styles["mobile-search"]}>
        <button onClick={() => setOpenSearch(false)}>
          <svg
            width="25"
            height="25"
            viewBox="0 0 678 678"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M361.699 421.536V158.174H316.507V421.536L210.512 315.542L178.556 347.498L339.103 508.044L499.651 347.498L467.696 315.542L361.699 421.536Z"
              fill="currentColor"
            />
          </svg>
        </button>
        <SearchField
          queryText={searchText}
          setState={setSearchText}
          onSearch={() => {
            setOpenSearch(false);
            handleSearch();
          }}
        />
      </div>
    </article>
  );
}

export default SearchComponent;
