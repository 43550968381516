"use client";

import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useContext } from "react";

import {
  experienceValues,
  EMPLOYMENT_TYPE,
  setDefaultValues,
} from "../initialValues";
import SocketContext from "@/socket/Context";
import styles from "./experience.module.scss";
import services from "@/store/services";
import Components from "@/components";
import api from "@/api";

const MAX_ABOUT_WORD_COUNT = 1200;

function ExperienceModal({ resetModalEdit, details }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.users);
  const { socket } = useContext(SocketContext).SocketState;

  const [currentlyWorkingHere, setCurrentlyWorkingHere] = useState(false);
  const [experience, setExperience] = useState(experienceValues);
  const [isUpdateDialog, setIsUpdateDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    showAlert: false,
    message: "",
  });

  useEffect(() => {
    setDefaultValues(
      "experience",
      details,
      setExperience,
      setIsUpdateDialog,
      setCurrentlyWorkingHere
    );
  }, []);

  const handleEmployeeTypeChange = (type) => {
    setExperience({ ...experience, empType: type });
  };

  const handleDescriptionChange = (e) => {
    setExperience({ ...experience, description: e.target.value });
  };

  const handleAddSkill = (skill) => {
    setExperience({ ...experience, skills: [...experience.skills, skill] });
  };

  const handleRemoveSkill = (skill) => {
    const newFilteredSkills = experience.skills.filter((e) => e !== skill);
    setExperience((prevExperience) => ({
      ...prevExperience,
      skills: newFilteredSkills,
    }));
  };

  const isEmpty = () => {
    if (
      experience.organization === "" ||
      experience.title === "" ||
      experience.description === "" ||
      experience.empType.value === "" ||
      experience.startDate === "" ||
      experience.location === "" ||
      (!currentlyWorkingHere && experience.endDate === "")
    ) {
      return true;
    }
    return false;
  };

  const chechUpdateCp = (slug) => {
    const cpStateArray = user?.profileCpStatus;
    if (Array.isArray(cpStateArray)) {
      if (cpStateArray.includes(slug)) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!isEmpty()) {
      if (!currentlyWorkingHere && experience.startDate > experience.endDate) {
        dispatch(services.failureAlert("The start and end dates are invalid"));
        setLoading(false);
        return;
      }
      const _endDate = currentlyWorkingHere ? "present" : experience.endDate;

      let response;
      if (isUpdateDialog) {
        response = await api.updateExperience({
          ...experience,
          empType: experience.empType.value,
          endDate: _endDate,
        });
      } else {
        response = await api.createExperience({
          ...experience,
          empType: experience.empType.value,
          endDate: _endDate,
        });
        if (response.status < 400) {
          const updateCp = chechUpdateCp("experience");
          if (updateCp) {
            const cpStateRes = await api.updateProfileCpStatus("experience");
            if (cpStateRes?.status === 200) {
              dispatch(services.updateEngagement(300, socket, user._id));
              dispatch(services.fetchUser());
              rewardModalToggle(300);
            }
          }
        }
      }

      if (response.status < 400) {
        dispatch(services.successAlert("Experience updated!"));
        setLoading(false);
        dispatch(services.fetchUserDetails());
        resetModalEdit();
        dispatch(services.toggleExperienceModal());
        return;
      } else {
        setAlert({
          showAlert: true,
          message:
            response?.data?.error ?? "Can't add experience, please try again",
        });
      }
      setLoading(false);
    } else {
      setLoading(false);
      dispatch(services.failureAlert("Some fields are empty!"));
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (isUpdateDialog) {
      setLoading(true);

      const response = await api.deleteExperience({
        experienceCreatedAt: experience.createdAt,
      });

      if (response.status < 400) {
        dispatch(services.successAlert("Experience Deleted!"));
        resetModalEdit();
        setLoading(false);
        dispatch(services.fetchUserDetails());
        dispatch(services.toggleExperienceModal());
        return;
      } else {
        setAlert({
          showAlert: true,
          message:
            response.data.error ?? "Can't delete experience, please try again",
        });
      }
    }
    setLoading(false);
  };

  return (
    <section>
      {loading ? <Components.PageLoader type="page" /> : null}
      {alert.showAlert && (
        <p className={styles.errorHeading}>
          {alert.message[0].toUpperCase() + alert.message.slice(1)}
        </p>
      )}

      <form className={styles.profileModal}>
        <div className={styles.row}>
          <Components.TextInput
            label={"Company name*"}
            placeholder={"Company name"}
            leftIcon={"mdi:company"}
            value={experience.organization}
            onChange={(e) => setExperience({ ...experience, organization: e })}
          />
          <Components.TextInput
            label={"Job title*"}
            placeholder={"Job title"}
            value={experience.title}
            onChange={(e) => setExperience({ ...experience, title: e })}
          />
        </div>
        <div className={styles.row}>
          <Components.TextInput
            label={"Location*"}
            placeholder={"Location"}
            value={experience.location}
            onChange={(e) => setExperience({ ...experience, location: e })}
          />
          <Components.DropDownInput
            label={"Employment type*"}
            options={EMPLOYMENT_TYPE}
            onChange={handleEmployeeTypeChange}
            value={experience.empType}
          />
        </div>

        <div className={styles.durationDates}>
          <div className={styles.durationDate}>
            <Components.MyDatePicker
              label={"Start date*"}
              onChange={(date) =>
                setExperience({ ...experience, startDate: date })
              }
              value={experience.startDate}
              format="MM/yyyy"
              placeholder="MM/YYYY"
            />
          </div>
          <div className={styles.durationDate}>
            {!currentlyWorkingHere && (
              <Components.MyDatePicker
                label={"End date"}
                onChange={(date) =>
                  setExperience({ ...experience, endDate: date })
                }
                value={experience.endDate}
                format="MM/yyyy"
                placeholder="MM/YYYY"
              />
            )}
          </div>
        </div>

        {/* currently working here checkbox */}
        <div className={styles["experience-checkbox"]}>
          <input
            type="checkbox"
            name=""
            id=""
            value={true}
            checked={currentlyWorkingHere}
            onChange={() => setCurrentlyWorkingHere(!currentlyWorkingHere)}
          />
          <p>I am currently working here</p>
        </div>

        {/* job/experience description */}
        <Components.TextArea
          label={"Description*"}
          placeholder={"Feel free to write about work experience"}
          value={experience.description}
          onChange={handleDescriptionChange}
          maxCharacters={MAX_ABOUT_WORD_COUNT}
        />

        <Components.SkillsInput
          label={"Skills"}
          onAdd={handleAddSkill}
          onRemove={handleRemoveSkill}
          allValues={experience.skills}
        />

        <div className={styles["am-btns"]}>
          {!isUpdateDialog ? (
            <button
              className={styles["am-cancel-btn"]}
              onClick={(e) => {
                e.preventDefault();
                resetModalEdit();
                dispatch(services.toggleExperienceModal());
              }}
            >
              Cancel
            </button>
          ) : (
            <button className={styles["am-cancel-btn"]} onClick={handleDelete}>
              Delete
            </button>
          )}
          <button className={styles["am-save-btn"]} onClick={handleSubmit}>
            Save
          </button>
        </div>
      </form>
    </section>
  );
}

export default ExperienceModal;
