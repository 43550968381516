import React from "react";

import styles from "./date.module.scss";
import utils from "@/utils";

function DateInput(props) {
  const { label, onChange, value, type = "date", ...others } = props;

  const handleDateChange = (e) => {
    if (onChange) {
      //format date
      const date = e.target.value;
      const year = date.split("-")[0];
      const month = date.split("-")[1];
      const day = date.split("-")[2];
      if (type === "year") onChange(`${year}`);
      else if (type === "month") onChange(`${month}/${year}`);
      else onChange(`${day}/${month}/${year}`);
    }
  };

  return (
    <div className={styles["profileModal-input-field-container"]}>
      <div className={styles["input-label"]}>{label}</div>
      <div className={styles["input-field"]}>
        <input
          type={type}
          className={styles["date-input"]}
          onChange={handleDateChange}
          value={utils.formatDateValue(value)}
          {...others}
        />
      </div>
    </div>
  );
}

export default DateInput;
