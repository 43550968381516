"use client";

import React, { useState, useEffect, useContext } from "react";
import { useSearchParams, useRouter } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";

import {
  educationValues,
  SCORE_TYPE,
  setDefaultValues,
} from "../initialValues";
import SocketContext from "@/socket/Context";
import styles from "./education.module.scss";
import services from "@/store/services";
import Components from "@/components";
import api from "@/api";

function EducationModal({ resetModalEdit, details, rewardModalToggle }) {
  const { socket } = useContext(SocketContext).SocketState;
  const user = useSelector((state) => state.user.users);
  const dispatch = useDispatch();

  const searchParams = useSearchParams();
  const router = useRouter();

  const [isUpdateDialog, setIsUpdateDialog] = useState(false);
  const [education, setEducation] = useState(educationValues);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    showAlert: false,
    message: "",
  });

  useEffect(() => {
    setDefaultValues("education", details, setEducation, setIsUpdateDialog);
  }, []);

  const empty = () => {
    if (
      education.school === "" ||
      education.degree == "" ||
      education.studyField === "" ||
      education.startDate === "" ||
      education.endDate == ""
    ) {
      return true;
    }
    return false;
  };

  const chechUpdateCp = (slug) => {
    const cpStateArray = user?.profileCpStatus;
    if (Array.isArray(cpStateArray)) {
      if (cpStateArray.includes(slug)) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!empty()) {
      if (education.startDate > education.endDate) {
        dispatch(services.failureAlert("The start and end dates are invalid"));
        setLoading(false);
        return;
      }
      let response;
      if (isUpdateDialog) {
        response = await api.updateEducation({
          education: {
            ...education,
            scoreType: education.scoreType?.value ?? "",
          },
        });
      } else {
        response = await api.createEducation({
          education: {
            ...education,
            scoreType: education.scoreType?.value ?? "",
          },
        });
      }
      if (response.status < 400) {
        const updateCp = chechUpdateCp("education");
        if (updateCp) {
          const cpStateRes = await api.updateProfileCpStatus("education");
          if (cpStateRes?.status === 200) {
            dispatch(services.updateEngagement(300, socket, user._id));
            dispatch(services.fetchUser());
            rewardModalToggle(300);
          }
        }
        dispatch(services.successAlert("Education updated!"));
        dispatch(services.fetchUserDetails());
        resetModalEdit();
        setLoading(false);
        dispatch(services.toggleAddEducationModal());
        if (searchParams.get("redirectUri"))
          router.push(searchParams.get("redirectUri"));
        return;
      } else {
        setAlert({
          showAlert: true,
          message:
            response.data.error ?? "Can't update education, please try again",
        });
      }
    } else dispatch(services.failureAlert("Some fields are empty!"));

    setLoading(false);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (isUpdateDialog) {
      setLoading(true);

      const response = await api.deleteEducation({
        educationCreatedAt: education.createdAt,
      });

      if (response.status < 400) {
        dispatch(services.successAlert("Education Deleted!"));
        resetModalEdit();
        setLoading(false);
        dispatch(services.fetchUserDetails());
        dispatch(services.toggleAddEducationModal());
        return;
      } else {
        setAlert({
          showAlert: true,
          message:
            response.data.error ?? "Can't delete education, please try again",
        });
      }
      setLoading(false);
      if (searchParams.get("redirectUri"))
        router.push(searchParams.get("redirectUri"));
    }
  };

  const handleScoretypeChange = (type) => {
    setEducation({ ...education, scoreType: type });
  };

  return (
    <section>
      {loading ? <Components.PageLoader type="page" /> : null}
      {alert.showAlert && (
        <p className={styles.errorHeading}>
          {alert.message[0].toUpperCase() + alert.message.slice(1)}
        </p>
      )}
      <form className={styles.profileModal}>
        <div className={styles.row}>
          <Components.TextInput
            label={"School/College*"}
            placeholder={"IIT Delhi..."}
            value={education.school}
            onChange={(e) => setEducation({ ...education, school: e })}
          />
          <Components.TextInput
            label={"Degree*"}
            placeholder={"B.tech"}
            value={education.degree}
            onChange={(e) => setEducation({ ...education, degree: e })}
          />
        </div>
        <div className={styles.row}>
          <Components.TextInput
            label={"Field of Study/Board*"}
            placeholder={"Engineering"}
            value={education.studyField}
            onChange={(e) => setEducation({ ...education, studyField: e })}
          />
          <Components.TextInput
            label={"Location"}
            placeholder={"Chandigarh"}
            value={education.location}
            onChange={(e) => setEducation({ ...education, location: e })}
          />
        </div>
        <div className={styles.durationDates}>
          <div className={styles.durationDate}>
            <Components.MyDatePicker
              label={"Start Year*"}
              onChange={(date) =>
                setEducation({ ...education, startDate: date })
              }
              value={education.startDate}
              format="yyyy"
              placeholder="YYYY"
            />
          </div>
          <div className={styles.durationDate}>
            <Components.MyDatePicker
              label={"End Year*"}
              onChange={(date) => setEducation({ ...education, endDate: date })}
              value={education.endDate}
              format="yyyy"
              placeholder="YYYY"
            />
          </div>
        </div>
        <div className={styles.row}>
          <Components.DropDownInput
            label={"Score type"}
            options={SCORE_TYPE}
            onChange={handleScoretypeChange}
            value={education.scoreType}
          />
          <Components.TextInput
            type="number"
            label={"Score"}
            placeholder={"9.0"}
            value={education.score}
            onChange={(e) => setEducation({ ...education, score: e })}
          />
        </div>

        <Components.TextInput
          label={"Activities and Societies"}
          placeholder={"Volleyball, President, Marching band"}
          value={education.activities}
          onChange={(e) => setEducation({ ...education, activities: e })}
        />

        <div className={styles["am-btns"]}>
          {!isUpdateDialog ? (
            <button
              className={styles["am-cancel-btn"]}
              onClick={(e) => {
                e.preventDefault();
                resetModalEdit();
                dispatch(services.toggleAddEducationModal());
              }}
            >
              Cancel
            </button>
          ) : (
            <button className={styles["am-cancel-btn"]} onClick={handleDelete}>
              Delete
            </button>
          )}
          <button className={styles["am-save-btn"]} onClick={handleSubmit}>
            Save
          </button>
        </div>
      </form>
    </section>
  );
}

export default EducationModal;
