"use client";

import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/navigation";

import ReplyComment from "./ReplyComment";
import services from "@/store/services";
import Components from "@/components";
import utils from "@/utils";
import "./comment.scss";
import api from "@/api";

function CommentCard({ comment, handleReply, allComments, id }) {
  const user = useSelector((state) => state?.user?.users);
  const [replyCount, setReplyCount] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [inView, setInView] = useState(false);
  const comment_text_ref = useRef(null);
  const elementRef = useRef(null);
  const dispatch = useDispatch();
  const router = useRouter();

  const [likeState, setLikeState] = useState({
    isLiked: user?.email && comment?.likedUsers?.includes(user?._id),
    count: comment?.likedUsers?.length || 0,
  });

  const onLikeEvent = async () => {
    if (user.email) {
      if (!likeState.isLiked) {
        const response = await api.likeComment(comment._id);
        if (response.data?.success === 200) {
          setLikeState((prev) => ({
            isLiked: !prev.isLiked,
            count: prev.count + 1,
          }));
        }
      } else {
        const response = await api.unlikeComment(comment._id);
        if (response.data?.success === 200) {
          setLikeState((prev) => ({
            isLiked: !prev.isLiked,
            count: prev.count - 1,
          }));
        }
      }
    } else {
      dispatch(services.toggleSigninModal());
    }
  };

  const fetchReplies = useCallback(() => {
    const replies = allComments?.filter(
      (item) => item?.replyToId === comment?._id
    );
    setReplyCount(replies?.length);
    let commentId = router.asPath?.split("#")?.[1];
    let isReplyId = replies.find((item) => item._id === commentId)?.replyToId;
    if (isReplyId === id) {
      setShowReply(true);
      commentId = id;
    }
    if (id === commentId) {
      setInView(true);
    } else {
      setInterval(false);
    }
    document.getElementById(commentId)?.scrollIntoView();
  }, [allComments]);

  useEffect(() => {
    fetchReplies();
  }, []);

  useEffect(() => {
    if (comment_text_ref.current) {
      comment_text_ref.current.innerHTML = `<p>${utils.linkify(
        comment.text,
        comment?.taggedUser
      )}</p>`;
    }
  }, [comment]);

  return (
    <article
      className={`cm-container ${inView ? "cm-container-highlight" : ""}`}
      id={id}
      ref={elementRef}
    >
      <div className="cm-avatar-area">
        <Components.Avatar
          src={comment?.userInfo?.profileImage}
          alt={comment?.userInfo?.name}
          loader="md"
          errStyle={{ fontSize: "1rem" }}
          className={"cm-avatar"}
        />
      </div>
      <div className="cm-text-area">
        <a
          className="cm-writer-box"
          href={`/profile/${comment?.userInfo.username}`}
        >
          <Components.Tooltip title={comment?.userInfo?.name} placement="top">
            <div className="cm-writer-name">
              <h3>{comment?.userInfo?.name}</h3>
              <span style={{ opacity: 0.8 }}>&bull;</span>
              <span>{utils.timeDifference(comment?.createdAt)}</span>
            </div>
          </Components.Tooltip>
        </a>
        <div className="cm-box">
          <div
            ref={comment_text_ref}
            className={`${!showMore && "body"} content`}
          ></div>
          {comment.text.length > 100 && (
            <a className={"link"} onClick={() => setShowMore((prev) => !prev)}>
              {showMore ? "Read Less" : "Read More"}
            </a>
          )}
        </div>
        <div className="cm-bottom">
          <div className="cm-tool-box">
            <div className="cm-like-btn-box" onClick={onLikeEvent}>
              <div
                className={`cm-icon-container ${likeState.isLiked ? "liked" : ""}`}
              >
                {likeState.isLiked ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M15.056 9.004c.46-1.427.693-2.677.693-3.754c0-2.398-.939-4.247-2.5-4.247c-.847 0-1.109.504-1.437 1.747c.018-.065-.163.634-.215.821c-.101.359-.277.97-.527 1.831a.247.247 0 0 1-.03.065L8.174 9.953a5.885 5.885 0 0 1-2.855 2.326l-1.257.482a1.75 1.75 0 0 0-1.092 1.967l.686 3.539a2.25 2.25 0 0 0 1.673 1.757l8.25 2.022a4.75 4.75 0 0 0 5.733-3.44l1.574-6.173a2.75 2.75 0 0 0-2.665-3.43h-3.165Z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M16.5 5.203c0-2.442-1.14-4.2-3.007-4.2c-1.026 0-1.378.602-1.746 2c-.075.29-.112.43-.151.569c-.101.358-.277.97-.527 1.83a.25.25 0 0 1-.03.065L8.174 9.953a5.885 5.885 0 0 1-2.855 2.327l-.473.181a2.75 2.75 0 0 0-1.716 3.092l.404 2.086a3.25 3.25 0 0 0 2.417 2.538l7.628 1.87a4.75 4.75 0 0 0 5.733-3.44l1.415-5.55a3.25 3.25 0 0 0-3.15-4.053h-1.822c.496-1.633.746-2.892.746-3.801ZM4.6 15.267a1.25 1.25 0 0 1 .78-1.405l.474-.181a7.384 7.384 0 0 0 3.582-2.92l2.867-4.486c.09-.14.159-.294.205-.454c.252-.865.428-1.48.53-1.843a24.7 24.7 0 0 0 .159-.593c.19-.722.283-.881.295-.881c.868 0 1.507.984 1.507 2.699c0 .884-.326 2.335-.984 4.315a.75.75 0 0 0 .711.986h2.85a1.75 1.75 0 0 1 1.696 2.182l-1.415 5.55a3.25 3.25 0 0 1-3.923 2.353l-7.628-1.87a1.75 1.75 0 0 1-1.301-1.366L4.6 15.267Z"
                    />
                  </svg>
                )}
              </div>
              <div className="cm-like-num">
                <span>{likeState.count}</span>
              </div>
            </div>
            <div
              className="cm-reply-btn-box"
              onClick={() =>
                handleReply(
                  comment?.replyToId ? comment.replyToId : comment._id,
                  comment?.userInfo?.username,
                  comment
                )
              }
            >
              <div className="cm-reply">
                <span>Reply</span>
              </div>
            </div>
            {replyCount ? (
              <div
                className="cm-reply-btn-box"
                onClick={() => setShowReply((prev) => !prev)}
              >
                <div className="cm-reply">
                  <span>{showReply ? "Hide replies" : "View replies"}</span>
                </div>
              </div>
            ) : null}
          </div>
          {replyCount && showReply ? (
            <div className="com-reply-box">
              <ReplyComment
                handleReply={handleReply}
                allComments={allComments}
                parentId={comment._id}
              />
            </div>
          ) : null}
        </div>
      </div>
    </article>
  );
}

export default React.memo(CommentCard);
