export const SCORE_TYPE = [
  { value: "cgpa", label: "CGPA" },
  { value: "gpa", label: "GPA" },
  { value: "percentage", label: "Percentage" },
];

export const EMPLOYMENT_TYPE = [
  { value: "full-time", label: "Full time" },
  { value: "part-time", label: "Part time" },
  { value: "training", label: "Training" },
  { value: "internship", label: "Internship" },
];

export const experienceValues = {
  organization: "",
  title: "",
  location: "",
  description: "",
  empType: EMPLOYMENT_TYPE[0],
  startDate: "",
  endDate: "",
  skills: [],
  createdAt: null,
};

export const educationValues = {
  school: "",
  degree: "",
  studyField: "",
  location: "",
  startDate: "",
  endDate: "",
  scoreType: "",
  score: "",
  activities: "",
  createdAt: null,
};

export const projectValues = {
  title: "",
  description: "",
  startDate: "",
  endDate: "",
  skills: [],
  createdAt: null,
};

export const certificateValues = {
  title: "",
  organization: "",
  cftUrl: "",
  startDate: "",
  endDate: "",
  skills: [],
  createdAt: null,
};

export const setDefaultValues = (
  key,
  values,
  setObject,
  setIsUpdateDialog,
  setCurrentlyWorkingHere
) => {
  if (values && typeof values === "object" && !Array.isArray(values)) {
    setIsUpdateDialog(true);
    if (key === "experience") {
      let emp = EMPLOYMENT_TYPE?.find((item) => item.value === values.empType);
      if (values.endDate === "present") setCurrentlyWorkingHere(true);
      setObject({
        ...values,
        empType: emp,
        endDate: values.endDate !== "present" ? values.endDate : null,
      });

      return;
    }
    if (key === "education") {
      setObject({
        ...values,
        scoreType:
          SCORE_TYPE.find((type) => type.value === values.scoreType) ?? "",
      });
      return;
    }
    if (key === "project") {
      if (values?.endDate === "present") setCurrentlyWorkingHere(true);
      setObject({
        ...values,
        endDate: values.endDate !== "present" ? values.endDate : null,
      });
      return;
    } else {
      setObject({ ...values });
    }
  }
};
