import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/navigation";
import React from "react";

import Icons from "@/assets/icons";

import styles from "./policy.module.scss";
import services from "@/store/services";

const CipherPointsIntro = () => {
  const user = useSelector((state) => state.user.users);
  const router = useRouter();
  const dispatch = useDispatch();

  return (
    <>
      <article>
        <h2>What are CipherPoints?</h2>
        <p>
          CipherPoints are credits you earn on the platform. You can accumulate
          these points by completing various daily missions and watching videos.
          Each activity rewards you with a certain number of CipherPoints, which
          you can then use for various benefits or rewards within the platform.
          This system encourages regular engagement and motivates users to
          complete the courses they have enrolled in.
        </p>
      </article>
      <article>
        <h2>How can you earn CipherPoints?</h2>
        <p>
          You can earn CipherPoints by investing your time in learning on the
          platform. CipherPoints are distributed based on the time spent
          watching videos.
          <br />
          Below is the breakdown:
          <ul>
            <li>5 minutes of learning gives you 5 CipherPoints.</li>
            <li>30 minutes of learning gives you another 15 CipherPoints.</li>
            <li>60 minutes of learning gives you another 30 CipherPoints.</li>
            <li>120 minutes of learning gives you another 50 CipherPoints.</li>
          </ul>
          The maximum CipherPoints one can earn in a single day is capped at
          100. Additionally, you can earn CipherPoints by completing your daily
          missions. To explore more &nbsp;
          <a
            onClick={() =>
              user.email
                ? router.push("/redeem/earnpoints")
                : dispatch(services.toggleSigninModal())
            }
          >
            Click here
          </a>
          .
        </p>
      </article>
      <article>
        <h2>How can you redeem CipherPoints?</h2>
        <p>
          You can redeem CipherPoints by either clicking on the{" "}
          <img src={Icons.WatchPoints} /> available on the top right corner of
          the navigation bar in desktop mode or by clicking on the “My
          CipherPoint” option available in the “Profile” section in mobile view.
          Additionally, you can also redeem your CipherPoints by visiting the
          “Store” section available on the left horizontal navigation bar on the
          website.
        </p>
      </article>
      <article>
        <h2>What can I redeem CipherPoints for?</h2>
        <p>
          You can redeem CipherPoints for rewards like T-shirts and other
          merchandise in the store section. Once redeemed, you will need to
          complete another course from scratch/start before you can use your
          CipherPoints to redeem your next reward, after 60 days of redeeming
          the previous merchandise.
        </p>
      </article>
    </>
  );
};

const PolicyUpdates = () => {
  return (
    <>
      <article>
        <h2>Important update on recent changes in CipherPoints Policy</h2>
        <p>
          <ul>
            <li>
              Once CipherPoints are redeemed for any merchandise in the Store
              section, it might take around 30 days for the item to reach your
              delivery address.
            </li>
            <li>
              Since this initiative is new, it sometimes might take longer than
              the above-mentioned time. However, our efforts are constantly
              aimed at ensuring that your merchandise reaches you as soon as
              possible.
            </li>
            <li>
              The reason for slow delivery at this point is quality. We source
              the merchandise locally to ensure its quality and accountability.
              We tried outsourcing it to online printing facilities but
              encountered quality concerns and accountability issues.
            </li>
            <li>
              We have recently made some changes to the logic of CipherPoints
              from the previous concept of 1 CipherPoint = 1 minute of learning
              time. We observed some misuse of this points mechanism. A few
              individuals were running multiple videos simultaneously to collect
              more points, leading to an unexpected surge in orders. Initially,
              the points required ranged from 1200 to 1500, but due to this
              issue, we had to increase it to 3000 points. Rest assured, if you
              are consistent in learning, you can easily achieve the milestone.
              However, as mentioned in the section "How can you earn
              CipherPoints, there is a limit on the maximum points that can be
              achieved in a single day.
            </li>
          </ul>
          <strong>PS:</strong> This is a new initiative to provide quality
          goodies and merchandise to deserving students, and we are committed to
          making it a success for all our valued students. We might make
          mistakes, but don't forget to come forward and guide us on how we can
          improve and make it better for you.
        </p>
      </article>
    </>
  );
};

function PointsPolicy(props) {
  const { page, contentRef } = props;

  return (
    <section className={styles.container} ref={contentRef}>
      {page === 1 ? <CipherPointsIntro /> : <PolicyUpdates />}
    </section>
  );
}

export default PointsPolicy;
