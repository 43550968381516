const ProfileMenu = [
  {
    title: "Dashboard",
    path: "/profile",
  },
  {
    title: "My Profile",
    path: "/profile/me",
  },
  {
    title: "Enrolled Courses",
    path: "/profile/enrollments",
  },
  {
    title: "Wishlist",
    path: "/profile/wishlist",
  },
  {
    title: "Liked Videos",
    path: "/profile/liked-videos",
  },
  {
    title: "My Certificates",
    path: "/profile/certificates",
  },
  {
    title: "Create New Course",
    path: "/upload/course",
  },
  {
    title: "My cohorts",
    path: "/profile/cohorts",
  },
  {
    title: "My Courses",
    path: "/profile/mycourses",
  },
  {
    title: "Assignments",
    path: "/upload/assignments",
  },
];

export default ProfileMenu;
