import React from "react";
import Image from "next/image";

import styles from "./course.module.scss";
import Components from "@/components";

export default function LanguifyCard(props) {
  const data = [
    {
      name: "Languify",
      type: "express & excel",
      title: "FREE IELTS/TOEFL Mock Assessment",
      desc1: <p>AI genereated feedback and scores</p>,
      desc2: (
        <p>
          Test duration: <span>30 mins / 3 hours</span>
        </p>
      ),
      thumbnail:
        "https://ik.imagekit.io/cipherschools/CipherSchools/languify-1",
      logo: "https://www.languify.in/static/media/languify_logo.a9f8d0049ddccd8b9396f1fd772f4f32.svg",
      redirect: "http://bit.ly/3Wsh9Cy",
    },
    {
      name: "Languify",
      type: "express & excel",
      title: "FREE Career Resources for Your Dream Job",
      desc1: <p>An AI communication skills Certification</p>,
      desc2: (
        <p>
          <span>100+ company & jobs roles simulations</span>
        </p>
      ),
      thumbnail:
        "https://ik.imagekit.io/cipherschools/CipherSchools/languify-2",
      logo: "https://www.languify.in/static/media/languify_logo.a9f8d0049ddccd8b9396f1fd772f4f32.svg",
      redirect: "https://sep.languify.in/get-freebies",
    },
  ];

  return (
    <article
      className={styles["container"]}
      onClick={() => window.open(data[Number(props.active)].redirect)}
    >
      <figure>
        <Image
          src={data[Number(props.active)].thumbnail}
          alt={data[Number(props.active)].name}
          width={400}
          height={400}
        />
      </figure>
      <summary>
        <p className={styles["tags"]}>
          <span className={styles["tag"]}>
            {data[Number(props.active)].name}
          </span>
        </p>
        <Components.Tooltip
          title={data[Number(props.active)].title}
          placement="top"
        >
          <h2>{data[Number(props.active)].title}</h2>
        </Components.Tooltip>
        <div className={styles["meta"]}>
          {data[Number(props.active)].desc1}
          {data[Number(props.active)].desc2}
        </div>
        <Components.Tooltip
          title={data[Number(props.active)].name}
          placement="top"
        >
          <footer>
            <Image
              src={data[Number(props.active)].logo}
              alt={data[Number(props.active)].name || "languify-image"}
              width={60}
              height={60}
            />
            <div className={styles.info}>
              <h3>{data[Number(props.active)].name}</h3>
              <p>{data[Number(props.active)].type}</p>
            </div>
          </footer>
        </Components.Tooltip>
      </summary>
    </article>
  );
}
