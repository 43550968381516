import React from "react";

import styles from "./wrapper.module.scss";

const Wrapper = (InnerComponent, bg, id, className) => {
  return function EnhancedComponent(props) {
    return (
      <div
        id={id}
        className={`${styles["padding-container"]} ${className}`}
        style={{ backgroundColor: bg }}
      >
        <InnerComponent {...props} />
      </div>
    );
  };
};

export default Wrapper;
