"use client";

import { useRouter } from "next/navigation";
import { Icon } from "@iconify/react";
import Image from "next/image";
import React from "react";

import styles from "./footer.module.scss";
import Images from "@/assets/images";

function MainFooter(props) {
  const router = useRouter();

  const { className } = props;

  function goTo(url) {
    window.open(url, "_blank");
  }

  return (
    <footer className={`${styles["container"]} ${className}`}>
      <main>
        <aside className={`${styles["part"]} ${styles["branding"]}`}>
          <div className={styles.logo} onClick={() => goToHash("home")}>
            <Image
              src={Images.NavLogo}
              alt="CipherSchools"
              width={200}
              height={200}
            />
            <h2>
              Cipher<span>Schools</span>
            </h2>
          </div>
          <p>
            Cipherschools is a bootstrapped educational video streaming platform
            in India that is connecting passionate unskilled students to skilled
            Industry experts to fulfill their career dreams.
          </p>
          <nav className={styles["links"]} data-type="info">
            <p
              className={styles["link"]}
              onClick={() =>
                window.open("mailto:support@cipherschools.com", "_blank")
              }
              id="Footer_Email"
              data-name="email"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                height="1em"
                width="1em"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
              support@cipherschools.com
            </p>

            <div className={styles.appsContainer}>
              <p>Get the App</p>
              <div>
                <a
                  href="https://apps.apple.com/in/developer/cipherschools/id1713293647"
                  className={styles.downloadButton}
                  target="blank"
                  id="Footer_AppDownload_Ios_Btn"
                >
                  <Icon
                    icon="ic:baseline-apple"
                    className={styles.downloadIcon}
                  />
                  <p>
                    {" "}
                    <span>Download on the</span> App Store
                  </p>
                </a>

                <a
                  href="https://play.google.com/store/apps/dev?id=6109068113970377181"
                  className={styles.downloadButton}
                  target="blank"
                  id="Footer_AppDownload_Android_Btn"
                >
                  <Icon
                    icon="logos:google-play-icon"
                    className={styles.downloadIcon}
                  />
                  <p>
                    {" "}
                    <span>Get it on</span>Google Play
                  </p>
                </a>
              </div>
            </div>
          </nav>
        </aside>
        <aside className={`${styles["part"]} ${styles["part1"]}`}>
          <h5 className={styles["title"]}>Popular Instructors</h5>
          <nav className={styles["links"]}>
            <a
              href="/profile/cipherschools"
              className={styles["link"]}
              onClick={() => router.push("/profile/cipherschools")}
              id="Footer_PI_CipherSchools"
            >
              Cipher Schools
            </a>
            <p
              className={styles["link"]}
              onClick={() => router.push("/profile/cipherharshit")}
              id="Footer_PI_HarshitVashisth"
            >
              Harshit Vashisth
            </p>
            <p
              className={styles["link"]}
              onClick={() => router.push("/profile/cipherayushi")}
              id="Footer_PI_AyushiSharma"
            >
              Ayushi Sharma
            </p>
            <p
              className={styles["link"]}
              onClick={() => router.push("/profile/ciphersdanish")}
              id="Footer_PI_ DanishShaikh"
            >
              Danish Shaikh
            </p>
            <p
              className={styles["link"]}
              onClick={() => router.push("/profile/cipherkrishna")}
              id="Footer_PI_KrishnaLodha"
            >
              Krishna Lodha
            </p>
            <p
              className={styles["link"]}
              onClick={() => router.push("/profile/ciphershruti")}
              id="Footer_PI_ShrutiCodes"
            >
              Shruti Codes
            </p>
          </nav>
        </aside>
        <aside className={styles["part"]}>
          <h5 className={styles["title"]}>Popular Courses</h5>
          <nav className={styles["links"]}>
            <p
              className={styles["link"]}
              onClick={() => router.push("/courses/data-science")}
              id="Footer_PC_DataScience"
            >
              Data Science
            </p>
            <p
              className={styles["link"]}
              onClick={() => router.push("/courses/programming")}
              id="Footer_PI_Programming"
            >
              Programming
            </p>

            <p
              className={styles["link"]}
              onClick={() => router.push("/courses/data-structures")}
              id="Footer_PI_DataStructures"
            >
              Data Structures
            </p>

            <p
              className={styles["link"]}
              onClick={() => router.push("/courses/machine-learning")}
              id="Footer_PI_MachineLearning"
            >
              Machine Learning
            </p>

            <p
              className={styles["link"]}
              onClick={() => router.push("/courses/web-development")}
              id="Footer_PI_WebDevelopment"
            >
              Web Development
            </p>
            <p
              className={styles["link"]}
              onClick={() => router.push("/courses/game-development")}
              id="Footer_PI_GameDevelopment"
            >
              Game Development
            </p>
          </nav>
        </aside>
        <aside className={styles["part"]}>
          <h5 className={styles["title"]}>Company Info</h5>
          <nav className={styles["links"]}>
            <p
              className={styles["link"]}
              onClick={() => router.push("/")}
              id="Footer_CI_Home"
            >
              Home
            </p>
            <p
              className={styles["link"]}
              onClick={() => goTo("https://blog.cipherschools.com")}
              id="Footer_CI_Blog"
            >
              Blog
            </p>
            <p
              className={styles["link"]}
              onClick={() => goTo("/aboutus")}
              id="Footer_CI_AboutUs"
            >
              About us
            </p>
            <p
              className={styles["link"]}
              onClick={() => goTo("/alumni")}
              id="Footer_CI_Alumni"
            >
              Alumni
            </p>
            <p
              className={styles["link"]}
              onClick={() => goTo("/privacy-policy")}
              id="Footer_CI_PrivacyPolicy"
            >
              Privacy Policy
            </p>
            <p
              className={styles["link"]}
              onClick={() => goTo("/terms-and-condition")}
              id="Footer_CI_TermAndCond"
            >
              Terms & Condition
            </p>
          </nav>
        </aside>
      </main>
    </footer>
  );
}

export default MainFooter;
