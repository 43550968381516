"use client";

import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import SocketContext from "@/socket/Context";
import styles from "./about.module.scss";
import services from "@/store/services";
import Components from "@/components";
import api from "@/api";

let MAX_ABOUT_WORD_COUNT = 2000;

function AboutModal(props) {
  const user = useSelector((state) => state.user.users);
  const { socket } = useContext(SocketContext).SocketState;
  const userDetails = useSelector((state) => state.userDetails);

  // const [links, setLinks] = useState(user.webLinks);
  const [aboutInput, setAboutInput] = useState("");
  const [headline, setHeadline] = useState("");
  const [linkedIn, setLinkedIn] = useState({
    linkName: "linkedin",
    link: "",
  });
  const [github, setGithub] = useState({
    linkName: "github",
    link: "",
  });
  const [portfolio, setPortfolio] = useState({
    linkName: "portfolio",
    link: "",
  });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    showAlert: false,
    message: "",
  });

  const dispatch = useDispatch();

  const SocialFields = [
    {
      key: 0,
      title: "Linkedin",
      icon: "mdi:linkedin",
      value: linkedIn.link,
      onChange: (e) =>
        setLinkedIn((prev) => ({
          ...prev,
          link: e.toLowerCase(),
        })),
    },
    {
      key: 1,
      title: "GitHub",
      icon: "mdi:github",
      value: github.link,
      onChange: (e) =>
        setGithub((prev) => ({
          ...prev,
          link: e.toLowerCase(),
        })),
    },
    {
      key: 5,
      title: "Portfolio",
      icon: "ph:globe",
      value: portfolio.link,
      onChange: (e) =>
        setPortfolio((prev) => ({
          ...prev,
          link: e.toLowerCase(),
        })),
    },
  ];

  const handleAboutChange = (e) => {
    e.preventDefault();
    setAboutInput(e.target.value);
  };

  const isEmpty = () => {
    if (
      aboutInput === "" ||
      headline === "" ||
      linkedIn.link.length + portfolio.link.length + github.link.length === 0
    ) {
      return true;
    }
    return false;
  };

  const chechUpdateCp = (slug) => {
    const cpStateArray = user?.profileCpStatus;
    if (Array.isArray(cpStateArray)) {
      if (cpStateArray.includes(slug)) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!isEmpty()) {
      const obj = {
        about: aboutInput,
        headline: headline,
        social: [linkedIn, github, portfolio],
      };
      const response = await api.updateAbout({ about: obj });
      if (response.status < 400) {
        // first check if skills is present in the redux
        const updateAboutCp = chechUpdateCp("about");
        const updateSocialCp = chechUpdateCp("socials");
        if (updateAboutCp || updateSocialCp) {
          let cpAboutStateRes;
          let cpSocialStateRes;
          let cipherPoints = 0;
          let allOkay = false;
          if (updateAboutCp && updateSocialCp) {
            cpAboutStateRes = await api.updateProfileCpStatus("about");
            cpSocialStateRes = await api.updateProfileCpStatus("socials");
            cipherPoints = 600;
            if (
              cpAboutStateRes?.status === 200 &&
              cpSocialStateRes?.status === 200
            ) {
              allOkay = true;
            }
          } else if (updateAboutCp) {
            cpAboutStateRes = await api.updateProfileCpStatus("about");
            cipherPoints = 300;
            if (cpAboutStateRes?.status === 200) {
              allOkay = true;
            }
          } else if (updateSocialCp) {
            cpSocialStateRes = await api.updateProfileCpStatus("socials");
            cipherPoints = 300;
            if (cpSocialStateRes?.status === 200) allOkay = true;
          }

          if (allOkay) {
            dispatch(
              services.updateEngagement(cipherPoints, socket, user?._id)
            );
            dispatch(services.fetchUser());
            props.rewardModalToggle(cipherPoints);
          }
        }
        dispatch(services.successAlert("About updated!"));
        dispatch(services.fetchUserDetails());
        setLoading(false);
        dispatch(services.toggleEditAboutModal());
        return;
      } else {
        setAlert({
          showAlert: true,
          message:
            response?.data?.error ?? "Can't update about, please try again",
        });
      }
    } else {
      dispatch(services.failureAlert("Some fields are empty!"));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user) {
      setAboutInput(user.about);
      setHeadline(user?.headline);
      // set the user links
      userDetails?.social?.map((item) => {
        if (item?.linkName === "linkedin") {
          setLinkedIn({
            ...linkedIn,
            linkName: "linkedin",
            link: item?.link,
          });
        } else if (item?.linkName === "github") {
          setGithub({
            ...github,
            linkName: "github",
            link: item?.link,
          });
        } else if (item?.linkName === "portfolio") {
          setPortfolio({
            ...portfolio,
            linkName: "portfolio",
            link: item?.link,
          });
        }
      });
    }
  }, [user]);

  return (
    <section>
      {loading ? <Components.PageLoader type="page" /> : null}
      {alert.showAlert && <p className={styles.errorHeading}>Error</p>}
      <form className={styles["am-input-box"]}>
        <Components.TextArea
          label={"Write a brief introduction about your self*"}
          placeholder={"add something about your self..."}
          value={aboutInput}
          onChange={handleAboutChange}
          maxCharacters={MAX_ABOUT_WORD_COUNT}
        />
        <Components.TextInput
          value={headline}
          label={"Headline*"}
          placeholder={"add your headline"}
          onChange={(e) => setHeadline(e)}
        />
        <div className={styles["am-social-input-text"]}>
          <h2>Add socials links</h2>
        </div>
        <div className={styles["am-social-input-container"]}>
          {SocialFields.map((field, i) => {
            if (i < 2) {
              return (
                <Components.TextInput
                  label={field.title}
                  placeholder={field.title}
                  leftIcon={field.icon}
                  value={field.value}
                  onChange={(e) => field.onChange(e)}
                  key={i}
                />
              );
            }
          })}
        </div>
        <br />
        <Components.TextInput
          label={SocialFields[2].title}
          placeholder={SocialFields[2].title}
          leftIcon={SocialFields[2].icon}
          value={SocialFields[2].value}
          onChange={(e) => SocialFields[2].onChange(e)}
        />
        <div className={styles["am-btns"]}>
          <button
            className={styles["am-cancel-btn"]}
            onClick={(e) => {
              e.preventDefault();
              dispatch(services.toggleEditAboutModal());
            }}
          >
            Cancel
          </button>
          <button className={styles["am-save-btn"]} onClick={handleSubmit}>
            Save
          </button>
        </div>
      </form>
    </section>
  );
}

export default AboutModal;
