import React, { useEffect, useCallback, useState, Fragment } from "react";
import CommentCard from ".";

function ReplyComment({ handleReply, allComments, parentId }) {
  const [count, setCount] = useState(0);

  const getRepliedComment = useCallback(() => {
    const replies = allComments.filter((item) => item.replyToId === parentId);
    setCount(replies?.length);
  }, [allComments, parentId]);

  useEffect(() => {
    getRepliedComment();
  }, [parentId, allComments]);

  return (
    <Fragment>
      {count ? (
        <div className="cm-reply-container">
          {allComments.map((cmt, i) => {
            if (cmt.replyToId === parentId)
              return (
                <CommentCard
                  key={`reply-${i}`}
                  comment={cmt}
                  handleReply={handleReply}
                  allComments={allComments}
                />
              );
          })}
        </div>
      ) : null}
    </Fragment>
  );
}

export default React.memo(ReplyComment);
