import React from "react";

import styles from "./no-data.module.scss";

function NoDataPage(props) {
  const { data, className } = props;

  return (
    <section className={`${styles.container} ${className}`}>
      <h3>{data ? data : "No Data found"}</h3>
    </section>
  );
}

export default NoDataPage;
