"use client";

import React, { useEffect, useMemo, useRef } from "react";
import { useRouter } from "next/navigation";
import { Icon } from "@iconify/react";
import PropTypes from "prop-types";
import Image from "next/image";

import courses from "@/data/premiumCourseData";
import styles from "./course.module.scss";
import Components from "@/components";
import utils from "@/utils";
import apis from "@/api";

function CourseCard(props) {
  const router = useRouter();

  const cardClickHandler = useMemo(() => {
    if (props.mode === "offline" || props.mode === "online") {
      return null;
    } else if (
      [
        "course",
        "progress",
        "mycourse",
        "course-video",
        "w-course-video",
      ].includes(props.type)
    ) {
      if (props.courseType === "b2b") return null;
      return `/courses/${props.seoTitle}/${props.videoTitle}`;
    } else if (props.type === "mybundle") {
      return "/profile/mybundles";
    } else {
      return `/videos/${props.seoTitle}`;
    }
  }, [props.title, props.mode]);
  const descriptionRef = useRef(null);

  const b2bCourseClick = async () => {
    const feedbackShared = await apis.isUserFeedbackExist(
      props.id,
      "live-coursefeedback"
    );
    if (feedbackShared.status === 200) return `/certificate?id=${props.id}`;
    else return `/certificate/feedback?id=${props.id}&type=b2b`;
  };

  function handleEdit(e) {
    e.stopPropagation();
    if (props.onEdit) props.onEdit();
    else {
      if (props.type === "mycourse") {
        utils.addToLocalStorage("curr_course", props.id);
        router.push(`/upload/course?id=${props.id}`);
      } else if (props.type === "mybundle") {
        utils.addToLocalStorage("curr_bundle", props.id);
        router.push(`/upload/bundle?id=${props.id}`);
      } else {
        router.push(`/upload/video`, {
          state: {
            isEdit: true,
          },
        });
      }
    }
  }

  useEffect(() => {
    if (descriptionRef?.current?.innerHTML && props.description) {
      descriptionRef.current.innerHTML = props.description;
    }
  }, [props.description]);

  return (
    <article
      className={`${styles.container} ${props.className}`}
      style={props.currentCourse ? { border: "0.5px solid orange" } : null}
      id={`${window.location.pathname}_${props.type}_${props.title}_Card`}
    >
      <a
        href={cardClickHandler}
        onClick={async () => {
          if (!cardClickHandler) {
            let url = await b2bCourseClick();
            window.location.href = url;
          }
        }}
        aria-disabled={props.type === "mybundle"}
        className={styles["anchor-level"]}
      ></a>
      <figure
        aria-label={
          props.type === "mybundle"
            ? "bundle"
            : props.mode === "offline" || props.mode === "online"
              ? props.mode
              : props.visibility
        }
        data-content={
          props.type === "mybundle"
            ? "Bundle"
            : props.mode === "offline" || props.mode === "online"
              ? props.mode
              : "Premium"
        }
      >
        <Image
          src={props.image}
          alt={props.title}
          width={500}
          height={500}
          onError={(e) => (e.target.src = "/images/fallback.png")}
        />
        {["w-course-video", "w-video"].includes(props.type) ? (
          <div className={styles["buttons"]}>
            <Components.Tooltip title="Remove from Wishlist" placement="left">
              <Icon
                icon="mdi:delete"
                onClick={(e) => {
                  e.stopPropagation();
                  props.onDelete?.();
                }}
              />
            </Components.Tooltip>
          </div>
        ) : null}
        {props.type === "mycourse" ||
        props.type === "myvideo" ||
        props.type === "mybundle" ? (
          <div className={styles["buttons"]}>
            {props.visibility !== "restricted" ? (
              <Components.Tooltip title={props.visibility} placement="left">
                {props.visibility === "public" ? (
                  <Icon icon="mdi:eye" onClick={(e) => e.stopPropagation()} />
                ) : (
                  <Icon
                    icon="mdi:eye-off"
                    onClick={(e) => e.stopPropagation()}
                  />
                )}
              </Components.Tooltip>
            ) : null}
            <Components.Tooltip title="Edit" placement="bottom">
              <Icon icon="mdi:pencil" onClick={(e) => handleEdit(e)} />
            </Components.Tooltip>
            <Components.Tooltip title="Delete" placement="bottom">
              <Icon
                icon="mdi:delete"
                onClick={(e) => {
                  e.stopPropagation();
                  props.onDelete?.();
                }}
              />
            </Components.Tooltip>
          </div>
        ) : null}
      </figure>
      <summary>
        {props.type === "course" ? (
          <p className={styles["tags"]}>
            <span className={styles.tag}>{props?.tag?.split(",")[0]}</span>
          </p>
        ) : null}
        <h2>{props.title}</h2>
        {props.type === "course" ? (
          <div className={styles["meta"]}>
            <p>
              No. of videos: <span>{utils.formatNumber(props.videoCount)}</span>
            </p>
            {props.visibility !== "restricted" ? (
              <p>
                Course time:{" "}
                <span>{(props.timeCount / 60).toFixed(1)} hours</span>
              </p>
            ) : null}
          </div>
        ) : (
          <p className={styles["description"]} ref={descriptionRef}>
            {props.description}
          </p>
        )}
        {props.type === "progress" &&
        !courses.liveCourses.find((course) => course.courseId === props.id) ? (
          <div className={styles["progress"]}>
            <p className={styles["pgline"]}>
              <span style={{ width: `${props.progress}%` }}></span>
            </p>
            <p className={styles["pgtext"]}>
              {`${props.progress > 100 ? 100 : props.progress}%`} completed
            </p>
          </div>
        ) : props.type === "mycourse" || props.type === "myvideo" ? (
          <div className={styles["mycourse-meta"]}>
            {props.type === "mycourse" ? (
              <>
                <p>
                  Students: <span>{utils.formatNumber(props.enrollCount)}</span>
                </p>
                {props.mode === "offline" || props.mode === "online" ? null : (
                  <p>
                    Videos: <span>{utils.formatNumber(props.videoCount)}</span>
                  </p>
                )}
              </>
            ) : (
              <>
                <p>
                  Views: <span>{utils.formatNumber(props.viewCount)}</span>
                </p>
                <p>
                  Likes: <span>{utils.formatNumber(props.likeCount)}</span>
                </p>
              </>
            )}
          </div>
        ) : (
          <Components.Tooltip title={props.creatorInfo?.name} placement="top">
            <footer
              onClick={(e) => {
                e.stopPropagation();
                router.push(`/profile/${props.creatorInfo.username}`);
              }}
            >
              <Image
                src={props.creatorInfo?.image}
                alt={props.creatorInfo?.name}
                width={50}
                height={50}
              />
              <div className={styles["info"]}>
                <h3>{props.creatorInfo?.name}</h3>
                <p>Instructor</p>
              </div>
            </footer>
          </Components.Tooltip>
        )}
      </summary>
    </article>
  );
}

export default React.memo(CourseCard);

CourseCard.propTypes = {
  tag: PropTypes.string,
  vSerial: PropTypes.number,
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  videoCount: PropTypes.number,
  timeCount: PropTypes.number,
  enrollCount: PropTypes.number,
  viewCount: PropTypes.number,
  likeCount: PropTypes.number,
  progress: PropTypes.number,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  visibility: PropTypes.string,
  creatorInfo: PropTypes.exact({
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    username: PropTypes.string,
  }),
};
