"use client";

import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/navigation";
import React, { useEffect } from "react";

import { Drawer } from "@mui/material";

import Comments from "./sections/comments";
import styles from "./styles.module.scss";
import Content from "./sections/content";
import services from "@/store/services";
import Notes from "./sections/notes";

function VideoSidebar() {
  const { isEnrolled, articleInfo, videoInfo } = useSelector(
    (state) => state.video
  );
  const { on, next, comments, notes } = useSelector((state) => state.videobar);
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    const hash = router.asPath?.split("#")[1];
    if (!hash) {
      return;
    } else {
      dispatch(services.toggleCommentContent());
    }
  }, []);

  const componentsSwitch = () => {
    if (next) return <Content />;

    if (comments) return <Comments />;

    if (notes) return <Notes />;
  };

  const IconsList = () => (
    <section className={styles["icons-list"]}>
      <article
        id="video-list"
        className={styles["icon-box"]}
        style={{
          backgroundColor: next ? "var(--brand-color)" : "transparent",
        }}
        onClick={() => dispatch(services.toggleNextContent())}
      >
        <svg
          width="25"
          height="25"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 15H3.75M20 7.5H3.75M15 22.5H3.75M20 15L26.25 18.75L20 22.5V15Z"
            stroke={!next ? "var(--text-color)" : "white"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={!next ? "var(--text-color)" : "white"}
          />
        </svg>

        <p
          className={styles["icon-title"]}
          style={{
            color: next ? "var(--white)" : "var(--text-color)",
          }}
        >
          Videos
        </p>
      </article>
      <article
        id="comments"
        className={styles["icon-box"]}
        style={{
          backgroundColor: comments ? "var(--brand-color)" : "transparent",
          opacity: articleInfo ? "0.3" : "1",
        }}
        onClick={() => {
          if (articleInfo && !videoInfo) {
            dispatch(
              services.warningAlert("Cannot use comments for articles!")
            );
          } else {
            dispatch(services.toggleCommentContent());
          }
        }}
      >
        <svg
          width="25"
          height="25"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.8125 5.625V24.375H11.7994L15 27.5756L18.2006 24.375H27.1875V5.625H2.8125ZM4.6875 7.5H25.3125V22.5H17.4244L15 24.9244L12.5756 22.5H4.6875V7.5ZM8.4375 10.3125V12.1875H21.5625V10.3125H8.4375ZM8.4375 14.0625V15.9375H21.5625V14.0625H8.4375ZM8.4375 17.8125V19.6875H17.8125V17.8125H8.4375Z"
            fill={!comments ? "var(--text-color)" : "white"}
          />
        </svg>

        <p
          className={styles["icon-title"]}
          style={{
            color: comments ? "var(--white)" : "var(--text-color)",
            opacity: articleInfo ? "0.3" : "1",
          }}
        >
          Comments
        </p>
      </article>
      <article
        id="notes"
        className={styles["icon-box"]}
        style={{
          backgroundColor: notes ? "var(--brand-color)" : "transparent",
          opacity: articleInfo ? "0.3" : "1",
        }}
        onClick={() => {
          if (articleInfo && !videoInfo) {
            dispatch(services.warningAlert("Cannot use notes for articles!"));
          } else {
            dispatch(services.toggleNotes());
          }
        }}
      >
        <svg
          width="25"
          height="25"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1_2190)">
            <path
              d="M23.3334 25H5.00004V6.66667H16.0167L17.6834 5H5.00004C4.55801 5 4.13409 5.17559 3.82153 5.48816C3.50897 5.80072 3.33337 6.22464 3.33337 6.66667V25C3.33337 25.442 3.50897 25.866 3.82153 26.1785C4.13409 26.4911 4.55801 26.6667 5.00004 26.6667H23.3334C23.7754 26.6667 24.1993 26.4911 24.5119 26.1785C24.8244 25.866 25 25.442 25 25V12.5L23.3334 14.1667V25Z"
              fill={!notes ? "var(--text-color)" : "white"}
            />
            <path
              d="M27.9417 4.86666L25.1333 2.05833C25.0087 1.93335 24.8606 1.8342 24.6976 1.76654C24.5346 1.69889 24.3598 1.66406 24.1833 1.66406C24.0068 1.66406 23.8321 1.69889 23.6691 1.76654C23.506 1.8342 23.358 1.93335 23.2333 2.05833L11.8083 13.55L10.8833 17.5583C10.8439 17.7526 10.8481 17.9532 10.8955 18.1458C10.9429 18.3383 11.0323 18.5179 11.1574 18.6717C11.2826 18.8255 11.4402 18.9496 11.619 19.0352C11.7978 19.1208 11.9934 19.1657 12.1917 19.1667C12.2941 19.1779 12.3975 19.1779 12.5 19.1667L16.5417 18.275L27.9417 6.76666C28.0666 6.64203 28.1658 6.49396 28.2335 6.33094C28.3011 6.16793 28.3359 5.99316 28.3359 5.81666C28.3359 5.64016 28.3011 5.4654 28.2335 5.30238C28.1658 5.13936 28.0666 4.99129 27.9417 4.86666ZM15.675 16.7333L12.625 17.4083L13.3333 14.3833L21.9333 5.72499L24.2833 8.07499L15.675 16.7333ZM25.225 7.13333L22.875 4.78333L24.1667 3.46666L26.5333 5.83333L25.225 7.13333Z"
              fill={!notes ? "var(--text-color)" : "white"}
            />
          </g>
          <defs>
            <clipPath id="clip0_1_2190">
              <rect width="30" height="30" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <p
          className={styles["icon-title"]}
          style={{
            color: notes ? "var(--white)" : "var(--text-color)",
          }}
        >
          Notes
        </p>
      </article>
    </section>
  );

  return (
    <>
      <section className={`${styles["container"]}`}>
        <aside className={styles["saperator"]}>
          <article
            className={`${styles["toggle-btn"]} ${on ? "" : styles["toggle-close"]}`}
            onClick={() => dispatch(services.toggleBar())}
          >
            <svg
              width="12"
              height="14"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={on ? styles["open"] : styles["close"]}
            >
              <path
                d="M6.71672 6.71713C7.10725 6.3266 7.10725 5.69344 6.71672 5.30291L2.12083 0.707019C1.73036 0.316554 1.09729 0.316554 0.706829 0.707019C0.316364 1.09748 0.316364 1.73055 0.706829 2.12102L3.89272 5.30691C4.28325 5.69744 4.28325 6.3306 3.89272 6.72113L0.706831 9.90702C0.316689 10.2972 0.317587 10.93 0.708834 11.319C1.09851 11.7065 1.72825 11.7056 2.11683 11.317L6.71672 6.71713Z"
                fill="var(--text-color)"
              />
            </svg>
          </article>
          <IconsList />
        </aside>
        <article className={styles["video-sidebar"]}>
          <Drawer
            variant="permanent"
            className={`${styles.drawer} ${on ? "" : styles.close}`}
            classes={{
              paper: `${styles[`drawer-${on ? "open" : "close"}`]}`,
            }}
            anchor="right"
          >
            {componentsSwitch()}
          </Drawer>
        </article>
      </section>
    </>
  );
}

export default React.memo(VideoSidebar);
