"use client";

import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SocketContext from "@/socket/Context";
import services from "@/store/services";
import Components from "@/components";
import "./certificate-req.scss";
import api from "@/api";

const filter = createFilterOptions();

const Rating = ({ state, setState }) => {
  return (
    <div className="cr-rating-cont">
      <svg
        id="1"
        width="24"
        height="24"
        viewBox="0 0 48 48"
        onClick={() => setState(1)} //because ~ selector in css, check by id in inspect
        className={state >= 1 ? "cr-rated" : ""}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M24 34.54L36.36 42L33.09 27.94L44 18.49L29.62 17.25L24 4L18.38 17.25L4 18.49L14.91 27.94L11.64 42L24 34.54Z" />
      </svg>
      <svg
        width="24"
        height="24"
        viewBox="0 0 48 48"
        onClick={() => setState(2)} //because ~ selector in css
        className={state >= 2 ? "cr-rated" : ""}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M24 34.54L36.36 42L33.09 27.94L44 18.49L29.62 17.25L24 4L18.38 17.25L4 18.49L14.91 27.94L11.64 42L24 34.54Z" />
      </svg>
      <svg
        width="24"
        height="24"
        viewBox="0 0 48 48"
        onClick={() => setState(3)}
        className={state >= 3 ? "cr-rated" : ""}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M24 34.54L36.36 42L33.09 27.94L44 18.49L29.62 17.25L24 4L18.38 17.25L4 18.49L14.91 27.94L11.64 42L24 34.54Z" />
      </svg>
      <svg
        width="24"
        height="24"
        viewBox="0 0 48 48"
        onClick={() => setState(4)} //because ~ selector in css
        className={state >= 4 ? "cr-rated" : ""}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M24 34.54L36.36 42L33.09 27.94L44 18.49L29.62 17.25L24 4L18.38 17.25L4 18.49L14.91 27.94L11.64 42L24 34.54Z" />
      </svg>
      <svg
        id="5"
        width="24"
        height="24"
        viewBox="0 0 48 48"
        onClick={() => setState(5)} //because ~ selector in css
        className={state >= 5 ? "cr-rated" : ""}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M24 34.54L36.36 42L33.09 27.94L44 18.49L29.62 17.25L24 4L18.38 17.25L4 18.49L14.91 27.94L11.64 42L24 34.54Z" />
      </svg>
    </div>
  );
};

const CourseFeedbackContent = ({ courseId, handleOnNext }) => {
  const [feedReport, setFeedReport] = useState({
    content: 0,
    mentor: 0,
    platform: 0,
    community: 0,
    suggestions: "",
  });

  const initialFeedText = {
    content: 0,
    mentor: 0,
    platform: 0,
    community: 0,
    suggestions: "",
  };

  const dispatch = useDispatch();

  const isEmpty = () => {
    if (
      feedReport.content === 0 ||
      feedReport.mentor === 0 ||
      feedReport.platform === 0 ||
      feedReport.community === 0 ||
      feedReport.suggestions === ""
    )
      return true;
    else return false;
  };

  const handleOnSubmit = async () => {
    if (isEmpty()) {
      dispatch(services.failureAlert("Some fields are empty!!"));
      return;
    }
    const courseFeedback = {
      type: "coursefeedback",
      message: feedReport.suggestions,
      feedback: {
        content: feedReport.content,
        mentor: feedReport.mentor,
        platform: feedReport.platform,
        community: feedReport.community,
      },
      courseId,
    };

    try {
      let response = await api.postFeedback(courseFeedback);
      if (response.status < 400) {
        dispatch(services.successAlert("Course feedback submitted!."));
        setFeedReport(initialFeedText);
        handleOnNext();
        return;
      } else {
        dispatch(
          services.failureAlert(
            response.data?.error || "Can't submit feedback!"
          )
        );
        return;
      }
    } catch (error) {
      dispatch(
        services.failureAlert(error.data?.error || "Can't submit feedback")
      );
    }
  };
  return (
    <>
      <div className="cr-rating-row">
        <div>
          <h3>Content Quality:</h3>
        </div>
        <div>
          <Rating
            key="content"
            state={feedReport?.content}
            setState={(rating) =>
              setFeedReport((prev) => ({ ...prev, content: rating }))
            }
          />
        </div>
      </div>
      <div className="cr-rating-row">
        <div>
          <h3>Mentor Experience:</h3>
        </div>
        <div>
          <Rating
            key="mentor"
            state={feedReport?.mentor}
            setState={(rating) =>
              setFeedReport((prev) => ({ ...prev, mentor: rating }))
            }
          />
        </div>
      </div>
      <div className="cr-rating-row">
        <div>
          <h3>Platform Experience:</h3>
        </div>
        <div>
          <Rating
            key="platform"
            state={feedReport?.platform}
            setState={(rating) =>
              setFeedReport((prev) => ({ ...prev, platform: rating }))
            }
          />
        </div>
      </div>
      <div className="cr-rating-row">
        <div>
          <h3>Community Interactions:</h3>
        </div>
        <div>
          <Rating
            key="community"
            state={feedReport?.community}
            setState={(rating) =>
              setFeedReport((prev) => ({ ...prev, community: rating }))
            }
          />
        </div>
      </div>
      <div className="cr-textfield-msg">
        <h3>Feedback you want to share: </h3>
        <textarea
          key="suggestions"
          className="cr-msg-cont"
          value={feedReport?.suggestions}
          onChange={(e) =>
            setFeedReport((prev) => ({ ...prev, suggestions: e.target.value }))
          }
          placeholder="Feel free to drop any feedback related to content, platform, community and mentor."
        />
      </div>
      <div className="sf-btns-cont">
        <button
          className={isEmpty() ? "sf-msg-btn1" : "sf-msg-btn1 sf-btn-active"}
          disabled={isEmpty()}
          onClick={handleOnSubmit}
        >
          Next
        </button>
      </div>
    </>
  );
};

const CertificateModalContent = ({ courseId }) => {
  const { socket } = useContext(SocketContext).SocketState;
  const user = useSelector((state) => state?.user?.users);
  const [loading, setLoading] = useState(false);
  const [reqText, setReqText] = useState({
    type: "Completion",
    name: "",
    email: "",
    college: "",
    degree: "",
    gradYear: "",
    semester: "",
  });
  const dispatch = useDispatch();

  const initialReqText = {
    type: "Completion",
    name: "",
    email: "",
    college: "",
    degree: "",
    gradYear: "",
    semester: "",
  };

  const QueryList = [
    {
      value: "Completion",
    },
  ];

  const CollegeList = [
    {
      value: "Lovely Professional University",
    },
  ];

  const DegreeList = [
    {
      value: "B.tech",
    },
    {
      value: "M.tech",
    },
    {
      value: "BCA",
    },
    {
      value: "MCA",
    },
    {
      value: "Phd",
    },
  ];

  const defaultTypeProps = {
    options: QueryList,
    getOptionLabel: (option) => option.value,
  };

  const defaultCollegeProps = {
    options: CollegeList,
    getOptionLabel: (option) => option?.value || option,
  };

  const defaultDegreeProps = {
    options: DegreeList,
    getOptionLabel: (option) => option.value,
  };

  const isEmpty = () => {
    if (
      reqText.name === "" ||
      reqText.email === "" ||
      reqText.degree === "" ||
      reqText.college === "" ||
      reqText.gradYear === "" ||
      reqText.semester === "" ||
      reqText.type === ""
    )
      return true;
    else return false;
  };

  useEffect(() => {
    if (user?.email) {
      setReqText((prev) => ({
        ...prev,
        name: user?.name,
        email: user.email,
      }));
      initialReqText.name = user?.name;
      initialReqText.email = user.email;
    }
  }, [user]);

  const handleOnSubmit = async () => {
    if (isEmpty()) {
      dispatch(services.failureAlert("Some fields are empty!"));
      return;
    }
    const certificateRequest = {
      type: "certificate",
      isApproved: "Pending",
      formEntity: {
        college: reqText.college,
        gradYear: reqText.gradYear,
        semester: reqText.semester,
        degree: reqText.degree,
        certificateType: reqText.type,
      },
      courseId,
    };

    try {
      setLoading(true);
      //need to merge these query Very Important
      let response = await api.postRequest(certificateRequest);
      if (response.status === 201) {
        socketHandleCertificates(
          {
            userId: user?._id,
            entityId: response.data?.result?._id,
          },
          socket
        );
        dispatch(services.successAlert("Certificate request submitted."));
        dispatch(services.fetchEnrollment());
        setReqText(initialReqText);
        dispatch(services.toggleCertificateModal());
      } else {
        dispatch(
          services.failureAlert(
            response.data?.error ||
              response.data?.data?.error ||
              "Can't submit certificate request!"
          )
        );
      }
      setLoading(false);
    } catch (error) {
      dispatch(services.failureAlert("Can't submit certificate request!"));
    }
  };

  return (
    <>
      <div className="sn-textfield-row">
        <Autocomplete
          {...defaultTypeProps}
          className="sn-textfield"
          defaultValue={reqText?.type}
          getOptionLabel={(option) => option.value}
          onChange={(e, v) =>
            setReqText((prev) => ({ ...prev, type: v.value }))
          }
          autoHighlight
          blurOnSelect
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Certificate Type."
              variant="standard"
            />
          )}
        />
        <input
          disabled
          placeholder="Enter Your Name"
          value={reqText?.name}
          className="sn-textfield"
        />
      </div>
      <div className="sn-textfield-row">
        <input
          disabled
          placeholder="Email Address"
          value={reqText?.email}
          className="sn-textfield"
          onChange={(e) =>
            setReqText((prev) => ({ ...prev, email: e.target.value }))
          }
        />
        <Autocomplete
          {...defaultCollegeProps}
          className="sn-textfield"
          value={reqText?.college || ""}
          onChange={(event, option) => {
            if (typeof option === "string") {
              setReqText((prev) => ({ ...prev, college: option }));
            } else if (option && option.inputValue) {
              setReqText((prev) => ({ ...prev, college: option.inputValue }));
            } else {
              setReqText((prev) => ({ ...prev, college: option.value }));
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            const isExisting = options.some(
              (option) => inputValue === option.value
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                value: `Add "${inputValue}"`,
              });
            }
            return filtered;
          }}
          autoHighlight
          blurOnSelect
          disableClearable
          handleHomeEndKeys
          selectOnFocus
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="College Name"
              variant="standard"
            />
          )}
        />
      </div>
      <div className="sn-textfield-row">
        <Autocomplete
          {...defaultDegreeProps}
          className="sn-textfield"
          value={reqText?.degree || ""}
          onChange={(event, option) => {
            if (typeof option === "string") {
              setReqText((prev) => ({ ...prev, degree: option }));
            } else if (option && option.inputValue) {
              setReqText((prev) => ({ ...prev, degree: option.inputValue }));
            } else {
              setReqText((prev) => ({ ...prev, degree: option.value }));
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            const isExisting = options.some(
              (option) => inputValue === option.value
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                value: `Add "${inputValue}"`,
              });
            }
            return filtered;
          }}
          autoHighlight
          blurOnSelect
          disableClearable
          handleHomeEndKeys
          selectOnFocus
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Degree Type."
              variant="standard"
            />
          )}
        />
        <input
          placeholder="Semester"
          value={reqText?.semester}
          className="sn-textfield"
          onChange={(e) =>
            setReqText((prev) => ({ ...prev, semester: e.target.value }))
          }
        />
      </div>
      <div className="sn-textfield-row">
        <input
          placeholder="Graduation Year"
          value={reqText?.gradYear}
          className="sn-textfield"
          onChange={(e) =>
            setReqText((prev) => ({ ...prev, gradYear: e.target.value }))
          }
        />
      </div>
      <div className="sf-btns-cont">
        <Components.LoadingBtn
          loading={loading}
          className={"sf-msg-btn sf-btn-active"}
        >
          <button
            disabled={isEmpty()}
            className={isEmpty() ? "sf-msg-btn" : "sf-msg-btn sf-btn-active"}
            onClick={handleOnSubmit}
          >
            Submit
          </button>
        </Components.LoadingBtn>
      </div>
    </>
  );
};

function CertificateReqModal({ show, courseId }) {
  return (
    <>
      <div className="ca-underline" />
      <div className="cr-main-cont">
        {show === 1 ? (
          <CourseFeedbackContent
            handleOnNext={() => setShow(2)}
            courseId={courseId}
          />
        ) : (
          <CertificateModalContent courseId={courseId} />
        )}
      </div>
    </>
  );
}

export default CertificateReqModal;
