import CardSkeleton from "./components/cardSkeleton";
import VideoCardSK from "./components/videoCardSK";
import AlumniCard from "./components/alumniCard";
import SkeletonElement from "./SkeletonElement";
import RowCardSkeleton from "./RowCardSkeleton";

export default {
  SkeletonElement,
  RowCardSkeleton,
  CardSkeleton,
  AlumniCard,
  VideoCardSK,
};
