import React from "react";
import "./Skeleton.scss";

function SkeletonElement({ type, display }) {
  const classes = `skeleton ${type}`;
  return (
    <span
      className={classes}
      style={{ display: display === "inline" ? "inline-flex" : "flex" }}
    ></span>
  );
}

export default SkeletonElement;
