"use client";

import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams, useRouter } from "next/navigation";
import { useSelector } from "react-redux";

import { socketComment, socketMention, socketReply } from "@/socket";
import CommentField from "../../partials/comment-field";
import CommentCard from "../../partials/comment";
import styles from "./comments.module.scss";
import SocketContext from "@/socket/Context";
import services from "@/store/services";
import useApi from "@/hooks/useApi";
import sk from "@/skeletons";
import api from "@/api";

function Comments() {
  const { courseInfo, videoInfo } = useSelector((state) => state.video);
  const { socket } = useContext(SocketContext).SocketState;
  const user = useSelector((state) => state?.user?.users);

  const [clickedLatestComments, setClickedLatestComments] = useState(false);
  const [showOlderComments, setShowOlderComments] = useState(false);
  const [showLatestComments, setShowNewerComments] = useState(false);
  const [currentCommentsPage, setCurrentCommentsPage] = useState(1);
  const [allComments, setAllComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [validTags, setValidTags] = useState([]);
  const [isReply, setIsReply] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [load, setLoad] = useState(false);

  const router = useRouter();
  const params = useParams();
  const { slug } = params;

  const replyIdRef = useRef({ id: null, username: "", userId: null });
  const textRef = useRef();
  const LIMIT = 20;

  const {
    data: comments,
    loading: comloading,
    request: loadcomments,
  } = useApi(api.getComments);

  useEffect(() => {
    if (videoInfo) {
      const hash = router.asPath?.split("#")?.[1];
      if (hash) {
        loadcomments(videoInfo._id, currentCommentsPage + 1, LIMIT, hash);
      } else {
        loadcomments(videoInfo._id, currentCommentsPage, LIMIT);
      }
    }
  }, [videoInfo, slug, load]);

  useEffect(() => {
    if (!comloading && comments?.data?.length) {
      setAllComments((prev) => {
        let _arr = [...prev];
        let resArr = [];
        comments?.data?.forEach((item) => {
          if (!_arr.find((comm) => comm._id === item._id)) {
            resArr.push(item);
          }
        });
        if (clickedLatestComments) {
          return [...resArr, ..._arr];
        } else {
          return [..._arr, ...resArr];
        }
      });

      let prevPage;
      let nextPage;
      let limit = null;
      // set nextPage or proevious page
      if (comments?.next && comments?.next?.page) {
        nextPage = comments?.next?.page;
        setShowOlderComments(true);
        limit = comments?.next?.limit;
      } else {
        setShowOlderComments(false);
        nextPage = null;
      }

      if (comments?.previous && comments?.previous?.page) {
        // set the page number
        prevPage = comments?.next?.page;
        setShowNewerComments(true);
        limit = comments?.previous?.limit;
      } else {
        setShowNewerComments(false);
        prevPage = null;
      }

      // calculate the page number
      let _currentPage = 1;
      const _flag = prevPage && nextPage;
      if (comments?.previous && comments?.next) {
        _currentPage = (comments?.previous?.page + comments?.next?.page) / 2;
      } else if (comments?.previous) {
        _currentPage = comments?.previous?.page + 1;
      } else if (comments?.next) {
        _currentPage = comments?.next?.page - 1;
      }
      setCurrentCommentsPage(_currentPage);

      //updating weather to show buttons or not
      if (limit) {
        let leftLimit = (_currentPage - 1) * limit;
        let rightLimit = _currentPage * limit;
        let commentsCount = allComments?.length;
        let flag = leftLimit < commentsCount && commentsCount <= rightLimit;
        if (comments?.previous && flag) {
          setShowNewerComments(false);
        }
        if (comments?.next && flag) {
          setShowOlderComments(false);
        }
        if (flag) {
          setShowNewerComments(true);
          setShowOlderComments(true);
        }
      }
      setRefresh(false);
      setClickedLatestComments(false);
    }
    // setClickedOlderComments(false);
  }, [comments, comloading, refresh]);

  const onSubmitComment = async (e) => {
    e.preventDefault();
    if (!user.email) {
      dispatchEvent(services.toggleSigninModal());
      return;
    }
    if (!commentText.includes(`@${replyIdRef.current.username}`)) {
      replyIdRef.current.id = null;
    }

    let _text = commentText;

    validTags.forEach((user) => {
      if (_text.includes("@" + user?.username)) {
        _text = _text.replace(`@${user?.username}`, "@" + user?._id);
      }
    });

    const details = {
      videoId: videoInfo?._id,
      text: _text,
      replyToId: replyIdRef.current.id,
    };

    let replyUserTagged = commentText.includes(
      `@${replyIdRef.current.username}`
    );

    let replyTagId = null;
    if (isReply && replyUserTagged && commentText[0] === "@") {
      let _text = commentText;
      details.text = _text.replace(
        `@${replyIdRef.current.username}`,
        `@${replyIdRef.current.userId}`
      );
      replyTagId = replyIdRef.current.userId;
    }

    let taggedUsers = [];
    const { data } = await api.validateTaggedUsers(
      validTags.map((user) => user.username)
    );
    if (data?.success === 200) {
      if (data.result) {
        taggedUsers = [...taggedUsers, ...data.result];
      }
    }

    details.taggedUser = taggedUsers.map((user) => user._id);

    if (replyTagId !== null)
      details.taggedUser = [...details.taggedUser, replyTagId];

    await api.saveComment(
      details,
      () => setCommentText(""),
      (commentId) => {
        setLoad((prev) => !prev);
        if (isReply && taggedUsers.length > 0) {
          // emmit for notification for comment mention
          socketMention(
            {
              userId: user?._id,
              entityId: commentId,
              type: "comment-mention",
              commentText: commentText,
              recieverIds: taggedUsers.map((tag) => tag._id),
            },
            socket
          );
          // emit notification for comment reply
          if (user.username !== replyIdRef.current?.username)
            socketMention(
              {
                userId: user?._id,
                entityId: commentId,
                type: "comment-mention",
                commentText: commentText,
                recieverIds: [replyIdRef.current?.userId],
              },
              socket
            );
        } else if (isReply) {
          // emit notification for comment reply
          if (user.username !== replyIdRef.current?.username)
            socketReply(
              {
                userId: user?._id,
                entityId: commentId,
                type: "comment-mention",
                commentText: commentText,
                recieverIds: [replyIdRef.current?.userId],
              },
              socket
            );
        } else if (taggedUsers.length > 0) {
          // emit notification for comment mention
          socketMention(
            {
              userId: user?._id,
              entityId: commentId,
              type: "comment-mention",
              commentText: commentText,
              recieverIds: taggedUsers.map((tag) => tag._id),
            },
            socket
          );
        } else {
          let recieverIds =
            courseInfo?.creatorId ===
            process.env.NEXT_PUBLIC_SUPER_ADMIN_USER_ID
              ? [courseInfo?.creatorId]
              : [
                  courseInfo?.creatorId,
                  process.env.NEXT_PUBLIC_SUPER_ADMIN_USER_ID,
                ];
          if (user?._id !== videoInfo.creatorId)
            socketComment(
              {
                recieverIds,
                type: "comment",
                userId: user?._id,
                entityId: commentId,
                commentText: commentText,
              },
              socket
            );
        }
      }
    );
  };

  const onHandleReply = (id, username, comment) => {
    if (!user.email) {
      dispatchEvent(services.toggleSigninModal());
      return;
    }
    setIsReply(true);
    setCommentText(`@${username} `);
    textRef.current.focus();
    replyIdRef.current = { id, username, userId: comment?.userInfo._id };
  };

  const handleSeeOldComments = () => {
    // call the api again
    setRefresh(true);
    if (videoInfo) loadcomments(videoInfo._id, currentCommentsPage + 1);
    setCurrentCommentsPage((prev) => prev + 1);
  };

  const handleSeeLatestComments = () => {
    // call the api again
    setRefresh(true);
    setClickedLatestComments(true);
    if (videoInfo) loadcomments(videoInfo._id, currentCommentsPage - 1);
    // currentCommentsPage((prev) => prev - 1);
  };

  return (
    <aside className={styles["container"]}>
      <header>
        <h3>Comments</h3>
      </header>
      <section className={styles["box"]}>
        <CommentField
          text={commentText}
          setText={setCommentText}
          textRef={textRef}
          handleSubmit={onSubmitComment}
          allTags={validTags}
          changeValidTags={(e) => setValidTags([...e])}
          creatorInfo={courseInfo?.creatorInfo[0]}
          userId={user?._id}
        />
      </section>
      <section className={styles["lists"]} id="comments-list">
        {!comloading ? (
          <>
            {/* see more button */}
            {!comloading ? (
              // comments?.data.length && comments?.data.length > LIMIT ? (
              showLatestComments ? (
                <button
                  className={styles["seeMore"]}
                  onClick={handleSeeLatestComments}
                >
                  See latest comments
                </button>
              ) : null
            ) : null}
            {allComments?.length ? (
              allComments?.map((comment, i) => {
                if (comment?.replyToId === null) {
                  return (
                    <>
                      <CommentCard
                        key={i}
                        comment={comment}
                        handleReply={onHandleReply}
                        allComments={comments?.data}
                        id={comment?._id}
                      />
                    </>
                  );
                }
              })
            ) : (
              <div className={styles["empty"]}>
                <p>No Comments</p>
              </div>
            )}
            {/* see more button */}
            {!comloading ? (
              // comments?.data.length && comments?.data.length > LIMIT ? (
              showOlderComments ? (
                <button
                  className={styles["seeMore"]}
                  onClick={handleSeeOldComments}
                >
                  See old comments
                </button>
              ) : null
            ) : null}
          </>
        ) : (
          <sk.RowCardSkeleton number="5" type="sk-comment-card" />
        )}
      </section>
    </aside>
  );
}

export default React.memo(Comments);
