"use client";

// taken from https://github.com/videojs/video.js/blob/master/docs/guides/react.md
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import "videojs-hotkeys";
import videojs from "video.js";
import "@videojs/http-streaming";
import "video.js/dist/video-js.css";
import "videojs-quality-selector-hls";
import "videojs-contrib-quality-levels";
import { Icon } from "@iconify/react";
import dynamic from "next/dynamic";

import { addbykey, getbykey, updatebykey } from "@/utils/indexdb";
import VideoTimer from "@/components/atoms/video-timer";
import services from "@/store/services";
import "./styles.scss";

let player;
let currentQuality;
let currentPlaybackRate = 1;

const landscapeFullscreen = dynamic(
  () => import("videojs-landscape-fullscreen"),
  { ssr: false } // Disable server-side rendering
);

const VideoJS = (props) => {
  const { certificate, preEnroll, postEnroll, notes, newVideoPage } =
    useSelector((state) => state.tours);
  const user = useSelector((state) => state?.user?.users);
  const [viewedTime, setViewedTime] = useState(0);
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const playerRef = useRef(null);
  const videoRef = useRef(null);
  const readyRef = useRef(null);
  const seekRef = useRef(null);
  const {
    options,
    handleIsPlay,
    handleQuality,
    handlePlayrate,
    hoverControls,
    setRestrictVideo,
  } = props;

  const { violationError } = useSelector((state) => state.modal);

  async function changeStatus(e) {
    const player = playerRef.current;
    e?.stopPropagation();
    if (player) {
      if (player.paused()) await player?.play();
      else await player?.pause();
    }
  }

  function setCurrentTime(time) {
    const player = playerRef.current;
    return function (e) {
      e.stopPropagation();
      if (player) {
        const now = player.currentTime();
        player.currentTime(now + time);
      }
    };
  }

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  function changeVolume(amount) {
    const player = playerRef.current;
    return function (e) {
      e.stopPropagation();
      if (player) {
        let newVolume = player.volume() + amount;
        if (newVolume > 1) newVolume = 1;
        if (newVolume < 0) newVolume = 0;
        player.volume(newVolume);
      }
    };
  }

  function toggleMute(e) {
    const player = playerRef.current;
    e.stopPropagation();
    if (player) {
      player.muted(!player.muted());
    }
  }

  const handleKeyDown = (e) => {
    const player = playerRef.current;
    const playerElement = player?.el();

    const targetTagName = e.target.tagName.toLowerCase();

    const isInputElement =
      targetTagName === "input" ||
      targetTagName === "textarea" ||
      targetTagName === "select";

    if (
      playerElement?.classList.contains("vjs-user-active") ||
      isInputElement
    ) {
      return;
    }

    switch (e.key) {
      case "ArrowRight":
        e.preventDefault();
        setCurrentTime(10)(e);
        break;
      case "ArrowLeft":
        e.preventDefault();
        setCurrentTime(-10)(e);
        break;
      case "ArrowUp":
        e.preventDefault();
        changeVolume(0.1)(e);
        break;
      case "ArrowDown":
        e.preventDefault();
        changeVolume(-0.1)(e);
        break;
      case "m":
        e.preventDefault();
        toggleMute(e);
        break;
      case " ":
        e.preventDefault();

        if (player.paused()) player.play();
        else player.pause();
        break;
      default:
        break;
    }
  };

  // for pausing video
  const handleVisibilityChange = () => {
    const player = playerRef.current;
    if (document.hidden && player) {
      player.pause();
    }
  };

  // pause video if their is any bot
  useEffect(() => {
    if (typeof window !== "undefined") {
      document.addEventListener("visibilitychange", handleVisibilityChange);
      const isBot = /bot|crawler|spider|crawling/i.test(navigator.userAgent);
      if (isBot) {
        player.pause();
        setTimeout(() => {
          player.play();
        }, 5000);
      }
    }
  }, []);

  // Free Time Access
  useEffect(() => {
    const getCurrentViewTime = async () => {
      let playMetaData = await getbykey(
        "nonauth_video_player_db",
        "totalPlayTime"
      );

      let currTotalTime = playMetaData?.playTime ?? null;
      let date = playMetaData?.date ?? null;

      if (currTotalTime && date) {
        //NOTE - Check only date not time
        const storedDate = new Date(date);
        const today = new Date();
        storedDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);

        if (storedDate < today) {
          setViewedTime(0);
        } else {
          setViewedTime(currTotalTime);
          if (currTotalTime >= 900) {
            setRestrictVideo(true);
          }
        }
      }
    };
    getCurrentViewTime();
  }, []);

  useEffect(() => {
    if (typeof window === "undefined" || !videoRef.current) return;
    let interval;
    let playProgressInterval;
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");

      videoElement.classList.add(
        "video-js",
        "vjs-default-skin",
        "vjs-matrix",
        `${props.className}`
      );
      videoRef.current.appendChild(videoElement);
      const player = (playerRef.current = videojs(
        videoElement,
        options,
        () => {}
      ));
      player.qualitySelectorHls({
        displayCurrentQuality: false,
        vjsIconClass: "vjs-icon-cog",
      });
      try {
        if (landscapeFullscreen)
          player.landscapeFullscreen?.({
            fullscreen: {
              enterOnRotate: true,
              exitOnRotate: true,
              alwaysInLandscapeMode: true,
              iOS: true,
            },
          });
      } catch (error) {
        console.error("Error initializing landscapeFullscreen plugin:", error);
      }

      player.on("pause", () => {
        setStatus("paused");
      });
      player.on("play", () => {
        setStatus("playing");
      });
      player.on("ended", () => {
        setStatus("paused");
      });
      player.on("seeked", () => {
        seekRef.current = Number(player.currentTime()) - Number(viewedTime);
      });

      player.ready(async () => {
        readyRef.current = true;
        const videoElem = player?.tech()?.el(); // Get native video element

        // Function to check and reset playback rate
        const maxPlaybackRate = 2.0;
        const enforcePlaybackRate = async () => {
          const isPlaying = !player.paused(); //
          if (!violationError) {
            if (videoElem && videoElem?.playbackRate > maxPlaybackRate) {
              console.warn(
                `Playback rate ${videoElem?.playbackRate} exceeds limit.`
              );
              player.pause();
              if (!violationError && isPlaying) {
                dispatch(services.toggleVideoWarningModal(true));
              }
            }
          }
        };

        let playMetaData = await getbykey(
          "nonauth_video_player_db",
          "totalPlayTime"
        );
        let currTotalTime = playMetaData?.playTime ?? null;
        let date = playMetaData?.date ?? null;

        const updatePlayProgress = async () => {
          const currentTime = player.currentTime();
          const isPlaying = !player.paused();

          if (isPlaying) {
            if (!currTotalTime) currTotalTime = 0;
            //NOTE - Checking only date
            const storedDate = new Date(date);
            const today = new Date();
            storedDate.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);

            if (storedDate < today) currTotalTime = 0;
            const seekedTime = seekRef.current;
            const updatedTime =
              currTotalTime +
              (currentTime - seekedTime > 0 ? currentTime - seekedTime : 0);

            setViewedTime(updatedTime);

            if (!currTotalTime) {
              await addbykey(
                "nonauth_video_player_db",
                { playTime: updatedTime, date: new Date().toISOString() },
                "totalPlayTime"
              );
            } else {
              await updatebykey(
                "nonauth_video_player_db",
                "totalPlayTime",
                { playTime: updatedTime, date: new Date().toISOString() },
                "custom"
              );
            }

            if (updatedTime > 900) {
              setRestrictVideo(true);
            }
          }
        };

        // Use MutationObserver to detect external changes
        const observer = new MutationObserver(enforcePlaybackRate);
        observer.observe(videoElement, {
          attributes: true,
          attributeFilter: ["playbackRate"],
        });

        // Fallback: Polling every 500ms (in case MutationObserver misses it)
        interval = setInterval(enforcePlaybackRate, 2000);
        playProgressInterval = setInterval(updatePlayProgress, 10000);
      });

      videoElement.addEventListener("contextmenu", handleContextMenu);
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("visibilitychange", handleVisibilityChange);

      player.loop(false);
      player.on("loopchange", () => {
        player.loop(false);
      });
    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
    return () => {
      clearInterval(interval);
      clearInterval(playProgressInterval);
      player?.dispose();
    };
  }, [videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = videoRef.current;

    return () => {
      if (player) {
        let c = player?.dispose?.();
        videoRef.current = null;
      }
    };
  }, [videoRef]);

  useEffect(() => {
    handleIsPlay(status);
  }, [status]);

  useEffect(() => {
    const player = playerRef.current;
    if (!player) return;
    if (certificate || postEnroll || preEnroll || notes || newVideoPage)
      player.pause();
    else player.play();
  }, [certificate, postEnroll, preEnroll, notes]);

  useEffect(() => {
    const player = playerRef.current;
    if (player) {
      player.on("ratechange", function () {
        currentPlaybackRate = player?.playbackRate();
        handlePlayrate(currentPlaybackRate);
      });
      const qualityLevels = player?.qualityLevels();
      qualityLevels.on("change", function () {
        currentQuality = qualityLevels[qualityLevels.selectedIndex].height;
        handleQuality?.(currentQuality);
      });
    }
  }, []);

  useEffect(() => {
    player = playerRef.current;
  }, [playerRef.current]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
      <div className="seekButtons">
        <div>
          <Icon
            icon={"material-symbols:forward-10"}
            className="backward-seek"
            onClick={setCurrentTime(-hoverControls.seekTime)}
          />
          {status === "playing" ? (
            <Icon
              icon={"mdi:pause"}
              className="pause-icon"
              onClick={changeStatus}
            />
          ) : (
            <Icon
              icon={"mdi:play"}
              className="play-icon"
              onClick={changeStatus}
            />
          )}
          <Icon
            icon={"material-symbols:forward-10"}
            className="forward-seek"
            onClick={setCurrentTime(hoverControls.seekTime)}
          />
        </div>
      </div>
      {Object.keys(user).length === 0 && (
        <VideoTimer
          timePassed={viewedTime}
          totalTime={900}
          className={"non-auth-timer-overlay"}
        />
      )}
    </div>
  );
};

export default function Player({
  responsive = false,
  fluid = false,
  controls = true,
  // aspectRatio = "16:6",
  videoList,
  hoverControls = { enabled: true, seekTime: 10 },
  hotKeys = { seekStep: 10, enableModifiersForNumbers: false },
  // watermark = { default: false },
  checkStatus,
  checkQuality,
  checkPlayrate,
  className,
  setRestrictVideo,
}) {
  const videoOptions = {
    autoplay: false,
    responsive,
    fluid,
    controls,
    loop: false,
    inactivityTimeout: 1000,
    playbackRates: [0.5, 0.75, 1, 1.5, 1.75, 2],
    html5: {
      hls: {
        withCredentials: true,
        smoothQualityChange: true,
      },
      overrideNative: true,
      nativeAudioTracks: false,
      nativeVideoTracks: false,
    },
    controlBar: {
      audioTrackButton: false,
    },
    plugins: {
      hotkeys: {
        alwaysCaptureHotkeys: false,
        captureDocumentHotkeys: true,
        enableModifiersForNumbers: false,
        seekStep: hotKeys.seekStep || 10,
      },
      // fullscreen: {
      //   enterOnRotate: true,
      //   exitOnRotate: true,
      //   alwaysInLandscapeMode: true,
      //   iOS: true,
      // },
      // watermark: watermark.default
      //   ? {
      //       image: watermark.image,
      //       fadeTime: watermark.fadeTime || 3,
      //       position: watermark.position || "top-right",
      //       url: watermark.url || "",
      //     }
      //   : {},
    },
    poster: videoList[0].poster,
    sources: videoList[0].sources,
  };

  return (
    <VideoJS
      className={className}
      options={videoOptions}
      handleIsPlay={checkStatus}
      handleQuality={checkQuality}
      handlePlayrate={checkPlayrate}
      hoverControls={hoverControls}
      setRestrictVideo={setRestrictVideo}
    />
  );
}

export const PlayerAttributes = () => {
  let state = {};
  state.getCurrentPlayer = () => {
    return player;
  };
  state.getCurrentQuality = () => {
    return currentQuality;
  };
  state.getCurrentPlayRate = () => {
    return currentPlaybackRate;
  };

  return state;
};
