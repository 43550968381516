import AvatarLight from "./AvatarLight.svg";
import ForgetPassword from "./ForgetPassword.svg";
import ActiveCourses from "./ActiveCourses.svg";
import CompletedCourses from "./CompletedCourses.svg";
import TotalCourses from "./TotalCourses.svg";
import TotalBundles from "./TotalBundles.svg";
import WatchPoints from "./WatchPoints.svg";
import Notification from "./Notification.svg";
import MyProfile from "./MyProfile.svg";
import Assignment from "./Assignment.svg";
import Streaks from "./Streaks.svg";
import Void from "./Void.svg";
import WarningBack from "./WarningBack.svg";
import Success from "./Success.svg";
import Failure from "./Failure.svg";

import SuccessGif from "./success.gif";

import Celebrate from "./celebrate.json";
import MainLoad from "./mainload.json";
import Load from "./load.json";

import Certificate from "./certificate.svg";

const SVGS = {
  AvatarLight,
  ForgetPassword,
  ActiveCourses,
  CompletedCourses,
  TotalCourses,
  TotalBundles,
  WatchPoints,
  Notification,
  MyProfile,
  Assignment,
  Streaks,
  Void,

  //Alerts
  WarningBack,
  Success,
  Failure,
  SuccessGif,
  //JSON
  Celebrate,
  MainLoad,
  Load,

  Certificate,
};

export default SVGS;
