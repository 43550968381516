"use client";

import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";

import styles from "./dialogue.module.scss";

function Dialogue(props) {
  const {
    content,
    closeDialogue,
    showSkip = false,
    ctaText = "Start building",
  } = props;
  const [contentSelect, setContentSelect] = useState(0);
  const [dialogContent, setDialogContent] = useState({});
  const [progress, setProgress] = useState("0%");

  useEffect(() => {
    setDialogContent(content[contentSelect]);
    const total = content?.length - 1;
    const _prog = (contentSelect / total) * 100;
    setProgress(_prog + "%");
  }, [contentSelect]);

  const handleNextClick = (e) => {
    e.preventDefault();
    if (contentSelect + 1 <= content?.length) {
      setContentSelect((prev) => prev + 1);
    }
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    if (contentSelect - 1 < 0) return;
    setContentSelect((prev) => prev - 1);
  };

  return (
    <section className={styles["box"]}>
      {/* image section */}
      <section className={styles["image-container"]}>
        <img
          src={dialogContent?.image}
          alt="image"
          className={styles["dialog-image"]}
        />
      </section>
      {/* progress section */}
      <div className={styles["progress-container"]}>
        <div
          className={styles["progressbar"]}
          style={{ width: progress }}
        ></div>
      </div>
      {/* content section */}
      <div className={styles["content"]}>
        <div className={styles["header"]}>
          <h1>{dialogContent?.heading}</h1>
        </div>
        <div className={styles["points"]}>
          {dialogContent?.points?.map((item, i) => (
            <p key={i}>{item}</p>
          ))}
        </div>
      </div>
      <div className={styles["footer"]}>
        <div className={styles["left"]}>
          {contentSelect === 0 ? (
            <>
              {showSkip ? (
                <button className={styles["back-btn"]} onClick={onbtnClick}>
                  Skip
                </button>
              ) : null}
            </>
          ) : (
            <button className={styles["back-btn"]} onClick={handleBackClick}>
              <Icon
                icon="mingcute:left-fill"
                className={styles["back-btn-icon"]}
              />
              Previous
            </button>
          )}
        </div>

        <div className={styles["right"]}>
          {contentSelect < content?.length - 1 ? (
            <button className={styles["next"]} onClick={handleNextClick}>
              Next
            </button>
          ) : (
            <button className={styles["next"]} onClick={closeDialogue}>
              {ctaText}
            </button>
          )}
        </div>
      </div>
    </section>
  );
}

export default Dialogue;
