"use client";

import { useSelector } from "react-redux";
import Image from "next/image";
import React from "react";

import styles from "./break-point.module.scss";

function BreakPoint(props) {
  const user = useSelector((state) => state?.user?.users);

  const {
    src,
    heading,
    className,
    subHeading,
    navigationLink,
    backgroundColor,
  } = props;

  const adjustedNavigationLink =
    heading === "Don't just learn, Get Certified - for Free 🙌🏻" &&
    user?.username
      ? `/profile/${user?.username}`
      : navigationLink;

  return (
    <>
      <a href={adjustedNavigationLink}>
        <article
          className={`${styles["break-point"]} ${className}`}
          style={{ backgroundColor: backgroundColor }}
        >
          <div className={styles.content}>
            <h3>{heading}</h3>
            <p>{subHeading}</p>
          </div>
          <figure>
            <Image src={src} alt={"cs-feature"} width={400} height={400} />
          </figure>
        </article>
      </a>
    </>
  );
}

export default BreakPoint;
