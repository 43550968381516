"use client";

import React, { useEffect, useLayoutEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "next/navigation";

import styles from "./content.module.scss";
import services from "@/store/services";
import Components from "@/components";
import sk from "@/skeletons";
import apis from "@/api";

const pageLimit = 20;

const SuggestedVideoList = (props) => {
  const { courseInfo } = useSelector((state) => state.video);

  const { stage, currStage, nextVideoCb, stageId, currStageId } = props;
  const [stageVideos, setStageVideos] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const params = useParams();

  const { video } = params;

  useLayoutEffect(() => {
    const fetchStageVideos = async () => {
      setLoading(true);
      const res = await apis.getStageVideos({
        cid: courseInfo?._id,
        stageNum: currStage,
      });

      if (res.status === 200) {
        if (props.type === "Course") {
          const filteredData = res.data?.result?.data
            ?.filter((obj) => !["article", "mcq"].includes(obj.type))
            .map(({ resources, ...rest }) => rest);

          setStageVideos((prev) => ({
            ...res.data.result,
            data: [...(prev?.data || []), ...(filteredData || [])],
          }));
        } else {
          setStageVideos(res.data.result);
        }
      }
      setLoading(false);
    };
    if (stage === currStage) fetchStageVideos();

    return () => {};
  }, [courseInfo?._id, currStage]);

  useEffect(() => {
    if (currStage && courseInfo?._id) {
      dispatch(
        services.updateTestInfo({
          testInfo: {
            stageId: currStage,
            currStageId: currStageId,
            courseId: courseInfo._id,
          },
        })
      );
    }
  }, [currStage, courseInfo?._id]);

  const handleLoadMore = async () => {
    if (stageVideos) {
      setLoading(true);
      const res = await apis.getStageVideos({
        cid: courseInfo?._id,
        stageNum: currStage,
        page: page + 1,
        limit: pageLimit,
      });
      if (res.status === 200) {
        if (props.type === "Course") {
          const filteredData = res.data?.result?.data
            ?.filter((obj) => !["article", "mcq"].includes(obj.type))
            .map(({ resources, ...rest }) => rest);

          setStageVideos((prev) => ({
            ...res.data.result,
            data: [...(prev?.data || []), ...(filteredData || [])],
          }));
        } else {
          setStageVideos((prev) => ({
            ...res.data.result,
            data: [...prev?.data, ...res.data?.result?.data],
          }));
        }
        setPage((prev) => prev + 1);
      }
      setLoading(false);
    }
  };

  return (
    <InfiniteScroll
      dataLength={stageVideos && stageVideos?.data?.length} //This is important field to render the next data
      next={handleLoadMore}
      hasMore={
        stageVideos?.data?.length &&
        stageVideos?.totalCount > stageVideos?.data?.length
      }
      scrollThreshold={200}
      loader={<sk.RowCardSkeleton number={5} type="sk-side-video-card" />}
      className={styles["infinite-scroll-lists"]}
      id="next-videos"
    >
      {!loading && stageVideos?.totalCount ? (
        stageVideos?.data?.map((_video, index) => (
          <Components.SuggestionCard
            key={index}
            video={_video}
            type={"course"}
            nextVideoCb={nextVideoCb}
            selected={_video?.seoTitle === video}
            stageId={stageId}
          />
        ))
      ) : loading ? (
        <sk.RowCardSkeleton number={5} type="sk-side-video-card" />
      ) : (
        <></>
      )}
    </InfiniteScroll>
  );
};

function Courses(props) {
  const { courseInfo, videoInfo, articleInfo } = useSelector(
    (state) => state.video
  );
  const [selectedStage, setSelectedStage] = useState(null);
  const [isLoading, setIsLoading] = useState(1);
  const [stages, setStages] = useState(null);

  useEffect(() => {
    const fetchStages = async () => {
      setIsLoading(true);
      if (courseInfo?._id) {
        const res = await apis.getCourseStages({
          cid: courseInfo?._id,
        });
        if (res.status === 200) {
          setStages(res.data.result);
        }
      }
      setIsLoading(false);
    };

    fetchStages();
  }, [courseInfo?._id]);

  useEffect(() => {
    if (props.type === "Course") {
      setSelectedStage(1);
    }
    if (videoInfo?._id) {
      setSelectedStage(videoInfo.stage);
    } else if (articleInfo?._id) {
      setSelectedStage(articleInfo.stage);
    }
    // else if (articleInfo?._id) setSelectedStage(videoInfo.stage);
  }, [videoInfo?._id, articleInfo?._id]);

  return (
    <div className={styles["lists"]} style={{ overflow: "auto" }}>
      {!isLoading && selectedStage ? (
        stages?.map((stage, index) => (
          <Components.Accordion
            header={
              <Components.AccordianHeader
                stage={index + 1}
                type={stage?.status}
                currStage={selectedStage}
                className={styles.headerVideoSideBar}
                handleChange={(e) => setSelectedStage(e)}
                title={`${stage?.stageNumber} - ${stage?.title}`}
              />
            }
            key={index}
          >
            <SuggestedVideoList
              currStage={selectedStage}
              stage={stage?.stageNumber}
              nextVideoCb={props.nextVideoCb}
              stageId={stage._id}
              type={props.type}
              currStageId={
                stages?.find((s) => s.stageNumber === selectedStage)?._id
              }
            />
          </Components.Accordion>
        ))
      ) : (
        <sk.RowCardSkeleton number={10} type="sk-side-video-card" />
      )}
    </div>
  );
}

export default React.memo(Courses);
