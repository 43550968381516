"use client";

import React, { useState, useLayoutEffect } from "react";

const CardWrapping = (WrappedComponent) => {
  const CardWrapping = (props) => {
    const [vidPerView, setVidPerView] = useState(2);
    const [gap, setGap] = useState(10);

    const windowHandler = () => {
      if (window.innerWidth <= 641) {
        setGap(10);
        setVidPerView(2);
        return;
      }
      if (window.innerWidth <= 800) {
        setGap(10);
        setVidPerView(3);
        return;
      }
      if (window.innerWidth > 800) {
        setVidPerView(Math.ceil(window.innerWidth / 400));
      }
      if (window.innerWidth > 1000) {
        setGap(30);
      }
    };

    useLayoutEffect(() => {
      if (typeof window === "undefined") return;
      windowHandler(); // Initial call
      window.addEventListener("resize", windowHandler);
      return () => window.removeEventListener("resize", windowHandler);
    }, []);

    // Pass calculated props to the wrapped component
    return <WrappedComponent {...props} vidPerView={vidPerView} gap={gap} />;
  };

  // Add a display name for easier debugging
  CardWrapping.displayName = `CardWrapping(${WrappedComponent.displayName || WrappedComponent.name || "Component"})`;

  return CardWrapping;
};

export default CardWrapping;
