"use client";

import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import Image from "next/image";

import styles from "./media.module.scss";
import utils from "@/utils";

function MediaInput(props) {
  const { label, allMedia, onChange, fileLimit, setMedia } = props;
  const [filesUrl, setFilesUrl] = useState([]);

  // all functions comes here
  const handleRemoveMedia = (e) => {
    e.preventDefault();
    // get the media ID or name

    const media = e.currentTarget.id;

    // filter it out from the all media
    const filteredMedia = allMedia.filter((item) => {
      if (utils.isvalidUrl(media)) {
        if (utils.isvalidUrl(item)) {
          return item !== media;
        } else {
          return item;
        }
      } else {
        if (!utils.isvalidUrl(item)) {
          return item.name !== media;
        } else {
          return item;
        }
      }
    });

    // console.log("filtered is: ", filteredMedia);
    // set the media using the function from props
    setFilesUrl([...filteredMedia]);
    onChange(filteredMedia);
  };

  const handelOnChange = (e) => {
    e.preventDefault();
    const files = [];
    let times = Math.min(fileLimit, e.target?.files?.length);
    for (let i = 0; i < times; i++) {
      files.push(e.target.files[i]);
    }
    setFilesUrl([...filesUrl, ...files]);
    onChange([...allMedia, ...files]);
  };

  // console.log("files url is: ", filesUrl);

  useEffect(() => {
    setFilesUrl([...allMedia]);
  }, []);

  return (
    <div className={styles["profileModal-input-field-container"]}>
      {label?.length > 0 && (
        <div className={styles["input-label"]}>{label}</div>
      )}
      <div className={styles["input-field"]}>
        <input
          type="file"
          className={styles["media-input"]}
          placeholder=""
          onChange={handelOnChange}
          multiple
        />
      </div>
      {allMedia?.length !== 0 && (
        <div className={styles["media-display"]} accept="image/png, image/jpeg">
          {/* {filesUrl.map((img, i) => ( */}
          {allMedia?.map((img, i) => {
            // console.log("input is: ", img);
            const res = utils.isvalidUrl(img);
            // console.log("image url is: ", res);
            const imageUrl = res ? img : URL.createObjectURL(img);
            const uniqueId = res ? img : img?.name;

            return (
              <div className={styles["media"]} key={i}>
                <Image
                  src={imageUrl}
                  alt="media files"
                  width={400}
                  height={400}
                />
                {/* check if this feature is required */}
                <button
                  className={styles["media-icon"]}
                  onClick={(e) => handleRemoveMedia(e, res)}
                  // name={}
                  id={uniqueId}
                >
                  <Icon
                    icon={"material-symbols:cancel"}
                    // onClick={handleRemoveMedia}
                    className={styles["icon"]}
                  />
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default MediaInput;
