"use client";

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import styles from "./styles.module.scss";
import Components from "@/components";
import apis from "@/api";

export const EXPIRATION = [
  { value: "minutes", label: "Minute" },
  { value: "hours", label: "Hour" },
  { value: "days", label: "Day" },
];

function AddAssignmentModal({ close, batchId, editData }) {
  const [data, setData] = useState({
    title: "",
    expiredAt: "",
    description: "",
  });
  const [expValue, setExpValue] = useState(0);
  const [type, setType] = useState(EXPIRATION[0]);

  const handleAddAssignment = async () => {
    const res = await apis.addBatchAssignment({
      batchId,
      title: data.title,
      expiredAt: formatExpiredAt(),
      description: data.description,
    });

    if (res.data?.success === 201) {
      toast.success("Assignment added successfully");
      close();
    } else {
      toast.error(
        res.data?.error ||
          res.data?.result.error ||
          "Something went wrong, please try again!"
      );
    }
  };

  const handleUpdateAssignment = async () => {
    const res = await apis.updateBatchAssignment({
      _id: editData._id,
      title: data.title,
      expiredAt: formatExpiredAt(),
      description: data.description,
    });

    if (res.data?.success === 200) {
      toast.success("Assignment updated successfully");
      close();
    } else {
      toast.error(
        res.data.error ||
          res.data.result.error ||
          "Something went wrong, please try again!"
      );
    }
  };

  const isEmpty = () => {
    if (!data?.title?.length || !data?.description?.length || expValue === 0)
      return true;
    return false;
  };

  const formatExpiredAt = () => {
    let expiredAt = new Date().getTime();
    if (type.value === "minutes") expiredAt += expValue * 60 * 1000;
    else if (type.value === "hours") expiredAt += expValue * 3600 * 1000;
    else if (type.value === "days") expiredAt += expValue * 24 * 3600 * 1000;

    return new Date(expiredAt).toISOString();
  };

  const reverseExpiredAt = (val) => {
    let current = new Date().getTime();
    let expiredAt = new Date(val).getTime();
    if (current > expiredAt) return 0;
    else {
      let minute = (expiredAt - current) / 60000;
      return Math.floor(minute);
    }
  };

  useEffect(() => {
    if (editData !== null) {
      setData((p) => ({
        ...p,
        title: editData?.title,
        description: editData?.description,
        expiredAt: editData?.expiredAt,
      }));
      setExpValue(reverseExpiredAt(editData?.expiredAt));
    }
  }, [editData]);

  return (
    <section className={styles.container}>
      <Components.TextInput
        label={"Title *"}
        placeholder={"Understanding and Implementation of Array "}
        value={data.title}
        onChange={(val) => setData((p) => ({ ...p, title: val }))}
      />
      <Components.TextArea
        label={"Description *"}
        placeholder={
          "This assignment has 2 questions, 1st is based on array traversal and 2nd is based on array CRUD operation."
        }
        rows={5}
        value={data.description}
        onChange={(e) =>
          setData((p) => ({ ...p, description: e.target.value }))
        }
      />
      <div className={styles.expiration}>
        <Components.TextInput
          label={"Expiration *"}
          placeholder={1}
          type="number"
          value={expValue}
          onChange={(val) => setExpValue(val)}
        />
        <Components.DropDownInput
          options={EXPIRATION}
          onChange={(val) => setType(val)}
          value={type}
        />
      </div>
      <div className={styles.footer}>
        {editData !== null ? (
          <Components.Button
            name="Update"
            disable={isEmpty()}
            className={styles.btn}
            handleClick={handleUpdateAssignment}
          />
        ) : (
          <Components.Button
            name="Add"
            disable={isEmpty()}
            className={styles.btn}
            handleClick={handleAddAssignment}
          />
        )}
      </div>
    </section>
  );
}

export default AddAssignmentModal;
