export default [
  {
    id: "msp-restoration-form",
    text: [
      `
      <div className="ap-container">
      <div>
        <svg
          width="30"
          height="30"
          viewBox="0 0 87 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0H86.28V86.28H0V0Z"
            fill="white"
            fillOpacity="0.01"
          />
          <path
            d="M43.14 79.09C53.0672 79.09 62.0547 75.0661 68.5604 68.5604C75.0661 62.0547 79.09 53.0672 79.09 43.14C79.09 33.2128 75.0661 24.2253 68.5604 17.7195C62.0547 11.2138 53.0672 7.19 43.14 7.19C33.2128 7.19 24.2253 11.2138 17.7195 17.7195C11.2138 24.2253 7.19 33.2128 7.19 43.14C7.19 53.0672 11.2138 62.0547 17.7195 68.5604C24.2253 75.0661 33.2128 79.09 43.14 79.09Z"
            fill="#F3912E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.14 66.5075C45.6218 66.5075 47.6338 64.4955 47.6338 62.0137C47.6338 59.5319 45.6218 57.52 43.14 57.52C40.6582 57.52 38.6463 59.5319 38.6463 62.0137C38.6463 64.4955 40.6582 66.5075 43.14 66.5075Z"
            fill="white"
          />
          <path
            d="M43.14 21.57V50.33"
            stroke="white"
            strokeWidth="7.19"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="ap-text">
        <h2>Course Progress Restoration Form</h2>
        <div>
          Hi Learners,
          <br />
          <br />
          1. If you had previously completed your course partially with
          green ticks on the videos and now you have lost those progress
          for one or multiple courses.
          <br />
          <span>or</span>
          <br />
          2. If you had previously completed the course fully with green
          ticks on all the videos and now you have lost the entire
          progress of one or multiple course.
          <br />
          <br />
          Fill out the form below and share course / playlist details.
          We'll verify and restore your progress.
          <br />
          <br />
          Progress restoration form:{" "}
          <a href="https://forms.gle/frMF6VUN2LNuPm4j9" target="_blank">
            https://forms.gle/frMF6VUN2LNuPm4j9
          </a>
          <br />
          <br />
          Apologies for any inconvenience cause.
          <br />
          <br />
          Best,
          <br />
          Team CipherSchools
        </div>
      </div>
    </div>
      `,
    ],
    attachTo: { element: "", on: "center" },
    classes: "shepherd",
    buttons: [
      {
        type: "cancel",
        classes: "shepherd-button-secondary",
        text: "Skip",
      },
      {
        text: "Next",
        classes: "shepherd-button-next",
        type: "next",
      },
    ],
    when: {
      show: () => {
        localStorage.setItem(
          "newItem-tour-guide",
          JSON.stringify({ show: "YES", step: 1 })
        );
      },
    },
  },
  {
    id: "msp-restoration-form",
    text: [
      `
      <h4 class="shephered-custom-title" >This section as the name suggests will help you to fill the Course Progress Restoration Form
      </h4>
      <h4 class="shephered-custom-foot">Click here to fill.</h4>
      `,
    ],
    attachTo: { element: "#msp-restoration-form", on: "right" },
    classes: "shepherd",
    buttons: [
      {
        type: "cancel",
        classes: "shepherd-button-secondary",
        text: "Skip",
      },
      {
        type: "next",
        classes: "shepherd-button-next",
        text: "Done",
      },
    ],
    when: {
      show: () => {
        localStorage.setItem(
          "newItem-tour-guide",
          JSON.stringify({ show: "YES", step: 2 })
        );
      },
      destroy: () => {
        let data = JSON.parse(localStorage.getItem("newItem-tour-guide"));
        if (data.show === "NO") {
          window.scrollTo(0, 0);
          window.location.assign("/courses");
        } else {
          localStorage.setItem(
            "newItem-tour-guide",
            JSON.stringify({ show: "NO", step: 0 })
          );
          window.location.assign("/courses");
        }
      },
    },
  },
];
