import { Icon } from "@iconify/react";
import React from "react";

import styles from "./filter.module.scss";

function Filter(props) {
  const { filter, list, handleChange, className } = props;

  return (
    <article className={`${styles["menu"]} ${className}`}>
      <div className={styles["filter"]}>
        <p>{filter.value}</p>
        <Icon
          icon={"iconamoon:arrow-down-2"}
          className={styles["down-arrow"]}
        />
      </div>
      <div className={styles["options"]}>
        <ul>
          {list?.map((categoryItem, index) => {
            return (
              <li key={index} onClick={() => handleChange(categoryItem)}>
                {categoryItem.value}
              </li>
            );
          })}
        </ul>
      </div>
    </article>
  );
}

export default Filter;
