"use client";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import React, { useState, useEffect, useCallback } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Virtuoso } from "react-virtuoso";
import { useSelector } from "react-redux";

import sk from "@/skeletons";
import "./document.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

const MyPdfViewer = ({ file }) => {
  const theme = useSelector((state) => state.theme);
  const [pdfError, setPdfError] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1);

  const calculateScale = useCallback(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1200) {
      setScale(3); // Larger scale for bigger screens
    } else if (screenWidth >= 768) {
      setScale(2); // Medium scale for tablets
    } else {
      setScale(0.8); // Smaller scale for mobile devices
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefiend") {
      calculateScale();
      window.addEventListener("resize", calculateScale);

      return () => {
        window.removeEventListener("resize", calculateScale);
      };
    }
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPdfError(false);
  };

  return (
    <>
      {!pdfError ? (
        <Document
          file={file}
          options={options}
          className={"react-pdf-doc"}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<sk.SkeletonElement className={"sk-resume-page"} />}
          // options={{ workerSrc: pdfjs.GlobalWorkerOptions.workerSrc }}
        >
          <Virtuoso
            style={{
              height: "calc(100vh - 65px)",
              width: "100%",
            }}
            totalCount={numPages}
            itemContent={(index) => (
              <div
                key={`page_${index + 1}`}
                className={
                  theme ? "pdf-page-container dark" : "pdf-page-container"
                }
              >
                <Page
                  scale={scale}
                  className="pdf-page"
                  pageNumber={index + 1}
                />
                {theme ? (
                  <div className="dark-mode-overlay"></div>
                ) : (
                  <div className="light-mode-overlay"></div>
                )}
              </div>
            )}
          />
        </Document>
      ) : (
        // <Document
        //   file={errorPdf}
        //   options={options}
        //   // workerSrc={pdfjs.GlobalWorkerOptions.workerSrc}
        // >
        //   <div className={"pdf-preview-pages"}>
        //     <Page key={`error_page`} pageNumber={1} scale={1} />
        //   </div>
        // </Document>
        <></>
      )}
    </>
  );
};

export default MyPdfViewer;
