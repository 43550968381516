"use client";

import React from "react";

import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";

import sk from "@/skeletons";
import utils from "@/utils";
import "./heat-map.scss";
import api from "@/api";

CalendarHeatmap.prototype.getHeight = function () {
  return (
    this.getWeekWidth() + (this.getMonthLabelSize() - this.props.gutterSize)
  );
};

const HEATMAP_DAYS = 367;

const dateOptions = {
  year: "numeric",
  month: "short",
  weekday: "long",
  day: "numeric",
};

const tooltip = (date, count) => {
  const dateString = date.toLocaleDateString("en-US", dateOptions);
  return `${count} Cipher Points on ${dateString}`;
};

let startDate = new Date(1662144889464);

let demoResult = [
  //C
  {
    dayDiff: 348 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 347 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 346 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 345 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 344 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 341 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 337 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 334 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 330 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 327 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 323 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 313 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 309 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 306 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 305 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 304 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 303 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 302 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 299 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 295 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 285 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 284 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 283 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 282 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 281 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 278 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 276 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 271 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 270 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 269 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 257 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 256 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 255 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 254 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 253 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 248 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 241 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 236 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 235 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 234 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 233 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 232 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 222 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 221 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 220 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 219 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 218 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 215 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 213 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 211 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 208 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 206 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 204 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 194 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 193 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 192 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 191 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 190 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 187 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 185 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 180 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 178 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 177 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 173 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 172 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 171 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 169 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },

  //M
  {
    dayDiff: 152 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 151 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 150 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 149 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 148 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 144 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 136 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 130 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 124 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 123 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 122 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 121 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 120 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  //A
  {
    dayDiff: 110 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 109 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 108 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 107 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 106 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 103 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 101 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 96 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 94 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 89 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 88 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 87 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 86 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 85 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  //P
  {
    dayDiff: 75 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 74 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 73 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 72 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 71 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 68 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 66 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 61 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 60 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
  {
    dayDiff: 59 - 21 + (utils.difftimestampindays(startDate) % 7),
    cipherPoint: 2500,
  },
];

const getDummyDates = (numberDates, userRecords = [], isDemo) => {
  numberDates = numberDates || 367;
  let result = [];

  const getCipherPoint = (i) => {
    let records = isDemo ? demoResult : userRecords;
    let result = records.find(function (el) {
      return el.dayDiff === i;
    });
    return result ? Math.floor(result.cipherPoint / 60) : 0;
  };

  for (let i = 0; i < numberDates; i++) {
    result.push({
      date: new Date(new Date().setDate(new Date().getDate() - i)),
      count: userRecords?.length > 0 ? getCipherPoint(i) : 0,
    });
  }

  return result;
};

class HeatMap extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      // Some dates to render in the heatmap
      values: [],
      // How many days should be shown
      numDays: HEATMAP_DAYS,
    };

    // this.onClick = this.onClick.bind(this);
  }

  async componentDidMount() {
    if (this.props.user) {
      let res = await api.getuserinsights(this.props.user);
      if (res.data?.result) {
        this.setState((prev) => ({
          ...prev,
          values: getDummyDates(
            HEATMAP_DAYS,
            res.data.result.points,
            this.props.isDemo
          ),
        }));
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.user !== this.props.user ||
      prevProps.isDemo !== this.props.isDemo
    ) {
      let res = await api.getuserinsights(this.props.user);
      if (res.data?.result) {
        this.setState((prev) => ({
          ...prev,
          values: getDummyDates(
            HEATMAP_DAYS,
            res.data.result.points,
            this.props.isDemo
          ),
        }));
      }
    }
  }

  render() {
    return (
      <div>
        {this.state?.values?.length > 0 ? (
          <>
            <CalendarHeatmap
              endDate={new Date()}
              startDate={
                new Date(
                  new Date().setDate(new Date().getDate() - this.state.numDays)
                )
              }
              values={this.state.values}
              showWeekdayLabels
              tooltipDataAttrs={(value) => {
                return {
                  "data-tip": tooltip(value.date, value.count),
                };
              }}
              classForValue={(value) => {
                if (value.count === 0)
                  return this.props.dark ? "color-cs-dark" : "color-cs-dark";
                else if (value.count < 20) return "color-cs-1";
                else if (value.count < 35) return "color-cs-2";
                else if (value.count < 60) return "color-cs-3";
                else if (value.count >= 60) return "color-cs-4";
              }}
            />
            {/* <ReactTooltip /> */}
          </>
        ) : (
          <sk.SkeletonElement type="sk-heat-map-box" />
        )}
      </div>
    );
  }
}

export default HeatMap;
