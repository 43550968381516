"use client";

import React, { useEffect, useState } from "react";
import styles from "./toggle.module.scss";

const ToggleButton = ({ initialState = false, onToggle, className = "" }) => {
  const [isToggled, setIsToggled] = useState(initialState);

  useEffect(() => {
    setIsToggled(initialState);
  }, [initialState]);

  const handleToggle = (checked) => {
    setIsToggled(checked);
    if (onToggle) {
      onToggle(checked);
    }
  };

  return (
    <label className={`${styles["switch"]} ${className}`}>
      <input
        type="checkbox"
        checked={isToggled}
        onChange={(e) => handleToggle(e.target.checked)}
      />
      <span className={styles["slider"]}></span>
    </label>
  );
};

export default ToggleButton;
