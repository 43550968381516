import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "next/navigation";

import styles from "./content.module.scss";
import Courses from "./Courses";
import Videos from "./Videos";

function Contents(props) {
  const { type, className } = props;
  const params = useParams();
  const { slug, video } = params;

  const { courseInfo } = useSelector((state) => state.video);
  const user = useSelector((state) => state.user.users);

  const nextVideoCb = useCallback(
    (id, videoSeoTitle, contentType = "video") => {
      if (contentType === "mcq") return;
      if (slug) {
        if (videoSeoTitle !== video) return `/courses/${slug}/${videoSeoTitle}`;
      } else {
        if (videoSeoTitle !== video) return `/videos/${videoSeoTitle}`;
      }
    }
  );

  return (
    <aside className={`${styles["container"]} ${styles[className]}`}>
      <header>
        <p>{slug ? "Course Syllabus" : "Recommended Videos"}</p>
        <h3>{courseInfo?.title}</h3>
      </header>
      {slug ? (
        <Courses
          isCreator={courseInfo?.creatorId === user._id}
          nextVideoCb={nextVideoCb}
          type={type}
        />
      ) : (
        <Videos nextVideoCb={nextVideoCb} />
      )}
    </aside>
  );
}

export default React.memo(Contents);
