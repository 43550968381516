import React from "react";

import Components from "@/components";
import styles from "./styles.module.scss";

function LoadingBtn(props) {
  const { loading, children, className } = props;
  return (
    <article className={className}>
      {loading ? (
        <div className={styles["loadingBtn"]}>
          <Components.ImageLoader color={"var(--white)"} width={"25px"} />
        </div>
      ) : (
        children
      )}
    </article>
  );
}

export default LoadingBtn;
