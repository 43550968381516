import React from "react";
import { Icon } from "@iconify/react";

import styles from "./lecture.module.scss";
import Components from "@/components";

function VideoUpload({ onCancel, videoDetails, setFileNameModal }) {
  return (
    <>
      {videoDetails?.mp4video === "" ? (
        <div
          className={styles["upload"]}
          onClick={() => setFileNameModal(true)}
        >
          <Icon icon={"lucide:upload"} className={styles["icon"]} />
          <p className={styles["tagline"]}>Upload Video</p>
        </div>
      ) : (
        <div className={styles["upload"]}>
          <Icon
            icon={"material-symbols:delete"}
            className={styles["cancel"]}
            onClick={onCancel}
          />
          <Components.SmallVideo
            poster={
              videoDetails?.currthumb === ""
                ? videoDetails?.thumbnails?.[0]
                : videoDetails?.currthumb
            }
            videoSrc={videoDetails?.mp4video}
            data={videoDetails}
          />
        </div>
      )}
    </>
  );
}

export default React.memo(VideoUpload);
