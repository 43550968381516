import React from "react";
import styles from "./title.module.scss";

const Title = (props) => {
  const {
    firstName,
    specialWord,
    lastName,
    className,
    subText,
    subTextClassName,
    headingTag = "h2",
  } = props;

  const getHeading = () => {
    switch (headingTag) {
      case "h1":
        return (
          <h1 className={`${styles.title} ${className}`}>
            {firstName}
            {specialWord ? <span>&nbsp;{specialWord}</span> : null} {lastName}
          </h1>
        );
      case "h2":
        return (
          <h2 className={`${styles.title} ${className}`}>
            {firstName}
            {specialWord ? <span>&nbsp;{specialWord}</span> : null} {lastName}
          </h2>
        );
      default:
        return (
          <h2 className={`${styles.title} ${className}`}>
            {firstName}
            {specialWord ? <span>&nbsp;{specialWord}</span> : null} {lastName}
          </h2>
        );
    }
  };

  return (
    <header>
      {getHeading()}
      {subText && (
        <p className={`${styles["para-heading"]} ${subTextClassName}`}>
          {/* isko hata skte subtextclassname  he */}
          {subText}
        </p>
      )}
    </header>
  );
};

export default Title;
