"use client";

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { Icon } from "@iconify/react";
import Image from "next/image";

import styles from "./test-link.module.scss";
import services from "@/store/services";
import Components from "@/components";
import assets from "@/assets/images";
import apis from "@/api";

const aboutTestPlatform = [
  {
    id: 1,
    point:
      "AI-Powered Camera Proctoring for real-time monitoring and fraud detection.",
  },
  {
    id: 2,
    point:
      "Fraud detection with face recognition, audio monitoring, and browser restrictions.",
  },
  {
    id: 3,
    point:
      "Secure environment with encrypted data, multi-layer authentication, and device lockdown.",
  },
  {
    id: 4,
    point:
      "Post-test analysis with detailed behavior reports and reviewable recordings.",
  },
  {
    id: 5,
    point:
      "Customizable test settings for time limits, randomization, and security features.",
  },
];

const SecondScreen = (props) => {
  const {
    eligibilityCrietria,
    firstRender,
    setFirstRender,
    isLoading,
    prevResult,
  } = props;

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    }
  }, []);

  return (
    <article className={styles["second-screen"]}>
      <div className={styles["second-container"]}>
        <div className={styles["contents"]}>
          <h2 className={styles.title}>Test Eligibility</h2>
          {eligibilityCrietria?.map((item, i) => (
            <div className={styles.eligibility} key={i}>
              {isLoading ? (
                <Icon
                  icon="line-md:loading-loop"
                  className={styles.loadingIcon}
                />
              ) : (
                <Icon
                  icon={item.status ? "icons8:checked" : "ic:round-close"}
                  className={`${styles.icon} ${item.status ? styles["checked"] : styles["corssed"]}`}
                />
              )}

              <p>{item.data}</p>
            </div>
          ))}
        </div>
        <div className={styles["contents"]}>
          {prevResult ? (
            <>
              <h2 className={styles.title}>Previouse attempt scores</h2>
              <div className={styles.eligibility}>
                <p>Marks obtained: {prevResult.obtainedScore}</p>
              </div>
              <div className={styles.eligibility}>
                <p>Total marks: {prevResult.totalScore}</p>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </article>
  );
};

function TestLinkModals(props) {
  const { testInfo } = useSelector((state) => state.video);
  const { content, closeHandler } = props;

  const [eligibilityCrietria, setEligibilityCrietria] = useState([
    { status: false, loading: false, data: "Checking eligibility" },
  ]);
  const [prevResult, setPrevResult] = useState(null);
  const [eligibleForTest, setEligibleForTest] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [firstRender, setFirstRender] = useState(true);
  const [screenNumber, setScreenNumber] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [progress, setProgress] = useState("0%");
  const [isLoading, setIsLoading] = useState(true);

  const descriptionRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTestEligibility = async () => {
      setIsLoading(true);

      const response = await apis.getTestEligibility({
        courseId: testInfo?.courseId,
        stageId: testInfo?.stageId,
      });
      if (response?.data?.success === 200) {
        if (!response?.data?.result?.testTaken) {
          setEligibilityCrietria([
            {
              status: response?.data?.result?.stageCompleted,
              loading: false,
              data: "All stage content completed",
            },
          ]);
          setPrevResult(null);
          setEligibleForTest(response?.data?.result?.stageCompleted);
        } else {
          setPrevResult({
            totalScore: response?.data?.result?.total,
            obtainedScore: response?.data?.result?.score,
          });
          const scorePercentage =
            response?.data?.result?.score / response?.data?.result?.total;

          if (scorePercentage >= 0.5) {
            setEligibilityCrietria([
              {
                status: true,
                loading: false,
                data: "You have completed the test",
              },
            ]);
            setEligibleForTest(false);
          } else {
            setEligibilityCrietria([
              {
                status: response?.data?.result?.stageCompleted,
                loading: false,
                data: "All stage content completed",
              },
              {
                status: response?.data?.result?.testCooledDown,
                loading: false,
                data: "Wait for 24 hours before re-attempting again",
              },
            ]);
            setEligibleForTest(
              response?.data?.result?.testCooledDown &&
                response?.data?.result?.stageCompleted
            );
          }
        }
      } else if (response?.data?.success === 404) {
        setEligibilityCrietria([
          {
            status: false,
            loading: false,
            data: "You are not enrolled into the course!",
          },
        ]);
      } else {
        setEligibilityCrietria([
          {
            status: false,
            loading: false,
            data: "Something went wrong in fetching your eligibility, please contact support!",
          },
        ]);
      }
      setIsLoading(false);
    };

    fetchTestEligibility();
  }, [testInfo]);

  const fetchEnrollToTest = async () => {
    if (eligibleForTest) {
      setIsLoading(true);
      const sheduleId = jwtDecode(testInfo?.testLink.split("sid=")[1]);
      const response = await apis.enrollStudentToTest({
        courseId: testInfo?.courseId,
        scheduleId: sheduleId.id,
      });
      if (response?.status === 200) {
        dispatch(services.toggleTestLinkModal(false));
        window.open(testInfo?.testLink, "_blank");
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (descriptionRef.current && dialogContent.description)
      descriptionRef.current.innerHTML = dialogContent.description;
  }, [dialogContent.description, isExpanded]);

  useEffect(() => {
    setDialogContent(content[0]);
    if (screenNumber === 1) {
      setProgress("50%");
    }
  }, [screenNumber]);

  const handleNextClick = () => {
    setScreenNumber(1);
  };

  const handlePreviousClick = () => {
    setProgress("0%");
    setScreenNumber(0);
  };

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <section className={styles.container}>
      <div className={styles["img-container"]}>
        <Image src={assets.Test} alt="test-image" width={500} height={500} />
      </div>
      <article className={styles.content}>
        <div className={styles.header} onClick={closeHandler}>
          <Icon icon="bitcoin-icons:cross-outline" className={styles.icon} />
        </div>
        <div className={styles["content-container"]}>
          {screenNumber === 0 ? (
            <div className={styles["sub-container"]}>
              <div className={styles.test}>
                <h2 className={styles.title}>{dialogContent?.title}</h2>
                <div className={styles.description} ref={descriptionRef}>
                  {isExpanded
                    ? dialogContent?.description
                    : `${dialogContent?.description?.slice(0, 325)}...`}
                  {dialogContent?.description > 325 ? (
                    <span onClick={toggleReadMore}>Read More</span>
                  ) : null}
                </div>
              </div>
              <div className={styles["about-platform"]}>
                <h2 className={styles.title}>About the Test Platform</h2>
                <ul>
                  {aboutTestPlatform.map((item) => (
                    <li key={item.id}>{item.point}</li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <SecondScreen
              eligibilityCrietria={eligibilityCrietria}
              firstRender={firstRender}
              setFirstRender={setFirstRender}
              isLoading={isLoading}
              prevResult={prevResult}
            />
          )}
        </div>

        <div className={styles["footer"]}>
          <div className={styles["progress-container"]}>
            <div
              className={styles["progressbar"]}
              style={{ width: progress }}
            ></div>
          </div>

          <div className={styles["btn-container"]}>
            {screenNumber === 0 ? (
              <Components.Button
                className={styles.btn}
                name={"Next"}
                handleClick={handleNextClick}
              />
            ) : (
              <>
                <Components.Button
                  className={styles["prev-btn"]}
                  name={"Previous"}
                  disable={isLoading}
                  handleClick={handlePreviousClick}
                />

                <Components.Button
                  className={styles.btn}
                  dis
                  disable={isLoading}
                  name={eligibleForTest ? "Start Test" : "Close"}
                  handleClick={() => {
                    eligibleForTest ? fetchEnrollToTest() : closeHandler();
                  }}
                  icon={
                    isLoading && (
                      <Icon
                        icon="line-md:loading-loop"
                        className={styles.loadingIcon}
                      />
                    )
                  }
                />
              </>
            )}
          </div>
        </div>
      </article>
    </section>
  );
}
export default TestLinkModals;
