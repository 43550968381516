import React from "react";

import styles from "./check-box.module.scss";

function CheckBox(props) {
  const { id, value, className, text, handler, checked } = props;
  return (
    <div className={`${styles.input} ${className}`}>
      <input
        type="checkbox"
        id={id}
        value={value}
        onChange={handler}
        checked={checked}
      />
      <label>{text}</label>
    </div>
  );
}

export default CheckBox;
