"use client";

import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useContext } from "react";

import { projectValues, setDefaultValues } from "../initialValues";
import SocketContext from "@/socket/Context";
import styles from "./project.module.scss";
import services from "@/store/services";
import Components from "@/components";
import api from "@/api";

const MAX_ABOUT_WORD_COUNT = 1200;

function AddProjectModal({ resetModalEdit, details, rewardModalToggle }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.users);
  const { socket } = useContext(SocketContext).SocketState;

  const [currentlyWorkingHere, setCurrentlyWorkingHere] = useState(false);
  const [isUpdateDialog, setIsUpdateDialog] = useState(false);
  const [project, setProject] = useState(projectValues);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    showAlert: false,
    message: "",
  });

  useEffect(
    () =>
      setDefaultValues(
        "project",
        details,
        setProject,
        setIsUpdateDialog,
        setCurrentlyWorkingHere
      ),
    []
  );

  // methods
  const handleAddSkill = (skill) => {
    setProject((prevProject) => ({
      ...prevProject,
      skills: [...prevProject.skills, skill],
    }));
  };
  const handleRemoveSkill = (skill) => {
    const newFilteredSkills = project.skills.filter((e) => e !== skill);
    setProject({ ...project, skills: newFilteredSkills });
  };

  const handleDescriptionChange = (e) => {
    e.preventDefault();
    setProject({ ...project, description: e.target.value });
  };

  const isEmpty = () => {
    if (
      project.title === "" ||
      project.description === "" ||
      project.startDate === "" ||
      project.endDate === ""
    )
      return true;
    return false;
  };

  const chechUpdateCp = (slug) => {
    const cpStateArray = user?.profileCpStatus;
    if (Array.isArray(cpStateArray)) {
      if (cpStateArray.includes(slug)) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!isEmpty()) {
      if (!currentlyWorkingHere && project.startDate > project.endDate) {
        dispatch(services.failureAlert("The start and end dates are invalid"));
        setLoading(false);
        return;
      }
      // !make API call
      let response;
      if (isUpdateDialog) {
        response = await api.updateProject({ project });
      } else {
        response = await api.createProject({ project });
        if (response.status < 400) {
          const updateCp = chechUpdateCp("projects");
          if (updateCp) {
            const cpStateRes = await api.updateProfileCpStatus("projects");
            if (cpStateRes?.status === 200) {
              dispatch(services.updateEngagement(300, socket, user._id));
              dispatch(services.fetchUser());
              rewardModalToggle(300);
            }
          }
        }
      }

      if (response.status < 400) {
        dispatch(services.successAlert("Projects updated!"));
        setLoading(false);
        resetModalEdit();
        dispatch(services.fetchUserDetails());
        dispatch(services.toggleAddProjectsModal());
        return;
      } else {
        setAlert({
          showAlert: true,
          message:
            response.data.error ?? "Can't update education, please try again",
        });
      }
    } else {
      dispatch(services.failureAlert("Some fields are empty!"));
    }
    setLoading(false);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (isUpdateDialog) {
      setLoading(true);
      const response = await api.deleteProject({
        projectCreatedAt: project.createdAt,
      });
      setLoading(false);
      if (response.status < 400) {
        dispatch(services.successAlert("Project Deleted!"));
        resetModalEdit();
        dispatch(services.fetchUserDetails());
        dispatch(services.toggleAddProjectsModal());
        return;
      } else {
        setAlert({
          showAlert: true,
          message:
            response.data.error ?? "Can't delete project, please try again",
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <section>
      {loading ? <Components.PageLoader type="page" /> : null}
      {alert.showAlert && (
        <p className={styles.errorHeading}>
          {alert.message[0].toUpperCase() + alert.message.slice(1)}
        </p>
      )}
      <form className={styles.profileModal}>
        <Components.TextInput
          label={"Project title*"}
          placeholder={"Amazon clone"}
          value={project.title}
          onChange={(e) => setProject({ ...project, title: e })}
        />

        <Components.TextArea
          label={"Project description*"}
          placeholder={"This project solves..."}
          value={project.description}
          onChange={handleDescriptionChange}
          maxCharacters={MAX_ABOUT_WORD_COUNT}
        />

        <div className={styles.durationDates}>
          <div className={styles.durationDate}>
            <Components.MyDatePicker
              label={"Start date*"}
              onChange={(date) => setProject({ ...project, startDate: date })}
              value={project.startDate}
              format="MM/yyyy"
              placeholder="MM/YYYY"
            />
          </div>
          <div className={styles.durationDate}>
            {!currentlyWorkingHere && (
              <Components.MyDatePicker
                label={"End date*"}
                onChange={(date) => setProject({ ...project, endDate: date })}
                value={project.endDate}
                format="MM/yyyy"
                placeholder="MM/YYYY"
              />
            )}
          </div>
        </div>

        <div className={styles["experience-checkbox"]}>
          <input
            type="checkbox"
            name=""
            id=""
            value={true}
            checked={currentlyWorkingHere}
            onChange={() => setCurrentlyWorkingHere(!currentlyWorkingHere)}
          />
          <p>I am currently working on this project</p>
        </div>

        <Components.SkillsInput
          label={"Skills"}
          allValues={project.skills}
          onAdd={handleAddSkill}
          onRemove={handleRemoveSkill}
        />

        <div className={styles["am-btns"]}>
          {!isUpdateDialog ? (
            <button
              className={styles["am-cancel-btn"]}
              onClick={(e) => {
                e.preventDefault();
                resetModalEdit();
                dispatch(services.toggleAddProjectsModal());
              }}
            >
              Cancel
            </button>
          ) : (
            <button className={styles["am-cancel-btn"]} onClick={handleDelete}>
              Delete
            </button>
          )}
          <button className={styles["am-save-btn"]} onClick={handleSubmit}>
            Save
          </button>
        </div>
      </form>
    </section>
  );
}

export default AddProjectModal;
