"use client";

import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";

import styles from "./warning.module.scss";
import Icons from "@/assets/icons";

function WarningAlert({ type, onbtnClick, onCancel, name, className }) {
  console.log("type", type);
  const [state, setState] = useState({
    back: Icons.WarningBack,
    icon: Icons.Failure,
    greet: "",
    subhead1: "",
    subHead2: "",
    btn: "",
    color: "",
  });

  useEffect(() => {
    switch (type) {
      case "unenroll":
        setState((prev) => ({
          ...prev,
          greet: "Unenroll",
          subhead1: `Do you want to unenroll "${name}"`,
          btn: "Unenroll now",
          color: "#D48432",
        }));
        break;
      case "unfollow":
        setState((prev) => ({
          ...prev,
          greet: "UnFollow",
          subhead1: `Do you want to unfollow "${name}"`,
          btn: "Unfollow now",
          color: "#D48432",
        }));
        break;
      case "delete":
        setState((prev) => ({
          ...prev,
          greet: "Delete",
          subhead1: `Do you want to delete "${name}"`,
          btn: "Delete now",
          color: "#D48432",
        }));
        break;
      case "incomplete-request":
        setState((prev) => ({
          ...prev,
          greet: `Incomplete Course`, // changign criteria 100 to 75
          subhead1: `Complete the remaining ${name}% of the course to get Certificate`,
          btn: "Continue",
          color: "#D48432",
        }));
        break;
      case "buffer-time":
        setState((prev) => ({
          ...prev,
          greet: name, // changign criteria 100 to 75
          subhead1: `Congratulations on your course completion. Your certificate will be ready for download in after 48 hours of enrollment.`,
          btn: "Continue",
          color: "#D48432",
        }));
        break;
      case "already-applied":
        setState((prev) => ({
          ...prev,
          icon: Icons.Success,
          greet: `Already Applied`,
          subhead1: `Please check your email box/spam or wait for some time, we will process certificate soon.`,
          btn: "Continue",
          color: "#2cda94",
        }));
        break;
      case "redeem-confirm":
        setState((prev) => ({
          ...prev,
          icon: Icons.SuccessGif,
          greet: `We have recieved your response`,
          subhead1: `${name}`,
          subHead2: "Check your email",
          btn: "Ok",
        }));
        break;
      case "missingField":
        setState((prev) => ({
          ...prev,
          greet: "Form validation error",
          subhead1: `${name}`,
          btn: "Update now",
        }));
        break;
      case "sendSuccess":
        setState((prev) => ({
          ...prev,
          greet: "Congratulations your data is submitted",
          subhead1: `${name}`,
          btn: "Ok",
        }));
        break;
      case "sendFailure":
        setState((prev) => ({
          ...prev,
          greet: "Something went wrong!",
          subhead1: `${name}`,
          btn: "Ok",
        }));
        break;
      case "notSelected":
        setState((prev) => ({
          ...prev,
          greet: "No prize is selected!!!",
          subhead1: `${name}`,
          btn: "Ok",
        }));
        break;
      case "alreadyClaimed":
        setState((prev) => ({
          ...prev,
          greet: "Already claimed this prize.",
          subhead1: `${name}`,
          btn: "Ok",
        }));
        break;
      case "resumeError":
        setState((prev) => ({
          ...prev,
          greet: "Fill personal info first.",
          subhead1: `You can also use inbuilt feature to prefill your data.`,
          btn: "Got it",
        }));
        break;
      case "fileSize":
        setState((prev) => ({
          ...prev,
          greet: "Size Limit Exceeded",
          subhead1: "Maximum Size of thumbnail image is 50kb only",
          btn: "Got it",
        }));
        break;
      case "incompleteCourse":
        setState((prev) => ({
          ...prev,
          greet: "More videos await",
          subhead1:
            "Complete all upcoming videos to qualify for the certificate.",
          btn: "Got it",
        }));
        break;
      case "certificateEdit":
        setState((prev) => ({
          ...prev,
          greet: "Attention",
          subhead1:
            "Please review your details carefully before submitting. You have only one chance to make changes, as no edits will be allowed afterward.",
          btn: "Continue",
        }));
        break;
      case "smallScreenTest":
        setState((prev) => ({
          ...prev,
          greet: "Warning",
          subhead1:
            "You dont give tets on current device please use a laptop or a device with a larger screen.",
          btn: "Got it",
        }));
      case "consentSuccess":
        setState((prev) => ({
          ...prev,
          greet: "Success",
          subhead1: name,
          btn: "Got it",
        }));
        break;
      case "sendFailure":
        setState((prev) => ({
          ...prev,
          greet: "Warning",
          subhead1: name,
          btn: "Got it",
        }));
        break;
    }
  }, []);

  return (
    <>
      <div className={styles[`wa-container`]} onClick={onCancel}>
        <div className={styles["wa-content"]}>
          <div className={styles["wa-details-box"]}>
            <div className={styles["wa-details-head"]}>{state.greet}</div>
            <div className={styles["wa-details-subhead1"]}>
              {state.subhead1}
            </div>
            <div className={styles["wa-btn-container"]}>
              <div
                className={styles[`wa-details-btn`]}
                onClick={onbtnClick?.btnClick || onCancel}
              >
                <span>{state.btn}</span>
              </div>
            </div>

            {state.subHead2 && (
              <div className={styles["wa-details-subhead2"]}>
                {state.subHead2}
              </div>
            )}
          </div>
          <Icon
            className={styles["close-icon"]}
            icon="material-symbols:close"
            width="24"
            height="24"
            onClick={onCancel}
          />
        </div>
      </div>
    </>
  );
}

export default WarningAlert;
