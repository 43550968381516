"use client";

import { useSelector } from "react-redux";
import React, { useMemo } from "react";
import { Icon } from "@iconify/react";
import Image from "next/image";

import { ClickAwayListener } from "@mui/material";

import Icons from "@/assets/icons";
import utils from "@/utils";
import "./drop-menu.scss";

function RedeamPoints({ close }) {
  const user = useSelector((state) => state.user.users);
  const points = useSelector((state) => state?.points);

  const setCPoints = useMemo(() => {
    if (user?.email) {
      const res = utils.calculateCipherPoints(points);
      let formatString = "";
      if (res < 10) formatString = "0" + res;
      else formatString = res;
      return formatString;
    } else {
      return "0";
    }
  }, [user, points]);

  return (
    <ClickAwayListener onClickAway={close}>
      <div className="rpm-cont">
        <Icon icon="bxs:up-arrow" className="rpm-arrow" />
        <div className="rpm-box">
          <div className="rpm-top">
            <Image
              src={Icons.WatchPoints}
              className="rpm-watchpoint"
              alt="watchpoint-icon"
              width={"25px"}
              height={"25px"}
            />
            <div className="rpm-point-title">
              Points : <span>{setCPoints}</span>
            </div>
          </div>
          <a className="rpm-point-text" href="/rewards#updated-policy">
            Know more about cipherpoints
          </a>
          <a className="rpm-redeam-btn rpm-disabled" href="/rewards">
            <span>Redeem Now</span>
          </a>
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default RedeamPoints;
