import React, { useEffect, useState } from "react";

import SuggestedUserCard from "./SuggestedUserCard";
import styles from "./users.module.scss";
import apis from "@/api";

function RecommendedUsers() {
  const [suggestedUser, setSuggestedUser] = useState([]);

  const getRecommendedUsers = async () => {
    const res = await apis.getRecommendedUsers();
    if (res.status === 200) {
      setSuggestedUser(res?.data?.result);
    }
  };

  useEffect(() => {
    getRecommendedUsers();
  }, []);

  return (
    <article className={styles["container"]}>
      <h2 className={styles.title}>People also viewed</h2>
      <div className={styles["cards"]}>
        {suggestedUser?.length > 0
          ? suggestedUser?.map((user, i) => (
              <React.Fragment key={i}>
                <SuggestedUserCard key={user?._id} user={user} />
                <div className={styles["divider"]}></div>
              </React.Fragment>
            ))
          : null}
      </div>
    </article>
  );
}

export default React.memo(RecommendedUsers);
