"use client";

import React, { useEffect, useState } from "react";

import Image from "next/image";
import styles from "./error-modal.module.scss";

import assets from "@/assets/images";
import Components from "@/components";
import { Icon } from "@iconify/react";

function ErrorModal(props) {
  const { closeHandler } = props;
  const [isLoading, setIsLoading] = useState(false);

  return (
    <section className={styles.container}>
      <div className={styles["img-container"]}>
        <Image
          src={assets.warningImg}
          alt="test-image"
          width={500}
          height={500}
        />
      </div>
      <article className={styles.content}>
        <div className={styles["content-container"]}>
          <div className={styles["sub-container"]}>
            <div className={styles.test}>
              <h2 className={styles.title}>Warning!</h2>
              {/* <div className={styles.description} ref={descriptionRef}> */}
              <div className={styles.description}>
                Please refrain from watching the videos at more than 2x speed :)
              </div>
            </div>
          </div>
        </div>

        <div className={styles["footer"]}>
          <div className={styles["btn-container"]}>
            <Components.Button
              className={styles.btn}
              disable={isLoading}
              name={"close"}
              handleClick={() => {
                closeHandler();
              }}
              icon={
                isLoading && (
                  <Icon
                    icon="line-md:loading-loop"
                    className={styles.loadingIcon}
                  />
                )
              }
            />
          </div>
        </div>
      </article>
    </section>
  );
}

export default ErrorModal;
