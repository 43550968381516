"use client";

import { usePathname } from "next/navigation";
import Image from "next/image";
import React from "react";

import styles from "../navbar.module.scss";
import Images from "@/assets/images";

function LeftComponent(props) {
  const pathname = usePathname();
  const { onSideCall } = props;

  return (
    <div className={styles["left-box"]}>
      {onSideCall ? (
        <div className={styles.navHambar} onClick={onSideCall}>
          <svg
            width="21"
            height="15"
            viewBox="0 0 28 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 18.3333C0 19.2538 0.746191 20 1.66667 20H12.3333C13.2538 20 14 19.2538 14 18.3333C14 17.4129 13.2538 16.6667 12.3333 16.6667H1.66667C0.746191 16.6667 0 17.4129 0 18.3333ZM0 10C0 10.9205 0.746191 11.6667 1.66667 11.6667H26.3333C27.2538 11.6667 28 10.9205 28 10C28 9.07952 27.2538 8.33333 26.3333 8.33333H1.66667C0.746193 8.33333 0 9.07952 0 10ZM0 1.66667C0 2.58714 0.746191 3.33333 1.66667 3.33333H26.3333C27.2538 3.33333 28 2.58714 28 1.66667C28 0.746192 27.2538 0 26.3333 0L1.66667 0C0.746193 0 0 0.746192 0 1.66667Z"
              fill="currentColor"
            />
          </svg>
        </div>
      ) : null}
      <a
        href={pathname === "/courses" ? null : "/courses"}
        className={styles.navBrand}
      >
        <Image src={Images.NavLogo} alt="cipherschools-logo" />
        <h2>CipherSchools</h2>
      </a>
    </div>
  );
}

export default LeftComponent;
