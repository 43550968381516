import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { usePathname } from "next/navigation";
import Link from "next/link";
import React from "react";

import { MainItems } from "@/data/ProfileSidebarItem";
import styles from "./main.module.scss";

function MainContent(props) {
  const pathname = usePathname();

  return (
    <List>
      {MainItems.map((item, i) => (
        <ListItem
          key={i}
          id={item.id}
          href={item.path}
          component={item.path ? Link : "button"}
          onClick={() => props.handleClick(item)}
          className={` ${styles["list-item"]} ${
            pathname === item.path ? styles.active : styles.inactive
          }`}
        >
          <ListItemIcon className={styles["item-icon"]}>
            <div className={item.type === "new" ? styles["ms-ripple"] : ""}>
              <span>{item.icon}</span>
            </div>
            {!props.isOpen ? (
              <div className={styles["item-text"]}>{item.subtitle}</div>
            ) : null}
          </ListItemIcon>
          {props.isOpen ? <ListItemText>{item.title}</ListItemText> : null}
        </ListItem>
      ))}
    </List>
  );
}

export default MainContent;
