import { usePathname } from "next/navigation";
import { useSelector } from "react-redux";

const useDataList = () => {
  const { email } = useSelector((state) => state?.user?.users);
  const course = useSelector((state) => state.courseDetails.courseInfo);
  const pathname = usePathname();

  const DataList = [
    {
      id: "course-structure",
      title: "Course structure",
      subtitle: "Course structure",
      path: "/upload/course",
      required: ["restricted", "unlisted", "public"],
    },
    {
      id: "landing-page",
      title: "Landing page",
      subtitle: "Landing page",
      path: "/upload/course/landing-page",
      required: ["restricted", "unlisted", "public"],
    },
    {
      id: "curriculam",
      title: "Curriculum",
      subtitle: "Curriculum",
      path: "/upload/course/curriculam",
      required: ["restricted", "unlisted", "public"],
    },
    ...(email === "support@cipherschools.com"
      ? [
          {
            id: "pricing",
            title: "Pricing",
            subtitle: "Pricing",
            path: "/upload/course/pricing",
            required: ["restricted"],
          },
        ]
      : []),
    {
      id: "creator-message",
      title: "Creator message",
      subtitle: "Creator message",
      path: "/upload/course/creator-message",
      required: ["restricted"],
    },
    ...(course?.visibility === "restricted"
      ? [
          {
            id: "promotions",
            title: "Promotions",
            subtitle: "Promotions",
            path: "/upload/course/promotions",
            required: ["restricted"],
          },
        ]
      : []),
    // {
    //   id: "accessibility",
    //   title: "Accessibility",
    //   subtitle: "Accessibility",
    //   path: "/upload/course/accessibility",
    //   required: ["restricted"],
    // },
  ];

  const BundleDataList = [
    {
      id: "bundle-structure",
      title: "Bundle structure",
      subtitle: "Bundle structure",
      path: "/upload/bundle",
      required: ["restricted", "unlisted", "public"],
    },
    {
      id: "landing-page",
      title: "Landing page",
      subtitle: "Landing page",
      path: "/upload/bundle/landing-page",
      required: ["restricted", "unlisted", "public"],
    },
    {
      id: "curriculam",
      title: "Curriculum",
      subtitle: "Curriculum",
      path: "/upload/bundle/curriculam",
      required: ["restricted", "unlisted", "public"],
    },

    {
      id: "pricing",
      title: "Pricing",
      subtitle: "Pricing",
      path: "/upload/bundle/pricing",
      required: ["restricted"],
    },

    // {
    //   id: "promotions",
    //   title: "Promotions",
    //   subtitle: "Promotions",
    //   path: "/upload/course/promotions",
    //   required: ["restricted"],
    // },
    {
      id: "creator-message",
      title: "Creator message",
      subtitle: "Creator message",
      path: "/upload/bundle/creator-message",
      required: ["restricted"],
    },
    // {
    //   id: "accessibility",
    //   title: "Accessibility",
    //   subtitle: "Accessibility",
    //   path: "/upload/course/accessibility",
    //   required: ["restricted"],
    // },
  ];

  if (pathname.startsWith("/upload/course")) {
    return DataList;
  } else if (pathname.startsWith("/upload/bundle")) {
    return BundleDataList;
  } else {
    return [];
  }
};

export default useDataList;
