"use client";

import React, { useEffect } from "react";
import Image from "next/image";

import CelebrationImage from "@/assets/svgs/profile/cp-celebration.svg";
import styles from "./coins.module.scss";

function CelebrationAlert({ onClose, pointsEarned }) {
  useEffect(() => {
    setTimeout(() => {
      onClose();
    }, 5000);
  }, []);

  return (
    <article className={styles["container"]} onClick={onClose}>
      <div className={styles["box"]}>
        <div className={styles["image-container"]}>
          <Image src={CelebrationImage} alt="celebration" />
        </div>
        <div className={styles["progressbar-container"]}>
          <div className={styles["progressbar"]}></div>
        </div>
        <div className={styles["content"]}>
          <h1>Congratulations!</h1>
          <p>
            Your have earned <b>{pointsEarned}</b> cipherpoints!
          </p>
          <div className={styles["perks"]}></div>
          <button onClick={onClose}>close</button>
        </div>
      </div>
    </article>
  );
}

export default CelebrationAlert;
