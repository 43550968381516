"use client";

import React from "react";

import RecommendedUsers from "./partials/Recommended";
import styles from "./partials/users.module.scss";
import TopLearners from "./partials/TopLearners";

function UserSidebar() {
  return (
    <aside className={styles["suggestions"]}>
      <TopLearners />
      <RecommendedUsers />
    </aside>
  );
}

export default UserSidebar;
