"use client";

import { IKContext, IKUpload } from "imagekitio-react";
import React, { useState } from "react";
import { Icon } from "@iconify/react";
import Image from "next/image";

import styles from "./file-uploader.module.scss";
import assets from "@/assets/images";
import apis from "@/api";

const FileUploader = (props) => {
  const { handleFileUpload, name } = props;

  const [fileUrl, setFileUrl] = useState("");
  const [fileId, setFileId] = useState("");
  const [fileName, setFileName] = useState("");

  const authenticator = async () => {
    try {
      const response = await apis.iKitAuth();

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Request failed with status ${response.status}: ${errorText}`
        );
      }

      const { signature, expire, token } = response.data;
      return { signature, expire, token };
    } catch (error) {
      throw new Error(`Authentication request failed: ${error.message}`);
    }
  };

  const onError = (err) => {
    // console.log("Error", err);
  };

  const onSuccess = (res) => {
    setFileUrl(res.url);
    setFileId(res.fileId);
    handleFileUpload(res.url);
  };

  const handleReplaceFile = async () => {
    setFileUrl("");
    await apis.deleteImageFromImageKit(fileId);
  };

  return (
    <div
      className={`${fileUrl ? styles.borderNone : ""} ${styles.fileUploader}`}
    >
      {fileUrl ? (
        <div className={styles.imageContainer} onClick={handleReplaceFile}>
          <Icon
            icon="heroicons-solid:pencil"
            className={styles.fileUploaderIcon}
          />
          <Image src={fileUrl} alt="user-image" />
        </div>
      ) : (
        <>
          <IKContext
            publicKey={process.env.IMAGE_KIT_PUBLIC_KEY}
            urlEndpoint={process.env.IMAGE_KIT_ENDPOINT}
            authenticator={authenticator}
            fileName={fileName}
          >
            <div className={styles.imageContainer}>
              <div>
                <Icon
                  icon="heroicons-solid:pencil"
                  className={styles.fileUploaderIcon}
                />
              </div>
              <Image
                className={styles.avatar}
                src={assets.Avatar}
                alt="avatar"
              />
            </div>

            <IKUpload
              folder="/alumni_images/"
              fileName={name}
              onError={onError}
              onSuccess={onSuccess}
              className={styles.input}
            />
          </IKContext>
        </>
      )}
    </div>
  );
};

export default FileUploader;
