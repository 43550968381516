import React from "react";

const NOTES_TOUR_DATA = [
  {
    content: (
      <>
        <p>
          Save important points, revisit key concepts, and download notes as a
          PDF anytime.
        </p>
      </>
    ),
    title: <h2>Take & Download Notes!</h2>,
    placement:
      typeof window === "undefined"
        ? "left"
        : window.innerWidth < 961
          ? window.innerWidth < 360
            ? "center"
            : "top"
          : "left",
    target:
      typeof window === "undefined"
        ? "#notes"
        : window.innerWidth < 961
          ? window.innerWidth < 360
            ? body
            : "#vd_TabMobile_Notes"
          : "#notes",
  },
];

export default NOTES_TOUR_DATA;
