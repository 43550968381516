"use client";

import { RotatingLines, ThreeDots } from "react-loader-spinner";
import { useEffect, useRef } from "react";
import lottie from "lottie-web";

import styles from "./style.module.scss";
import Icons from "@/assets/icons";

export const PageLoader = (props) => {
  const ref = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: ref.current,
      renderer: "svg",
      animationData: props.type === "page" ? Icons.MainLoad : Icons.LoadCircle,
      loop: true,
      autoplay: true,
    });

    return lottie.destroy;
  }, []);

  return (
    <div className={styles.container}>
      <div ref={ref} className={styles.content} />
    </div>
  );
};

export const CircleLoader = () => {
  return (
    <div className={styles.CircleCont}>
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="var(--primary)"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        visible={true}
      />
    </div>
  );
};

export const ImageLoader = ({ color, width }) => {
  return (
    <div className={styles.ImageCont}>
      <RotatingLines
        strokeColor={color || "var(--primary)"}
        strokeWidth="5"
        animationDuration="0.75"
        width={width || "36"}
        visible={true}
      />
    </div>
  );
};
