"use client";

import React, { useState, useEffect } from "react";
import Image from "next/image";

import resourceGif from "@/assets/images/vpage-resources.gif";
import articleGif from "@/assets/images/article-info.gif";
import testGif from "@/assets/images/test-info.gif";
import styles from "./video-tour.module.scss";
import Components from "@/components";

const tourData = [
  {
    title: "📂 Enhance your learning with resource materials!",
    details: [
      {
        pointer: "📥",
        content:
          "Access all the resources used in the lecture conveniently through the Resources dropdown.",
      },
      {
        pointer: "💡",
        content:
          "Utilize these materials to enhance your understanding and support your learning journey.",
      },
    ],
    gif: resourceGif,
  },
  {
    title: "📖 Discover the all-new article section!",
    details: [
      {
        pointer: "📚",
        content:
          "Read the article carefully to enhance your understanding of the course material.",
      },
      {
        pointer: "✅",
        content: "Complete the article to progress in your course",
      },
    ],
    gif: articleGif,
  },
  {
    title: "🧠 Challenge yourself with the test!",
    details: [
      {
        pointer: "📝",
        content:
          "Complete the test at the end of each stage to revise and reinforce the concepts you've learned.",
      },
      {
        pointer: "🔓",
        content:
          "The test will unlock once you’ve successfully completed the current stage.",
      },
    ],
    gif: testGif,
  },
];

function VideoPageTour(props) {
  const { closeHandler, skipHandler } = props;
  const [contentSelect, setContentSelect] = useState(0);
  const [progress, setProgress] = useState("0%");

  const handleNextClick = (e) => {
    e.preventDefault();
    if (contentSelect + 1 <= tourData?.length) {
      setContentSelect((prev) => prev + 1);
    }
  };

  useEffect(() => {
    tourData[[contentSelect]];
    const total = tourData?.length - 1;
    const _prog = (contentSelect / total) * 100;
    setProgress(_prog + "%");
  }, [contentSelect]);

  const handlePrevious = (e) => {
    e.preventDefault();
    if (contentSelect - 1 < 0) return;
    setContentSelect((prev) => prev - 1);
  };

  return (
    <section className={styles.container}>
      <div className={styles.img}>
        <Image src={tourData[contentSelect]?.gif} alt="tour-gif" />
      </div>
      <article className={styles["sub-container"]}>
        <div className={styles.header}>
          <h2>{tourData[contentSelect].title}</h2>
        </div>
        <div>
          <ul>
            {tourData[contentSelect]?.details.map((value, index) => (
              <li style={{ listStyle: "none" }} key={index}>
                <span style={{ paddingRight: "8px" }}>{value.pointer}</span>
                {value.content}
              </li>
            ))}
          </ul>
        </div>
      </article>

      <div className={styles["footer"]}>
        <div className={styles["progress-container"]}>
          <div
            className={styles["progressbar"]}
            style={{ width: progress }}
          ></div>
        </div>

        <div className={styles["btn-container"]}>
          <Components.Button
            className={styles["skip-btn"]}
            name={"Skip"}
            handleClick={skipHandler}
          />
          <div className={styles.right}>
            {contentSelect === 0 ? (
              <Components.Button
                className={styles.btn}
                name={"Next"}
                handleClick={handleNextClick}
              />
            ) : (
              <>
                <Components.Button
                  className={styles["prev-btn"]}
                  name={"Previous"}
                  handleClick={handlePrevious}
                />

                <Components.Button
                  className={styles.btn}
                  name={
                    contentSelect === tourData.length - 1 ? "Let’s Go!" : "Next"
                  }
                  handleClick={
                    contentSelect === tourData.length - 1
                      ? closeHandler
                      : handleNextClick
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default VideoPageTour;
