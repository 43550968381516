"use client";

import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";

import styles from "./accordion.module.scss";
import services from "@/store/services";
import Components from "@/components";
import utils from "@/utils";
import apis from "@/api";

export const AccordianHeader = (props) => {
  const {
    handleChange,
    title,
    type,
    className,
    stage,
    currStage,
    isDefaultOpen = false,
    ...others
  } = props;
  const [isOpen, setIsOpen] = useState(stage === currStage || isDefaultOpen);

  const toggleAccordion = () => {
    setIsOpen((prev) => !prev);
    handleChange?.(stage);
  };

  return (
    <article
      className={`${styles["accordion-title"]} ${className} ${isOpen ? styles["open"] : ""}`}
      onClick={toggleAccordion}
      {...others}
    >
      <Components.Tooltip title={title} placement="top">
        <h3>{title}</h3>
      </Components.Tooltip>
      <div className={styles["accordian-right"]}>
        {type ? (
          type === "locked" ? (
            <Icon icon={"mdi:lock"} className={styles["status-icon"]} />
          ) : type === "wait" ? (
            <Icon icon={"mdi:timer-sand"} className={styles["status-icon"]} />
          ) : (
            <Icon
              icon={"pajamas:live-preview"}
              className={styles["status-icon"]}
            />
          )
        ) : null}
        <Icon
          icon={"mingcute:down-fill"}
          className={isOpen ? styles["arrow"] : ""}
        />
      </div>
    </article>
  );
};

export const AccordianHeader2 = (props) => {
  const {
    _id,
    handleChange,
    handleStageEdit,
    removeCB,
    title,
    isOpen,
    visibility,
    className,
    ...others
  } = props;
  const dispatch = useDispatch();

  const handleRemoveStage = async (id) => {
    if (_id) {
      const res = await apis.deleteStage({
        cid: utils.getFromLocalStorage("curr_course"),
        sid: id,
      });
      if (res.status < 400) {
        toast.success(`Stage removed successfully!`);
        dispatch(services.fetchStage());
      } else toast.error(res.data.error || "Something went wrong!");
    }
  };

  return (
    <article
      className={`${styles["accordion-title"]} ${className} ${isOpen ? styles["open"] : ""} ${
        visibility === "hidden" ? styles["hidden"] : ""
      }`}
      onClick={handleChange}
      {...others}
    >
      <Components.Tooltip
        title={`${visibility.toUpperCase()} - ${title}`}
        placement="top"
      >
        <h3>{title}</h3>
      </Components.Tooltip>
      <div className={styles["accordian-right"]}>
        <Icon icon={"mingcute:edit-line"} onClick={handleStageEdit} />
        <Icon
          icon={"mdi:delete"}
          onClick={() => removeCB(_id, title, () => handleRemoveStage(_id))}
        />
        <Icon
          icon={"mingcute:down-fill"}
          className={isOpen ? styles["arrow"] : ""}
        />
      </div>
    </article>
  );
};

export const AccordianList = (props) => {
  const { children } = props;
  return (
    <div
      className={styles["accordion-content"]}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </div>
  );
};

export const ResumeAccordian = (props) => {
  const {
    title,
    type,
    resumeOpen,
    personalInfoCompleted,
    setShowPersonalInfoAlert,
  } = props;
  const [isOpen, setIsOpen] = useState(resumeOpen);

  const headerRef = useRef(null);

  const toggleAccordion = () => {
    if (!personalInfoCompleted) {
      setShowPersonalInfoAlert(true);
      return;
    }

    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isOpen && headerRef.current) {
      headerRef.current.scrollIntoView({
        body: "start",
        behavior: "smooth",
        inline: "nearest",
      });
    }
  }, [headerRef.current, isOpen]);

  return (
    <article
      ref={headerRef}
      className={`${styles["accordion-title"]} ${isOpen ? styles["open"] : ""}`}
      onClick={toggleAccordion}
    >
      <Components.Tooltip title={title} placement="top">
        <h3>{title}</h3>
      </Components.Tooltip>
      <div className={styles["accordian-right"]}>
        {type ? (
          type === "locked" ? (
            <Icon icon={"mdi:lock"} className={styles["status-icon"]} />
          ) : type === "wait" ? (
            <Icon icon={"mdi:timer-sand"} className={styles["status-icon"]} />
          ) : (
            <Icon
              icon={"pajamas:live-preview"}
              className={styles["status-icon"]}
            />
          )
        ) : null}
        <Icon
          icon={"mingcute:down-fill"}
          className={isOpen ? styles["arrow"] : ""}
        />
      </div>
    </article>
  );
};

const Accordion = (props) => {
  const { header, children, className } = props;
  return (
    <article className={`${styles["accordion-item"]}`}>
      <>{header}</>
      <AccordianList>{children}</AccordianList>
    </article>
  );
};

export default Accordion;
