"use client";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import services from "@/store/services";
import styles from "./styles.module.scss";

function VideoTimer(props) {
  const { timePassed = 0, className } = props;
  const freeAccessTime = Number(process.env.NEXT_PUBLIC_FREE_ACCESS) || 900;
  const [timeRemaining, setTimeRemaining] = useState("15:00");
  const [percentage, setPercentage] = useState(0);
  const [hover, setHover] = useState(false);
  const progressRef = useRef(null);
  const dispatch = useDispatch();

  const convertInMS = (totalTime, passedTime) => {
    const totalSecsRemaining = totalTime - passedTime;
    if (totalSecsRemaining <= 0) return "";
    const timeRemaining = `${Math.floor(totalSecsRemaining / 60)}:${(totalSecsRemaining % 60).toFixed(0).toString().padStart(2, "0")}`;
    return timeRemaining;
  };

  useEffect(() => {
    const timeRemaining = convertInMS(freeAccessTime, Number(timePassed));
    setTimeRemaining(timeRemaining != "" ? timeRemaining : 0);
    const _percentage =
      (Number(timePassed) / freeAccessTime) * 100 >= 0
        ? (Number(timePassed) / freeAccessTime) * 100
        : 0;

    setPercentage(_percentage);

    if (progressRef.current) {
      const progressBar = progressRef.current;
      let progressColor = "rgb(201, 116, 76)";
      const progressedDeg = parseInt(360 * (_percentage / 100));
      progressBar.style.background = `conic-gradient(${progressColor} 0deg, ${progressColor} ${progressedDeg}deg, ${"white"} ${progressedDeg}deg, ${"white"} 360deg)`;
    }
  }, [timePassed]);

  return (
    <div
      className={`${className} ${styles.container}`}
      style={hover ? { opacity: 1 } : { opacity: 0.6 }}
    >
      <div
        className={`${styles.helper} ${hover ? styles.showHelper : ""}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => dispatch(services.toggleSigninModal())}
      >
        <p>Free access ends soon. Login!</p>
      </div>
      <div
        ref={progressRef}
        className={styles["circular-progress"]}
        data-inner-circle-color="lightgrey"
        data-percentage={percentage}
        data-progress-color="crimson"
        data-bg-color="black"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className={styles["inner-circle"]}></div>
        <p className={styles["percentage"]}>{timeRemaining}</p>
      </div>
    </div>
  );
}

export default VideoTimer;
