export const MainItems = [
  {
    icon: (
      <svg
        width="18"
        height="22"
        viewBox="0 0 24 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="sidebar-icon"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.656 9.296C1.95158 9.296 1.27602 9.01617 0.777924 8.51807C0.279828 8.01998 0 7.34441 0 6.64V2.656C0 1.95158 0.279828 1.27602 0.777924 0.777924C1.27602 0.279828 1.95158 0 2.656 0H7.968C8.67241 0 9.34798 0.279828 9.84608 0.777924C10.3442 1.27602 10.624 1.95158 10.624 2.656V6.64C10.624 7.34441 10.3442 8.01998 9.84608 8.51807C9.34798 9.01617 8.67241 9.296 7.968 9.296H2.656ZM2.656 26.56C1.95158 26.56 1.27602 26.2802 0.777924 25.7821C0.279828 25.284 0 24.6084 0 23.904V13.28C0 12.5756 0.279828 11.9 0.777924 11.4019C1.27602 10.9038 1.95158 10.624 2.656 10.624H7.968C8.67241 10.624 9.34798 10.9038 9.84608 11.4019C10.3442 11.9 10.624 12.5756 10.624 13.28V23.904C10.624 24.6084 10.3442 25.284 9.84608 25.7821C9.34798 26.2802 8.67241 26.56 7.968 26.56H2.656ZM15.936 26.56C15.2316 26.56 14.556 26.2802 14.0579 25.7821C13.5598 25.284 13.28 24.6084 13.28 23.904V21.248C13.28 20.5436 13.5598 19.868 14.0579 19.3699C14.556 18.8718 15.2316 18.592 15.936 18.592H21.248C21.9524 18.592 22.628 18.8718 23.1261 19.3699C23.6242 19.868 23.904 20.5436 23.904 21.248V23.904C23.904 24.6084 23.6242 25.284 23.1261 25.7821C22.628 26.2802 21.9524 26.56 21.248 26.56H15.936ZM15.936 15.936C15.2316 15.936 14.556 15.6562 14.0579 15.1581C13.5598 14.66 13.28 13.9844 13.28 13.28V2.656C13.28 1.95158 13.5598 1.27602 14.0579 0.777924C14.556 0.279828 15.2316 0 15.936 0H21.248C21.9524 0 22.628 0.279828 23.1261 0.777924C23.6242 1.27602 23.904 1.95158 23.904 2.656V13.28C23.904 13.9844 23.6242 14.66 23.1261 15.1581C22.628 15.6562 21.9524 15.936 21.248 15.936H15.936Z"
          fill="currentColor"
        />
      </svg>
    ),
    id: "ps-dashboard",
    title: "Dashboard",
    subtitle: "Dashboard",
    path: "/profile",
  },
  {
    icon: (
      <svg
        width="24"
        height="27"
        fill="none"
        viewBox="0 0 34 34"
        className="sidebar-icon"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3333 9.91667C11.3333 8.41377 11.9304 6.97243 12.9931 5.90973C14.0558 4.84702 15.4971 4.25 17 4.25C18.5029 4.25 19.9442 4.84702 21.0069 5.90973C22.0696 6.97243 22.6667 8.41377 22.6667 9.91667C22.6667 11.4196 22.0696 12.8609 21.0069 13.9236C19.9442 14.9863 18.5029 15.5833 17 15.5833C15.4971 15.5833 14.0558 14.9863 12.9931 13.9236C11.9304 12.8609 11.3333 11.4196 11.3333 9.91667ZM11.3333 18.4167C9.45472 18.4167 7.65304 19.1629 6.32466 20.4913C4.99628 21.8197 4.25 23.6214 4.25 25.5C4.25 26.6272 4.69777 27.7082 5.4948 28.5052C6.29183 29.3022 7.37283 29.75 8.5 29.75H25.5C26.6272 29.75 27.7082 29.3022 28.5052 28.5052C29.3022 27.7082 29.75 26.6272 29.75 25.5C29.75 23.6214 29.0037 21.8197 27.6753 20.4913C26.347 19.1629 24.5453 18.4167 22.6667 18.4167H11.3333Z"
          fill="currentColor"
        />
      </svg>
    ),
    id: "ps-my-profile",
    title: "Profile",
    subtitle: "Profile",
    path: "/profile/me",
  },
  {
    icon: (
      <svg
        width="27"
        height="27"
        viewBox="0 0 39 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="sidebar-icon"
      >
        <path
          d="M7.63572 4.25H1.16448C0.57128 4.25 0.0859375 4.72812 0.0859375 5.3125V28.6875C0.0859375 29.2719 0.57128 29.75 1.16448 29.75H7.63572C8.22891 29.75 8.71426 29.2719 8.71426 28.6875V5.3125C8.71426 4.72812 8.22891 4.25 7.63572 4.25ZM6.55718 10.625H2.24302V8.5H6.55718V10.625ZM18.4211 4.25H11.9499C11.3567 4.25 10.8713 4.72812 10.8713 5.3125V28.6875C10.8713 29.2719 11.3567 29.75 11.9499 29.75H18.4211C19.0143 29.75 19.4997 29.2719 19.4997 28.6875V5.3125C19.4997 4.72812 19.0143 4.25 18.4211 4.25ZM17.3426 10.625H13.0284V8.5H17.3426V10.625Z"
          fill="currentColor"
        />
        <path
          d="M25.8719 5.89257L20.0931 8.76132C19.8386 8.88879 19.6455 9.11013 19.5558 9.37717C19.4661 9.64421 19.4871 9.93534 19.6142 10.1872L29.3211 29.1635C29.4505 29.4142 29.6752 29.6044 29.9462 29.6928C30.2173 29.7811 30.5128 29.7604 30.7685 29.6352L36.5473 26.7664C36.8018 26.639 36.9949 26.4176 37.0846 26.1506C37.1743 25.8836 37.1533 25.5924 37.0262 25.3406L27.3193 6.36432C27.1899 6.11359 26.9652 5.92335 26.6942 5.835C26.4231 5.74665 26.1276 5.76734 25.8719 5.89257Z"
          fill="currentColor"
        />
      </svg>
    ),
    id: "ps-enrolled-courses",
    title: "Enrolled Courses",
    subtitle: "Courses",
    path: "/profile/enrollments",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 256 256"
      >
        <path
          fill="currentColor"
          d="M232 86.53V56a16 16 0 0 0-16-16H40a16 16 0 0 0-16 16v128a16 16 0 0 0 16 16h120v24a8 8 0 0 0 12 7l24-13.74L220 231a8 8 0 0 0 12-7v-62.53a51.88 51.88 0 0 0 0-74.94M128 144H72a8 8 0 0 1 0-16h56a8 8 0 0 1 0 16m0-32H72a8 8 0 0 1 0-16h56a8 8 0 0 1 0 16m88 98.21l-16-9.16a8 8 0 0 0-7.94 0l-16 9.16V172a51.88 51.88 0 0 0 40 0ZM196 160a36 36 0 1 1 36-36a36 36 0 0 1-36 36"
        />
      </svg>
    ),
    id: "ps-user-certificates",
    title: "Certificates",
    subtitle: "Certificates",
    path: "/profile/certificates",
  },
  {
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="sidebar-icon"
      >
        <path
          d="M0.0830078 25.75V0.25H19.9163V25.75L9.99967 21.5L0.0830078 25.75Z"
          fill="currentColor"
        />
      </svg>
    ),
    id: "ps-wishlist",
    title: "Wishlist",
    subtitle: "Wishlist",
    path: "/profile/wishlist",
  },
  {
    icon: (
      <svg
        width="22"
        height="27"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="sidebar-icon"
      >
        <path
          d="M32.5837 14.1667C32.5837 13.4153 32.2851 12.6946 31.7538 12.1633C31.2224 11.6319 30.5018 11.3334 29.7503 11.3334H20.797L22.157 4.85925C22.1853 4.71758 22.1995 4.56175 22.1995 4.40591C22.1995 3.82508 21.9587 3.28675 21.5762 2.90425L20.0745 1.41675L10.7528 10.7384C10.2287 11.2626 9.91699 11.9709 9.91699 12.7501V26.9167C9.91699 27.6682 10.2155 28.3889 10.7469 28.9202C11.2782 29.4516 11.9989 29.7501 12.7503 29.7501H25.5003C26.6762 29.7501 27.682 29.0417 28.107 28.0218L32.3853 18.0342C32.5128 17.7084 32.5837 17.3684 32.5837 17.0001V14.1667ZM1.41699 29.7501H7.08366V12.7501H1.41699V29.7501Z"
          fill="currentColor"
        />
      </svg>
    ),
    id: "ps-liked-videos",
    title: "Liked Videos",
    subtitle: "Liked",
    path: "/profile/liked-videos",
  },
];

export const CreatorItems = [
  {
    icon: (
      <svg
        width="18"
        height="22"
        viewBox="0 0 23 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="sidebar-icon"
      >
        <path
          d="M3.84175 10.5527H6.38691V18.5564C6.38691 19.4368 7.10724 20.1571 7.98764 20.1571H14.3906C15.271 20.1571 15.9913 19.4368 15.9913 18.5564V10.5527H18.5364C19.9611 10.5527 20.6814 8.82393 19.673 7.81547L12.3256 0.468123C12.1775 0.31973 12.0016 0.202001 11.808 0.121674C11.6143 0.0413468 11.4067 0 11.1971 0C10.9875 0 10.7799 0.0413468 10.5862 0.121674C10.3926 0.202001 10.2167 0.31973 10.0686 0.468123L2.72124 7.81547C1.71278 8.82393 2.4171 10.5527 3.84175 10.5527ZM0 24.9593C0 25.8397 0.720328 26.56 1.60073 26.56H20.8095C21.6899 26.56 22.4102 25.8397 22.4102 24.9593C22.4102 24.0789 21.6899 23.3585 20.8095 23.3585H1.60073C0.720328 23.3585 0 24.0789 0 24.9593Z"
          fill="currentColor"
        />
      </svg>
    ),
    id: "ps-new-upload",
    title: "New Upload",
    subtitle: "Upload",
    path: null,
  },
  {
    icon: (
      <svg
        width="18"
        height="22"
        viewBox="0 0 24 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="sidebar-icon"
      >
        <path
          d="M21.248 0H3.984C2.38243 0 0 1.06107 0 3.984V22.576C0 25.4989 2.38243 26.56 3.984 26.56H23.904V23.904H3.99994C3.3864 23.8881 2.656 23.6464 2.656 22.576C2.656 22.4419 2.66795 22.3223 2.68787 22.2135C2.83661 21.4485 3.46342 21.2613 3.99994 21.248H23.904V2.656C23.904 1.95158 23.6242 1.27602 23.1261 0.777924C22.628 0.279828 21.9524 0 21.248 0ZM21.248 11.952L18.592 10.624L15.936 11.952V2.656H21.248V11.952Z"
          fill="currentColor"
        />
      </svg>
    ),
    id: "ps-my-videos",
    title: "My Videos",
    subtitle: "Videos",
    path: "/profile/myvideos",
  },
  {
    icon: (
      <svg
        width="18"
        height="22"
        viewBox="0 0 24 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="sidebar-icon"
      >
        <path
          d="M21.248 0H3.984C2.38243 0 0 1.06107 0 3.984V22.576C0 25.4989 2.38243 26.56 3.984 26.56H23.904V23.904H3.99994C3.3864 23.8881 2.656 23.6464 2.656 22.576C2.656 22.4419 2.66795 22.3223 2.68787 22.2135C2.83661 21.4485 3.46342 21.2613 3.99994 21.248H23.904V2.656C23.904 1.95158 23.6242 1.27602 23.1261 0.777924C22.628 0.279828 21.9524 0 21.248 0ZM21.248 11.952L18.592 10.624L15.936 11.952V2.656H21.248V11.952Z"
          fill="currentColor"
        />
      </svg>
    ),
    id: "ps-my-courses",
    title: "My Courses",
    subtitle: "Courses",
    path: "/profile/mycourses?filter=all&page=1",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 14 14"
      >
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="M12.402 8.976H7.259a2.278 2.278 0 0 0-.193-4.547h-1.68A3.095 3.095 0 0 0 4.609 0h7.793a1.35 1.35 0 0 1 1.348 1.35v6.279c0 .744-.604 1.348-1.348 1.348ZM2.898 4.431a1.848 1.848 0 1 0 0-3.695a1.848 1.848 0 0 0 0 3.695m5.195 2.276c0-.568-.46-1.028-1.027-1.028H2.899a2.65 2.65 0 0 0-2.65 2.65v1.205c0 .532.432.963.964.963h.172l.282 2.61A1 1 0 0 0 2.66 14h.502a1 1 0 0 0 .99-.862l.753-5.404h2.16c.567 0 1.027-.46 1.027-1.027Z"
          clip-rule="evenodd"
        />
      </svg>
    ),
    id: "ps-my-cohorts",
    title: "My Cohorts",
    subtitle: "cohorts",
    path: "/profile/cohorts",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g fill="none">
          <path d="m12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036q-.016-.004-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z" />
          <path
            fill="currentColor"
            d="M3 7a4 4 0 1 1 8 0a4 4 0 0 1-8 0m0 10a4 4 0 1 1 8 0a4 4 0 0 1-8 0M17 3a4 4 0 1 0 0 8a4 4 0 0 0 0-8m-4 14a1 1 0 0 1 1-1h2v-2a1 1 0 1 1 2 0v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 0 1-1-1"
          />
        </g>
      </svg>
    ),
    id: "ps-my-bundles",
    title: "My Bundles",
    subtitle: "Bundles",
    path: "/profile/mybundles",
  },
  // {
  //   icon: (
  //     <svg
  //       width="18"
  //       height="22"
  //       viewBox="0 0 24 27"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //       className="sidebar-icon"
  //     >
  //       <path
  //         d="M21.248 2.65588H15.697C15.1392 1.1154 13.6784 -0.00012207 11.952 -0.00012207C10.2256 -0.00012207 8.7648 1.1154 8.20704 2.65588H2.656C1.1952 2.65588 0 3.85108 0 5.31188V23.9039C0 25.3647 1.1952 26.5599 2.656 26.5599H21.248C22.7088 26.5599 23.904 25.3647 23.904 23.9039V5.31188C23.904 3.85108 22.7088 2.65588 21.248 2.65588ZM11.952 2.65588C12.6824 2.65588 13.28 3.25348 13.28 3.98388C13.28 4.71428 12.6824 5.31188 11.952 5.31188C11.2216 5.31188 10.624 4.71428 10.624 3.98388C10.624 3.25348 11.2216 2.65588 11.952 2.65588ZM13.28 21.2479H6.64C5.9096 21.2479 5.312 20.6503 5.312 19.9199C5.312 19.1895 5.9096 18.5919 6.64 18.5919H13.28C14.0104 18.5919 14.608 19.1895 14.608 19.9199C14.608 20.6503 14.0104 21.2479 13.28 21.2479ZM17.264 15.9359H6.64C5.9096 15.9359 5.312 15.3383 5.312 14.6079C5.312 13.8775 5.9096 13.2799 6.64 13.2799H17.264C17.9944 13.2799 18.592 13.8775 18.592 14.6079C18.592 15.3383 17.9944 15.9359 17.264 15.9359ZM17.264 10.6239H6.64C5.9096 10.6239 5.312 10.0263 5.312 9.29588C5.312 8.56548 5.9096 7.96788 6.64 7.96788H17.264C17.9944 7.96788 18.592 8.56548 18.592 9.29588C18.592 10.0263 17.9944 10.6239 17.264 10.6239Z"
  //         fill="currentColor"
  //       />
  //     </svg>
  //   ),
  //   id: "ps-assignment",
  //   title: "Assignments",
  //   subtitle: "Assignments",
  //   path: "/upload/assignment",
  // },
  // {
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="27"
  //       viewBox="0 0 24 24"
  //       className="sidebar-icon"
  //     >
  //       <path
  //         fill={isSelected === "Quizzes" ? "white" : "currentColor"}
  //         d="M14 15q.425 0 .738-.313t.312-.737q0-.425-.313-.737T14 12.9q-.425 0-.738.313t-.312.737q0 .425.313.738T14 15Zm0-3.2q.275 0 .513-.2t.287-.525q.05-.3.212-.55t.588-.675q.75-.75 1-1.213t.25-1.087q0-1.125-.788-1.838T14 5q-.825 0-1.5.375T11.425 6.45q-.15.25-.025.525t.425.4q.275.125.538.025t.437-.35q.225-.325.525-.487T14 6.4q.6 0 .975.338t.375.912q0 .35-.2.663t-.7.787q-.725.625-.925.963t-.25.987q-.025.3.188.525T14 11.8ZM8 18q-.825 0-1.413-.588T6 16V4q0-.825.588-1.413T8 2h12q.825 0 1.413.588T22 4v12q0 .825-.588 1.413T20 18H8Zm-4 4q-.825 0-1.413-.588T2 20V7q0-.425.288-.713T3 6q.425 0 .713.288T4 7v13h13q.425 0 .713.288T18 21q0 .425-.288.713T17 22H4Z"
  //       />
  //     </svg>
  //   ),
  //   id: "ps-quiz",
  //   title: "Quizzes",
  //   subtitle: "Quizzes",
  //   path: "/profilepage/quizzes",
  // },
];

export const CourseUpload = [
  {
    icon: (
      <svg
        width="23"
        height="16"
        viewBox="0 0 27 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="sidebar-icon"
      >
        <path
          d="M21.248 2.656C21.248 1.95159 20.9682 1.27602 20.4701 0.777924C19.972 0.279828 19.2964 0 18.592 0H2.656C1.95158 0 1.27602 0.279828 0.777924 0.777924C0.279828 1.27602 0 1.95159 0 2.656V15.936C0 16.6404 0.279828 17.316 0.777924 17.8141C1.27602 18.3122 1.95158 18.592 2.656 18.592H18.592C19.2964 18.592 19.972 18.3122 20.4701 17.8141C20.9682 17.316 21.248 16.6404 21.248 15.936V11.5098L26.56 15.936V2.656L21.248 7.08222V2.656ZM15.936 10.624H11.952V14.608H9.296V10.624H5.312V7.968H9.296V3.984H11.952V7.968H15.936V10.624Z"
          fill="currentColor"
        />
      </svg>
    ),
    id: "ps-upload-video",
    title: "Upload Video",
    subtitle: "Video",
    path: "/upload/video",
  },
  {
    icon: (
      <svg
        width="23"
        height="15"
        viewBox="0 0 27 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="sidebar-icon"
      >
        <path
          d="M5.64 3.32C6.19228 3.32 6.64 2.87228 6.64 2.32V1C6.64 0.447715 6.19228 0 5.64 0H1C0.447715 0 0 0.447715 0 1V2.32C0 2.87228 0.447715 3.32 1 3.32H5.64ZM9.96 0H24.9C25.813 0 26.56 0.747 26.56 1.66V14.94C26.56 15.853 25.813 16.6 24.9 16.6H9.96C9.047 16.6 8.3 15.853 8.3 14.94V1.66C8.3 0.747 9.047 0 9.96 0ZM14.94 11.62L20.4678 8.3L14.94 4.98V11.62ZM5.64 9.96C6.19228 9.96 6.64 9.51229 6.64 8.96V7.64C6.64 7.08772 6.19228 6.64 5.64 6.64H1C0.447715 6.64 0 7.08772 0 7.64V8.96C0 9.51229 0.447715 9.96 1 9.96H5.64ZM5.64 16.6C6.19228 16.6 6.64 16.1523 6.64 15.6V14.28C6.64 13.7277 6.19228 13.28 5.64 13.28H1C0.447715 13.28 0 13.7277 0 14.28V15.6C0 16.1523 0.447715 16.6 1 16.6H5.64Z"
          fill="currentColor"
        />
      </svg>
    ),
    id: "ps-upload-course",
    title: "Upload Course",
    subtitle: "Course",
    path: "/upload/course",
  },

  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="m2.5 8.9l9 5.2c.2.1.3.1.5.1s.3 0 .5-.1l9-5.2c.2-.1.3-.2.4-.4c.2-.5.1-1.1-.4-1.4l-9-5.2c-.3-.2-.7-.2-1 0l-9 5.2c-.2.1-.3.2-.4.4c-.2.5-.1 1.1.4 1.4m19 2.2l-.2-.1l-8.8 5.1c-.3.2-.7.2-1 0L2.7 11l-.2.1c-.5.3-.6.9-.4 1.4c.1.2.2.3.4.4l9 5.2c.3.2.7.2 1 0l9-5.2c.5-.3.6-.9.4-1.4c-.1-.2-.2-.3-.4-.4m0 4l-.2-.1l-8.8 5.1c-.3.2-.7.2-1 0L2.7 15l-.2.1c-.5.3-.6.9-.4 1.4c.1.2.2.3.4.4l9 5.2c.3.2.7.2 1 0l9-5.2c.5-.3.6-.9.4-1.4c-.1-.2-.2-.3-.4-.4"
        />
      </svg>
    ),
    id: "ps-upload-bundle",
    title: "Upload Bundle",
    subtitle: "Bundle",
    path: "/upload/bundle",
  },
];
