import React from "react";

import SkeletonElement from "./SkeletonElement";

function RowCardSkeleton({ number, type = "sk-home-video-card" }) {
  return [...Array(Number(Math.ceil(number)))].map((e, i) => (
    <SkeletonElement key={i} type={type} />
  ));
}

export default RowCardSkeleton;
