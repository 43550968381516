"use client";

import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import Image from "next/image";

import Button from "../../atoms/buttons/Button";
import styles from "./unwrap.module.scss";

function UnWrap(props) {
  const {
    courseTitle,
    videoTitle,
    videoSeoTitle,
    videoDescription,
    thumbnailImage,
    btnText,
  } = props;

  const [currentLecture, setCurrentLecture] = useState(0);

  useEffect(() => {
    let lecture = Number(localStorage.getItem("currentLecture"));
    if (lecture) {
      lecture = lecture < 2 ? lecture + 1 : 0;
    } else {
      lecture = 0;
    }
    setCurrentLecture(lecture);
    localStorage.setItem("currentLecture", lecture);
  }, []);

  const handleLectureClick = (index) => {
    setCurrentLecture(index);
  };

  return (
    <article className={styles.unwrap}>
      <div className={styles.content}>
        <h2>{courseTitle}</h2>
        <div className={styles["lec-btn"]}>
          <Button
            name={"Lecture 1"}
            className={`${styles.button} ${videoSeoTitle[currentLecture] == videoSeoTitle[0] ? styles.active : ""}`}
            handleClick={() => handleLectureClick(0)}
          />
          <Button
            name={"Lecture 2"}
            className={`${styles.button} ${videoSeoTitle[currentLecture] == videoSeoTitle[1] ? styles.active : ""}`}
            handleClick={() => handleLectureClick(1)}
          />
          <Button
            name={"Lecture 3"}
            className={`${styles.button} ${videoSeoTitle[currentLecture] == videoSeoTitle[2] ? styles.active : ""}`}
            handleClick={() => handleLectureClick(2)}
          />
        </div>
        <h3>{videoTitle[currentLecture]}</h3>
        <p>{videoDescription[currentLecture]}..</p>
        <a
          href={`/courses/${props.courseSeoTitle}/${videoSeoTitle[currentLecture]}`}
        >
          Get Started with {btnText}&nbsp;-&gt;
        </a>
      </div>
      <a
        href={`/courses/${props.courseSeoTitle}/${videoSeoTitle[currentLecture]}`}
        className={styles.image}
      >
        <Image
          src={thumbnailImage[currentLecture]}
          alt={"alumni-course"}
          height={600}
          width={600}
        />
        <Icon icon="emojione-monotone:play-button" />
      </a>
    </article>
  );
}

export default UnWrap;
