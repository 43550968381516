import Image from "next/image";
import React from "react";

import styles from "./announce.module.scss";
import Components from "@/components";
import Images from "@/assets/images";

function Announcement(props) {
  const { title, description, buttonName, handler } = props;
  return (
    <section className={styles.banner}>
      <article className={styles.image}>
        <Image src={Images.CertificatePopUp} alt="Onboarding Image" />
      </article>
      <article className={styles.content}>
        <p>{title}</p>

        <Components.Button
          className={styles.featureBtn}
          handleClick={handler}
          name={buttonName}
        />
      </article>
    </section>
  );
}

export default Announcement;
