"use client";

import React, { Fragment, useEffect, useState } from "react";
import "./pagination.scss";

export default function Pagination({ totalPage, currentPage, pageHandler }) {
  const [maxItem, setMaxItem] = useState(8);

  function renderItems(total) {
    const fullItems = [];
    for (let i = 0; i < total; i++) {
      fullItems.push(
        <span
          key={`page-${i}`}
          className={`pagination-item ${currentPage === i + 1}`}
          onClick={() => pageHandler?.(i + 1)}
        >
          {i + 1}
        </span>
      );
    }
    if (total > maxItem * 2) {
      if (currentPage < maxItem || currentPage > totalPage - maxItem) {
        return [
          ...fullItems.slice(0, maxItem),
          <span className="pagination-dot" key="dot4-1">
            &nbsp;&bull;&nbsp;&nbsp;&bull;&nbsp;&nbsp;&bull;&nbsp;&nbsp;&bull;&nbsp;
          </span>,
          ...fullItems.slice(-maxItem),
        ];
      } else {
        const slicedItems = [];
        slicedItems.push(fullItems[0]);
        slicedItems.push(
          <span className="pagination-dot" key="dot2-1">
            &nbsp;&bull;&nbsp;&nbsp;&nbsp;&bull;&nbsp;
          </span>
        );
        let startIndex = maxItem - 1;
        let endIndex = startIndex + maxItem - 1;
        if (currentPage > endIndex) {
          startIndex = currentPage - maxItem / 2;
          endIndex = startIndex + maxItem - 1;
        }
        slicedItems.push(...fullItems.slice(startIndex, endIndex));
        slicedItems.push(
          <span className="pagination-dot" key="dot2-2">
            &nbsp;&bull;&nbsp;&nbsp;&nbsp;&bull;&nbsp;
          </span>
        );
        slicedItems.push(...fullItems.slice(-1));
        return slicedItems;
      }
    }
    return fullItems;
  }

  function eventListener() {
    if (window.innerWidth > 910) setMaxItem(8);
    else if (window.innerWidth > 810) setMaxItem(7);
    else if (window.innerWidth > 730) setMaxItem(6);
    else if (window.innerWidth > 640) setMaxItem(5);
    else if (window.innerWidth > 520) setMaxItem(4);
    else if (window.innerWidth > 430) setMaxItem(3);
    else if (window.innerWidth > 340) setMaxItem(2);
    else setMaxItem(1);
  }

  useEffect(() => {
    if (typeof window === "undefined") return;
    eventListener();
    window.addEventListener("resize", eventListener);
    return () => window.removeEventListener("resize", eventListener);
  }, []);

  return (
    <Fragment>
      {totalPage > 1 ? (
        <div className="pagination-container">
          <span
            className={`pagination-item side ${currentPage > 1 ? "" : "disabled"}`}
            onClick={() => currentPage > 1 && pageHandler?.(currentPage - 1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M15.707 4.293a1 1 0 0 1 0 1.414L9.414 12l6.293 6.293a1 1 0 0 1-1.414 1.414l-7-7a1 1 0 0 1 0-1.414l7-7a1 1 0 0 1 1.414 0Z"
              />
            </svg>
          </span>
          {renderItems(totalPage)}
          <span
            className={`pagination-item side ${currentPage < totalPage ? "" : "disabled"}`}
            onClick={() =>
              currentPage < totalPage && pageHandler?.(currentPage + 1)
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M8.293 4.293a1 1 0 0 0 0 1.414L14.586 12l-6.293 6.293a1 1 0 1 0 1.414 1.414l7-7a1 1 0 0 0 0-1.414l-7-7a1 1 0 0 0-1.414 0Z"
              />
            </svg>
          </span>
        </div>
      ) : null}
    </Fragment>
  );
}
