export default [
  // {
  //   id: "welcome in profile",
  //   text: [
  //     `
  //     <h4 class="shephered-custom-title">Hi, here we will guide you about profile dashboard features, the place that will keep you high.</h4>
  //     <p class="shephered-custom-para">
  //     This will take as little as a minute....
  //       </p>
  //     <h4 class="shephered-custom-foot">Let's explore 😋</h4>
  //       `,
  //   ],
  //   attachTo: { element: "", on: "center" },
  //   classes: "shepherd shepherd-welcome",
  //   buttons: [
  //     {
  //       type: "skip",
  //       classes: "shepherd-button-secondary",
  //       text: "Skip",
  //     },
  //     {
  //       type: "next",
  //       classes: "shepherd-button-next",
  //       text: "Next",
  //     },
  //   ],
  //   when: {
  //     show: () => {
  //       localStorage.setItem(
  //         "profile-tour-guide",
  //         JSON.stringify({ show: "YES", step: 0 })
  //       );
  //     },
  //     cancel: () => {
  //       localStorage.setItem(
  //         "profile-tour-guide",
  //         JSON.stringify({ show: "NO", step: 0 })
  //       );
  //     },
  //   },
  // },
  {
    id: "user-profile",
    text: [
      `
      <h4 class="shephered-custom-title">
      Add or change your cool profile picture, we will not judge.
      </h4>
      <h4 class="shephered-custom-foot">Click here to add/change it.</h4>
        `,
    ],
    attachTo: { element: ".pf-profile-pencil-icon", on: "bottom" },
    classes: "shepherd shepherd-welcome",
    buttons: [
      {
        type: "cancel",
        classes: "shepherd-button-secondary",
        text: "Skip",
      },
      {
        type: "next",
        classes: "shepherd-button-next",
        text: "Next",
      },
    ],
    when: {
      show: () => {
        localStorage.setItem(
          "profile-tour-guide",
          JSON.stringify({ show: "YES", step: 1 })
        );
      },
    },
  },
  {
    id: "edit-profile",
    text: [
      `
      <h4 class="shephered-custom-title">
      Here, you can update your personal details, we will never spam or sell you anything with this information.
      </h4>
      <h4 class="shephered-custom-foot">Click here to update & relax.</h4>
      `,
    ],
    attachTo: { element: "#ps-my-profile", on: "right" },
    buttons: [
      {
        type: "back",
        classes: "shepherd-button-secondary",
        text: "Back",
      },
      {
        type: "next",
        classes: "shepherd-button-next",
        text: "Next",
      },
    ],
    when: {
      show: () => {
        localStorage.setItem(
          "profile-tour-guide",
          JSON.stringify({ show: "YES", step: 2 })
        );
      },
    },
  },
  {
    id: "Enrolled Courses",
    text: [
      `
      <h4 class="shephered-custom-title">
      Here, you can find which courses you are enrolled in & be able to track your progress.
      </h4>
      <h4 class="shephered-custom-foot">Click here to get see.</h4>
        `,
    ],
    attachTo: { element: "#ps-enrolled-courses", on: "right" },
    buttons: [
      {
        type: "back",
        classes: "shepherd-button-secondary",
        text: "Back",
      },
      {
        type: "next",
        classes: "shepherd-button-next",
        text: "Next",
      },
    ],
    when: {
      show: () => {
        localStorage.setItem(
          "profile-tour-guide",
          JSON.stringify({ show: "YES", step: 3 })
        );
      },
    },
  },
  {
    id: "Wishlist",
    text: [
      `
      <h4 class="shephered-custom-title">
      Here, you can see courses, which are added to the wishlist for either procrastination or making a dent into the world & telling about your existence.
      </h4>
      <h4 class="shephered-custom-foot">Click here to allow Genie to open it for you.</h4>
      `,
    ],
    attachTo: { element: "#ps-wishlist", on: "right" },
    buttons: [
      {
        type: "back",
        classes: "shepherd-button-secondary",
        text: "Back",
      },
      {
        type: "next",
        classes: "shepherd-button-next",
        text: "Next",
      },
    ],
    when: {
      show: () => {
        localStorage.setItem(
          "profile-tour-guide",
          JSON.stringify({ show: "YES", step: 4 })
        );
      },
    },
  },
  {
    id: "Liked-Videos",
    text: [
      `
      <h4 class="shephered-custom-title">
      Here, you can see courses/videos, which you once liked maybe because it taught you better than your College/University.
      </h4>
      <h4 class="shephered-custom-foot">Click here to see.</h4>
      `,
    ],
    attachTo: { element: "#ps-liked-videos", on: "right" },
    buttons: [
      {
        type: "back",
        classes: "shepherd-button-secondary",
        text: "Back",
      },
      {
        type: "next",
        classes: "shepherd-button-next",
        text: "Done",
      },
    ],
    when: {
      show: () => {
        localStorage.setItem(
          "profile-tour-guide",
          JSON.stringify({ show: "NO", step: 0 })
        );
      },
      destroy: () => {
        let data = JSON.parse(localStorage.getItem("profile-tour-guide"));
        if (data.show === "NO") {
          window.scrollTo(0, 0);
          window.location.assign("/");
        } else {
          localStorage.setItem(
            "home-tour-guide",
            JSON.stringify({ show: "NO", step: 0 })
          );
          localStorage.setItem(
            "video-tour-guide",
            JSON.stringify({ show: "NO", step: 0 })
          );
          localStorage.setItem(
            "profile-tour-guide",
            JSON.stringify({ show: "NO", step: 0 })
          );
          window.location.assign("/");
        }
      },
    },
  },
];
