"use client";

import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import WarningAlert from "@/components/molecules/alerts/warning";
import SocketContext from "@/socket/Context";
import services from "@/store/services";
import styles from "./styles.module.scss";

function FollowBtn(props) {
  const { user, className } = props;

  const { socket } = useContext(SocketContext).SocketState;
  const network = useSelector((state) => state.network);
  const { users } = useSelector((state) => state.user);
  const [warning, setWarning] = useState({
    name: "",
    type: "",
    show: false,
    btnClick: () => {},
  });
  const dispatch = useDispatch();

  function onCancel() {
    setWarning({
      name: "",
      type: "",
      show: false,
      btnClick: () => {},
    });
  }

  const handleFollowing = async (userId) => {
    if (users.email) {
      dispatch(services.unfollowUser(userId, users._id, socket));
    }
  };

  function onClick() {
    if (!users.email) {
      dispatch(services.toggleSigninModal());
      return;
    }
    if (network.find((networkUser) => networkUser._id === user?._id))
      setWarning({
        name: user?.name,
        type: "unfollow",
        show: true,
        btnClick: () => handleFollowing(user?._id),
      });
    else {
      dispatch(services.followUser(user?._id, users._id, socket));
    }
  }

  return (
    <>
      {warning.show ? (
        <WarningAlert
          name={warning.name}
          type={warning.type}
          onCancel={onCancel}
          onbtnClick={warning}
        />
      ) : null}
      <button
        className={`${
          !network.find((networkUser) => networkUser?._id === user?._id)
            ? styles["fc-follow-btn"]
            : `${styles["fc-follow-btn"]} ${styles["followed"]}`
        } ${className}`}
        disabled={users?._id === user?._id}
        onClick={onClick}
        id="vd_Follow_Btn"
      >
        {!network.find((networkUser) => networkUser._id === user?._id)
          ? "Follow"
          : users?._id === user?._id
            ? "Follow"
            : "Following"}
      </button>
    </>
  );
}

export default FollowBtn;
