"use client";

import { Box, List, Tooltip, Drawer } from "@mui/material";
import { useRouter } from "next/navigation";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import { Icon } from "@iconify/react";

import CreatorsMenu from "./partials/creators";
import MainContent from "./partials/main";
import styles from "./styles.module.scss";

function ProfileSidebar() {
  const user = useSelector((state) => state?.user?.users);
  const [uploadMenu, setUploadMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const router = useRouter();

  const handleClick = (item) => {
    if (item.title === "New Upload") {
      setUploadMenu((prev) => !prev);
    } else {
      router.push(item.path);
    }
  };

  return (
    <article className={styles["main"]}>
      <Drawer
        variant="permanent"
        className={`${styles.drawer} ${styles[isOpen]}`}
        classes={{
          paper: `${styles[`drawer-${isOpen ? "open" : "close"}`]}`,
        }}
        open
        anchor="right"
      >
        <Tooltip title={isOpen ? "Collapse" : "Expand"} placement="left" arrow>
          <article
            className={styles["close-box"]}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <Icon
              icon={"ic:round-keyboard-arrow-right"}
              className={isOpen ? styles["open"] : styles["close"]}
            />
          </article>
        </Tooltip>
        <Box className={styles["content-box"]}>
          <List className={styles["item-content"]}>
            <MainContent isOpen={isOpen} handleClick={handleClick} />
            {user?.isCreator ? (
              <>
                <div
                  className={` ${styles["divider"]} ${isOpen ? "" : styles["divider-close"]}`}
                />
                <CreatorsMenu
                  isOpen={isOpen}
                  uploadMenu={uploadMenu}
                  handleClick={handleClick}
                />
              </>
            ) : null}
          </List>
        </Box>
      </Drawer>
    </article>
  );
}

export default ProfileSidebar;
