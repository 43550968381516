const CERTIFICATE_TOUR_DATA = [
  {
    content: (
      <p>
        Just a few more steps! Complete{" "}
        {parseFloat(process.env.NEXT_PUBLIC_COURSE_COMPLETION_CRITERIA) * 100}%
        of the course to unlock and redeem your certificate.
      </p>
    ),
    title: <h2>You're Almost There! 🎉</h2>,
    placement: "top",
    target: "#vd_Certificate_Request_Btn",
  },
];

export default CERTIFICATE_TOUR_DATA;
