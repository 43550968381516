import Image from "next/image";
import React from "react";

import styles from "./batch.module.scss";
import Components from "@/components";
import utils from "@/utils";

const BatchCard = (props) => {
  const { name, description, coverImage, mentors, _id, startDate } = props;

  const mentor = mentors.find((mentor) => mentor?.type === "teaching");

  return (
    <article className={styles.card} id={`batches-${_id}`}>
      <a href={`/batches/${_id}`} className={styles["anchor-level"]}></a>
      <figure className={styles.header}>
        <Image
          src={coverImage}
          alt={`batch-cover-${name}`}
          width={500}
          height={450}
        />
        <div className={styles.mentor}>
          <Components.Avatar
            src={mentor?.profileImage}
            alt={mentor?.name || `${name}`}
            loader={"lg"}
            errStyle={{ fontSize: "1.5rem" }}
          />
        </div>
      </figure>

      <summary>
        <p className={styles.tagline}>
          {mentor?.name} &bull; {utils.formatDate(new Date(startDate))}
        </p>
        <h2>{name}</h2>
        <p>{description}</p>
      </summary>
    </article>
  );
};

export default BatchCard;
