const courses = Object.freeze({
  liveCourses: [
    {
      teamsLink: "https://bit.ly/3X9ojQo",
      courseId: "6624c1f60e5fc49e9cfa5467",
      groupLink: "https://chat.whatsapp.com/IL0QoZvFEm8FBhnEBSQu2r",
      communityLink:
        "https://www.plutonn.com/api/v1/links?query=bef27babd73dff366e9e2c3f93957bd069cb758a4534ba9db7a6c53c9452dc18",
      recordingLink: "",
      ended: true,
    },
    {
      teamsLink: "https://bit.ly/4c3mf0r",
      courseId: "6624c2de0e5fc49e9cfa5468",
      groupLink: "https://chat.whatsapp.com/KcvGHirAbP71FKmhrlcXHR",
      communityLink:
        "https://www.plutonn.com/api/v1/links?query=4cc08cffe69c66446368f00828f507e9b52972e0ddd25bbecfe61269a60ab8d1",
      recordingLink: "",
      ended: true,
    },
    {
      teamsLink: "https://bit.ly/4aQXLqi",
      courseId: "6624f6460e5fc49e9cfa5499",
      groupLink: "https://chat.whatsapp.com/Je98A2h67HIBl7nOrl8N0w",
      communityLink:
        "https://www.plutonn.com/api/v1/links?query=0bb6b8a8ae75a70bb32f4f68cec3ae78370408b64b0efc67fc86e7ec67d3fd98",
      recordingLink: "",
      ended: true,
    },
    /* Unity Live ->  */
    {
      teamsLink:
        "https://events.teams.microsoft.com/event/256f60f6-0463-49af-8565-bf9202543432@62223da0-d228-48fc-b3b1-eb95ab2d4902",
      courseId: "6625012e0e5fc49e9cfa54ab",
      groupLink: "https://chat.whatsapp.com/Ij2MpO1QPxm0l60Wb6PWQN",
      communityLink:
        "https://www.plutonn.com/api/v1/links?query=bcd4b27a2d5d52cc617c1bf700345b8a8838427a1b09dc5d80b72b9807770a6f",
      recordingLink: "",
      ended: true,
    },
    {
      teamsLink: "https://bit.ly/3yJJuym",
      courseId: "6625021a0e5fc49e9cfa54ae",
      groupLink: "https://chat.whatsapp.com/Fy1yMJ4Uux0JDaD33vdHmo",
      communityLink:
        "https://www.plutonn.com/api/v1/links?query=4a992f7a13acbd3732a13f302980c5689feadcd43051b359049e11ed8fc7d2b3",
      recordingLink: "",
      ended: true,
    },
    {
      teamsLink: "https://bit.ly/3xeavJD",
      courseId: "6625044b0e5fc49e9cfa54b1",
      groupLink: "https://chat.whatsapp.com/H3OxQ4AM5WdBE9iNMHF4lG",
      communityLink:
        "https://www.plutonn.com/api/v1/links?query=7abec905077b9a7f9953e023e43dcee7df23e2e4ddb3c07dd0f9da16089fe8f1",
      recordingLink: "",
      ended: true,
    },
    {
      teamsLink: "https://bit.ly/3wT5Z3n",
      courseId: "6625050a0e5fc49e9cfa54b2",
      groupLink: "https://chat.whatsapp.com/L3ztadif3deAevS7vqXyqq",
      communityLink:
        "https://www.plutonn.com/api/v1/links?query=cbbc8057f0d0fb0c85cbc8d8518e93bcd98642d79756e6b5e38db665da4288ed",
      recordingLink: "",
      ended: true,
    },
    {
      teamsLink: "https://bit.ly/4c79imd",
      courseId: "6625058b0e5fc49e9cfa54b4",
      groupLink: "https://chat.whatsapp.com/CjaPFTvZs4aLDuUWOxsUb3",
      communityLink:
        "https://www.plutonn.com/api/v1/links?query=439e03a35b75fef33b578f33d6e6652947637ab454a65c607f7767958104559e",
      recordingLink: "",
      ended: true,
    },
  ],
});

export default courses;
