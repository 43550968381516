/* eslint-disable react-hooks/rules-of-hooks */
"use client";

import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";

import useGetUploadCourseStatus from "@/hooks/useGetUploadCourseStatus";
import useGetUploadBundleStatus from "@/hooks/useGetUploadBundleStatus";
import styles from "./upload.module.scss";
import useDataList from "./partials/data";
import services from "@/store/services";

function UploadSideBar() {
  const course = useSelector((state) => state.courseDetails.courseInfo);
  const bundle = useSelector((state) => state.bundleDetails.bundleInfo);

  const bundleStatus = useGetUploadBundleStatus();
  const courseStatus = useGetUploadCourseStatus();

  const [publishable, setPublishable] = useState(false);
  const [status, setStatus] = useState([]);
  const [isBundle, setIsBundle] = useState(null);

  const searchParams = useSearchParams();
  const dispatch = useDispatch();
  const pathname = usePathname();
  const router = useRouter();

  const id = searchParams.get("id");

  const DataList = useDataList();

  const handleClick = (item) => {
    const currIdx = DataList.findIndex((list) => list.id === item.id);

    if (currIdx >= 1) {
      const flag = status.includes(DataList[currIdx - 1].id);

      if (!flag) {
        dispatch(
          services.warningAlert("Please complete the previous sections!")
        );
        return;
      } else {
        router.push(id && id !== "" ? `${item.path}?id=${id}` : item.path);
      }
    } else {
      router.push(id && id !== "" ? `${item.path}?id=${id}` : item.path);
    }
  };

  const handlePublishClick = () => {
    if (publishable) {
      isBundle
        ? router.push(
            id && id !== ""
              ? `/upload/bundle/publish?id=${id}`
              : "/upload/bundle/publish"
          )
        : router.push(
            id && id !== ""
              ? `/upload/course/publish?id=${id}`
              : "/upload/course/publish"
          );
    } else {
      dispatch(
        services.warningAlert(
          `Cannot publish an incomplete ${isBundle ? "bundle" : "course"}`
        )
      );
    }
  };

  const getStatusFunc = () => {
    const _status = isBundle ? bundleStatus : courseStatus;

    setStatus(_status);
  };

  useEffect(() => {
    const _isBundle = pathname.includes("/upload/bundle");
    setIsBundle(_isBundle);
    getStatusFunc();
  }, [bundle, course, bundleStatus, courseStatus]);

  useEffect(() => {
    if (bundle) {
      // Required bundle statuses
      const bundleReady =
        status.includes("bundle-structure") &&
        status.includes("landing-page") &&
        status.includes("curriculam") &&
        status.includes("pricing") &&
        status.includes("creator-message");

      setPublishable(bundleReady);
    } else if (course) {
      // Course publishing logic
      const freeCourseFlag =
        status.includes("course-structure") &&
        status.includes("landing-page") &&
        status.includes("curriculam");

      const paidCourseFlag =
        freeCourseFlag &&
        status.includes("pricing") &&
        status.includes("creator-message");

      if (
        (course?.visibility === "public" ||
          course?.visibility === "unlisted") &&
        freeCourseFlag
      )
        setPublishable(true);

      if (course?.visibility === "restricted" && paidCourseFlag)
        setPublishable(true);
    }
  }, [isBundle, course?.visibility, status, bundle]);

  return (
    <div className={styles.container}>
      {DataList.map((item, i) => (
        <button
          key={i}
          className={`${styles.btn} ${pathname === item.path ? styles["active"] : ""}`}
          onClick={() => handleClick(item)}
        >
          <span className={styles["btn-content"]}>
            {isBundle ? (
              <>
                {status.includes(item.id) ? (
                  <Icon
                    icon="mdi:tick-circle-outline"
                    className={styles["icon-checked"]}
                  />
                ) : (
                  <Icon
                    icon="mdi:tick-circle-outline"
                    className={styles["icon-unchecked"]}
                  />
                )}
              </>
            ) : (
              <>
                {status.includes(item.id) &&
                item.required.includes(course?.visibility) ? (
                  <Icon
                    icon="mdi:tick-circle-outline"
                    className={styles["icon-checked"]}
                  />
                ) : (
                  <Icon
                    icon="mdi:tick-circle-outline"
                    className={styles["icon-unchecked"]}
                  />
                )}
              </>
            )}

            {item.title}
          </span>
        </button>
      ))}
      {!pathname.startsWith("/upload/video") ? (
        <button
          className={`${styles.publishBtn} ${
            publishable ? styles.publishEnabled : styles.publishDisabled
          }`}
          onClick={handlePublishClick}
          disabled={!publishable}
        >
          <span className={styles["btn-content"]}>
            <Icon icon="fluent-mdl2:publish-content" className={styles.icon} />
            Publish {isBundle ? "Bundle" : "Course"}
          </span>
        </button>
      ) : null}
    </div>
  );
}

export default UploadSideBar;
