import React from "react";

import styles from "./all.module.scss";

function ViewAllCard() {
  return (
    <article className={styles["container"]}>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M8.293 4.293a1 1 0 0 0 0 1.414L14.586 12l-6.293 6.293a1 1 0 1 0 1.414 1.414l7-7a1 1 0 0 0 0-1.414l-7-7a1 1 0 0 0-1.414 0Z"
          />
        </svg>
        <p>View All</p>
      </div>
    </article>
  );
}

export default ViewAllCard;
