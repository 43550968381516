import Image from "next/image";
import React from "react";

import bg from "@/assets/images/CertificateBg.png";

import styles from "./certificate.module.scss";
import utils from "@/utils";

const getCertificateType = (type) => {
  switch (type) {
    case "EC4":
      return "Eminence Certificate";
    case "EC5":
      return "Eminence Certificate";
    case "TC":
      return "Training certificate";
    case "CO":
      return "Completion Certificate";
    case "PC":
      return "Project Certificate";
    default:
      return "Certificate";
  }
};

function CertificateCard(props) {
  const { id, url, course, type, duration, createdAt } = props;

  return (
    <article className={styles["card"]} id={`PD_Certificate_Card`}>
      <a
        href={
          !url.includes("cipher-other-assets")
            ? url
            : `/certificate/preview?id=${id}`
        }
        target="_blank"
        className={styles["anchor"]}
      ></a>
      <figure className={styles["card-figure"]}>
        <Image
          src={bg}
          alt={`certificate-${course}`}
          width={400}
          height={400}
        />
        <div className={styles["content"]}>
          <h4 className={styles.ceritficateTitle}>
            {getCertificateType(type)}
          </h4>
          <p className={styles.duration}>{duration}</p>
          <p className={styles["box-text"]}>{course}</p>
          <p className={styles["date"]}>
            {utils.formatDate(new Date(createdAt))}
          </p>
        </div>
      </figure>
    </article>
  );
}

export default CertificateCard;
