"use client";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ListItem } from "@mui/material";
import { withStyles } from "@mui/styles";

import styles from "./category.module.scss";
import services from "@/store/services";
import useApi from "@/hooks/useApi";
import sk from "@/skeletons";
import api from "@/api";

const MuiListItem = withStyles({
  root: {
    width: "100%",
    height: "40px",
    borderRadius: "8px",
    boxSizing: "border-box",
    fontWeight: "var(--fw-medium)",
    fontSize: "12px",
    color: "var(--heading)",
    backgroundColor: "var(--second-white)",
    "&$selected": {
      backgroundColor: "var(--brand-color)",
      color: "white",
    },
    "&$selected:hover": {
      opacity: 0.85,
    },
    ["@media screen and (min-width:1025px)"]: {
      fontSize: "14px",
    },
  },
  selected: {},
})(ListItem);

function InterestModal({ interest, saveCallback }) {
  const [interests, setInterests] = useState([...interest]);
  const [temp, setTemp] = useState(true);
  const dispatch = useDispatch();

  const {
    data: ctgList,
    loading: ctgLoad,
    request: loadctg,
  } = useApi(api.category);

  useEffect(() => {
    loadctg(1, 20);
  }, [loadctg]);

  const handleModalSave = () => {
    //computing
    saveCallback(interests);
    dispatch(services.toggleInterestModal());
  };

  const handleItemAdd = (interest) => {
    if (interests.includes(interest)) {
      let index = interests.indexOf(interest);
      interests.splice(index, 1);
    } else interests.push(interest);
    setTemp(!temp);
    setInterests(interests);
  };

  return (
    <section>
      <article className={styles["im-container"]}>
        {!ctgLoad ? (
          ctgList?.data?.map((interest, i) => {
            return (
              <MuiListItem
                key={i}
                components={"button"}
                className={styles["im-item-container"]}
                onClick={() => handleItemAdd(interest.title)}
              >
                {interest.title}
              </MuiListItem>
            );
          })
        ) : (
          <sk.RowCardSkeleton number="8" type="sk-interest-tab" />
        )}
      </article>
      <div className={styles["pm-pass-btns"]}>
        <div
          className={styles["pm-cancel-btns"]}
          onClick={() => dispatch(services.toggleInterestModal())}
        >
          Cancel
        </div>
        <div className={styles["pm-save-btns"]} onClick={handleModalSave}>
          Save
        </div>
      </div>
    </section>
  );
}

export default InterestModal;
