"use client";

import React, { useState } from "react";

import styles from "./alumni.module.scss";
import Components from "@/components";
import apis from "@/api";

const Form = () => {
  const initialState = {
    name: null,
    email: null,
    linkedinProfile: null,
    contribution: null,
    suggestion: null,
  };

  const [form, setForm] = useState(initialState);
  const [validationAlert, setValidationAlert] = useState({
    name: "",
    type: "missingField",
    show: false,
    btnClick: () => {
      setValidationAlert({ ...validationAlert, show: !validationAlert.show });
    },
  });
  const [status, setStatus] = useState({
    show: false,
    code: null,
    msg: "",
  });

  const [loading, setLoading] = useState(false);

  const options = [
    { value: "YouTube Workshop" },
    { value: "LinkedIn Live" },
    { value: "Providing Referrals" },
  ];

  const validateForm = (form) => {
    const { name, email, linkedinProfile, contribution } = form;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!name || !email || !linkedinProfile || !contribution) {
      setValidationAlert({
        ...validationAlert,
        name: "Some fields are missing in form.",
        show: !validationAlert.show,
      });
      return false;
    }
    if (name && (name.length < 5 || name.length > 50)) {
      setValidationAlert({
        ...validationAlert,
        name: "Name should contain minimum 5 and maximum 50 characters only",
        show: !validationAlert.show,
      });
      return false;
    }
    if (!emailRegex.test(email)) {
      setValidationAlert({
        ...validationAlert,
        name: "Email is not valid please check it again.",
        show: !validationAlert.show,
      });
      return false;
    }

    return true;
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleDropDownChange = (categoryItem) => {
    setForm({ ...form, contribution: categoryItem.value });
  };

  function onCancel() {
    setValidationAlert({
      name: "",
      type: "missingField",
      show: false,
      btnClick: () => {
        setValidationAlert({ ...validationAlert, show: !validationAlert.show });
      },
    });
  }

  function onCancelSuccess() {
    setStatus({ show: false, code: null, msg: "" });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm(form)) return;
    setLoading(true);
    try {
      const response = await apis.takeAlumniConsent(form);
      if (response.status === 200) {
        setStatus((prev) => ({
          ...prev,
          show: true,
          code: 200,
          msg: "We will verify your documents and update you soon",
        }));
        setForm(initialState);
      } else {
        setStatus((prev) => ({
          ...prev,
          show: true,
          code: 500,
          msg: "Please report this issue in feedback.",
        }));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setStatus((prev) => ({
        ...prev,
        show: true,
        code: 500,
        msg: error.message ?? "Please report this issue in feedback.",
      }));
    }
  };

  return (
    <section className={styles.formContainer}>
      <form className={styles.form}>
        <Components.Input
          type="text"
          name="name"
          placeholder="Full Name"
          value={form.name}
          handleChange={handleChange}
          className={styles.inputText}
        />
        <Components.Input
          className={styles.inputText}
          type="email"
          name="email"
          placeholder="Email"
          value={form.email}
          handleChange={handleChange}
        />
        <Components.Input
          type="text"
          name="linkedinProfile"
          className={styles.inputText}
          placeholder="Linkedin Profile"
          value={form.linkedinProfile}
          handleChange={handleChange}
        />

        <Components.Filter
          filter={{
            value:
              form.contribution === null ? "Contribution" : form.contribution,
          }}
          list={options}
          handleChange={handleDropDownChange}
          className={styles.dropdown}
        />

        <textarea
          name="suggestion"
          placeholder="Any Suggestion"
          value={form.description}
          onChange={(e) => handleChange(e)}
          rows="5"
          className={styles.inputText}
        />

        <Components.LoadingBtn loading={loading} className={styles.formBtn}>
          <button onClick={handleSubmit}>Send for verification</button>
        </Components.LoadingBtn>
      </form>

      {validationAlert.show && (
        <Components.WarningAlert
          name={validationAlert.name}
          type={validationAlert.type}
          onCancel={onCancel}
          onbtnClick={validationAlert}
        />
      )}

      {status.show && (
        <Components.WarningAlert
          name={status.msg}
          type={status.code === 200 ? "consentSuccess" : "sendFailure"}
          onCancel={onCancelSuccess}
        />
      )}
    </section>
  );
};

export default Form;
