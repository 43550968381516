"use client";

import Select from "react-select";
import React, { useEffect, useState } from "react";

import { useDebounce } from "@/hooks/useDebounce";
import styles from "./drop-down.module.scss";

export function SearchDropdown(props) {
  const {
    searchFunction,
    refetch,
    value,
    onOptionSelect,
    optsArray,
    className,
  } = props;

  const [searchValue, setSearchValue] = useState("");
  const [optionsArray, setOptionsArray] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const debouncedValue = useDebounce(searchValue);

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\\\\W/g, "");
    setSearchValue(inputValue);
    return inputValue;
  };

  useEffect(() => {
    const fetchData = () => {
      searchFunction &&
        searchFunction(debouncedValue).then((res) => {
          setOptionsArray(res);
        });
    };

    if (refetch) {
      refetch(debouncedValue);
    } else {
      fetchData();
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (optsArray) setOptionsArray(optsArray);
  }, [optsArray]);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  const Option = (props) => {
    return (
      <div
        {...props}
        onClick={() => {
          onOptionSelect({ label: props.data.label, value: props.data.value });
          setSelectedOption(props.data);
        }}
        className={styles.option}
      ></div>
    );
  };

  return (
    <div className={`${styles.selectContainer} ${className}`}>
      <Select
        components={{ Option }}
        value={selectedOption}
        inputValue={searchValue}
        options={optionsArray}
        onInputChange={handleInputChange}
        classNames={{
          container: () => styles["input-field-select"],
          control: ({ isFocused }) =>
            isFocused ? styles["focused-input"] : styles["unfocused-input"],
          singleValue: () => styles.valueContainer,
          input: () => styles.valueContainer,
          menu: () => styles["menu-container"],
        }}
      />
    </div>
  );
}

function DropDownInput(props) {
  const {
    label,
    options,
    value,
    disabled,
    onChange,
    className,
    validationError = false,
    requiredField = false,
    menuPlacement = "auto",
    onInputChange = null,
  } = props;

  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <div className={`${styles["container"]} ${className}`}>
      {label?.length > 0 && (
        <div className={styles["input-label"]}>
          {label}
          {requiredField ? (
            <span className={styles.requiredField}> *</span>
          ) : null}
        </div>
      )}
      <Select
        value={value}
        options={options}
        isDisabled={disabled}
        menuPlacement={menuPlacement}
        onInputChange={onInputChange}
        onChange={handleChange}
        classNames={{
          container: () =>
            validationError
              ? styles["input-field-select-error"]
              : styles["input-field-select"],
          control: () => styles["control-color"],
          singleValue: () => styles["color-input"],
          valueContainer: () => styles["color-input"],
          placeholder: () => styles["color-input"],
          input: () => styles["color-input"],
          menu: () => styles["color"],
          group: () => styles["color-input"],
          option: ({ isSelected, isFocused }) => {
            return isSelected
              ? styles["color-selected"]
              : isFocused
                ? styles["color-selected"]
                : styles["color"];
          },
        }}
      />
    </div>
  );
}

export default React.memo(DropDownInput);
