"use client";

import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import Joyride, { STATUS } from "react-joyride";

import services from "@/store/services";

function FeatureTour({ stepsData, tourFor }) {
  const enrollments = useSelector((state) => state.enrollment);

  const dispatch = useDispatch();

  const [siteTour, setSiteTour] = useState({
    run: false,
    steps: [],
  });

  const initializeSteps = () => {
    if (stepsData?.length) {
      setSiteTour({
        run: true,
        steps: [...stepsData],
      });
    }
  };

  const stopTourStatus = () => {
    if (tourFor === "pre") {
      dispatch(services.stopPreEnrollTour());
    } else if (tourFor === "post") {
      dispatch(services.stopPostEnrollTour());
    } else if (tourFor === "notes") {
      dispatch(services.stopNotesTour());
    } else if (tourFor === "certificate") {
      dispatch(services.stopCertificateTour());
    }
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setSiteTour({ run: false });
      stopTourStatus();
    }
  };

  useEffect(() => {
    initializeSteps();
  }, [enrollments.length]);

  return (
    <>
      {siteTour.run ? (
        <Joyride
          continuous
          run={siteTour.run}
          callback={handleJoyrideCallback}
          steps={siteTour.steps.map((step) => ({
            ...step,
            disableBeacon: true,
          }))}
          disableScrolling={true}
          disableOverlay={false}
          showSkipButton={true}
          hideCloseButton
          showProgress
          styles={{
            options: {
              zIndex: 1001,
              textColor: "#000",
              arrowColor: "#fff",
              primaryColor: "var(--menu-item)",
            },
          }}
          locale={{
            back: "Back",
            close: "Close",
            last: "Close",
            skip: "Skip",
            next: "Next",
          }}
        />
      ) : null}
    </>
  );
}

export default React.memo(FeatureTour);
