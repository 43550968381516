import React from "react";

import styles from "./cardSkeleton.module.scss";
import AlumniCardSkeleton from "./alumniCard";

const CardSkeleton = (props) => {
  const { count } = props;
  return (
    <div className={styles.skeletons}>
      {Array.from({ length: count }).map((_, index) => (
        <AlumniCardSkeleton key={index} />
      ))}
    </div>
  );
};

export default CardSkeleton;
