import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";

import styles from "./field.module.scss";
import services from "@/store/services";
import Components from "@/components";
import api from "@/api";

function CommentField(props) {
  const user = useSelector((state) => state.user.users);

  const [showTagSuggestion, setShowTagSuggestion] = useState(false);
  const [tagSuggestions, setTagSuggestions] = useState([]);
  const dispatch = useDispatch();

  const handleCommentInput = (e) => {
    if (!user.email) {
      dispatch(services.toggleSigninModal());
      props.textRef.blur();
    }
    // set the text field from parent component
    props.setText(e);
  };

  const getFollowingsAndFollowers = async (key) => {
    let tags = [
      {
        _id: props?.creatorInfo?._id,
        name: props?.creatorInfo?.name,
        profileImage: props?.creatorInfo?.profileImage,
        username: props?.creatorInfo?.username,
      },
    ];

    // to get following
    let response = await api.getAuthUserFollowing(props.userId, key);
    if (response.status === 200) {
      const data = response?.data?.result?.allFollowings;
      data?.forEach((element) => {
        if (element?.username !== tags[0].username) {
          tags.push(element);
        }
      });
    }

    // to get followers
    // let res = await api.getAuthUserFollowers(props.userId, key);
    // if (res.ok && res.data.success === 200) {
    //   const data = res?.data?.result?.allFollowers;
    //   data?.forEach((element) => {
    //     if (element?.username !== tags[0].username) {
    //       tags.push(element);
    //     }
    //   });
    // }
    setTagSuggestions([...tags]);
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      const lastText = props.text.split(" ").pop();
      if (lastText.includes("@")) {
        // make the api call to get tags
        getFollowingsAndFollowers(lastText.slice(1));
      }
    }, 300);
    // set the current tag key

    return () => clearTimeout(getData);
  }, [props.text]);

  const handleKeypress = (e) => {
    if (!user.email) {
      dispatch(services.toggleSigninModal());
      return;
    }
    if ((e?.keyCode === 13 || e?.which === 13) && !e?.shiftKey) {
      props.handleSubmit(e);
    }
  };

  const handleTagUserClick = (item, e) => {
    e.preventDefault();
    e.stopPropagation();

    const cursorPosition = props.textRef.current.selectionStart;

    if (cursorPosition !== props.text.length) {
      let wordPosition = 0;
      let finalTextArr = props.text.split(" ");
      props.text.split(" ").forEach((word, i) => {
        let oldPosition = wordPosition;
        wordPosition += word.length;
        if (cursorPosition <= wordPosition && cursorPosition > oldPosition) {
          if (word[0] === "@") {
            const username = e.target.id;
            finalTextArr[i] = `@${username} `;
            props.setText(finalTextArr.join(" "));
            const obj = tagSuggestions?.find(
              (item) => item.username === username
            );
            props.changeValidTags([
              ...props.allTags,
              { _id: obj._id, username: obj.username },
            ]);
            return;
          }
        }
        wordPosition++;
      });
    } else {
      const userName = e.target.id;
      const commentArr = props.text.split(" ");
      commentArr[commentArr.length - 1] = "@" + userName;
      handleCommentInput(`${commentArr.join(" ")} `);
      setShowTagSuggestion(false);
      // set the valid tag users
      const obj = tagSuggestions?.find((item) => item.username === userName);
      props.changeValidTags([
        ...props.allTags,
        { _id: obj._id, username: obj.username },
      ]);
      // props.textRef.focus();
    }
  };

  const handleBackSpaceClick = (e) => {
    setShowTagSuggestion(false);

    const arr = props.text.split(" ");
    const length = arr.length;
    const lastWord = arr[length - 1];

    if (
      lastWord[0] === "@" &&
      props.allTags.includes(lastWord.slice(1, lastWord.length))
    ) {
      e.preventDefault();
      props.changeValidTags(
        props.allTags.filter(
          (item) => item?.username !== lastWord.slice(1, lastWord.length)
        )
      );
      const newText = arr.slice(0, length - 1).join(" ");
      props.setText(newText);
    }
  };

  const handleKeyDown = (e) => {
    if (!user.email) {
      dispatch(services.toggleSigninModal());
      return;
    }
    if (e.key === "Backspace") {
      const textField = props.textRef && props.textRef.current.selectionStart;
      const cursorPosition = props.textRef.current.selectionStart;
      const words = e.target.value.split(" ");
      let flag = 0;
      for (let i = 0; i < words.length; i++) {
        const wordStartPosition = e.target.value.indexOf(words[i]);
        const wordEndPosition = wordStartPosition + words[i].length;

        if (
          words[i].includes("@") &&
          cursorPosition > wordStartPosition &&
          cursorPosition <= wordEndPosition
        ) {
          flag = 1;
          break;
        }
      }
      if (
        cursorPosition === e.target.value.length ||
        flag == 0 ||
        !props.text.includes("@")
      ) {
        // if we are removing the last word from the text
        handleBackSpaceClick(e);
      } else {
        e.preventDefault();
        // if we are removing the random word from the text
        let wordPosition = 0;
        let finalTextArr = e.target.value.split(" ");
        e.target.value.split(" ").forEach((word, i) => {
          let oldPosition = wordPosition;
          wordPosition += word.length;
          if (cursorPosition <= wordPosition && cursorPosition > oldPosition) {
            let isTag = props.allTags.filter((_tags) =>
              _tags?.username?.includes(word.slice(1, word.length))
            );
            if (word[0] === "@" && isTag) {
              finalTextArr[i] = "";
              let newTaggedUser = props.allTags.filter(
                (user) => user.username !== isTag[0]
              );
              props.changeValidTags(newTaggedUser);
              return;
            }
          }
          wordPosition++;
        });
        props.setText(finalTextArr.join(" "));
      }
    } else if (e.key === "Enter") {
      handleKeypress(e);
    } else if (e.key === " ") {
      setShowTagSuggestion(false);
    }
  };

  const handleInputChange = (e) => {
    if (!user.email) {
      dispatch(services.toggleSigninModal());
      return;
    }
    const val = e.target.value;
    const textField = props.textRef && props.textRef.current.selectionStart;
    let cursorPosition = props.textRef.current.selectionStart;

    if (cursorPosition !== val.length) {
      let wordPosition = 0;
      val.split(" ").forEach((word, i) => {
        let oldPosition = wordPosition;
        wordPosition += word.length;
        if (cursorPosition <= wordPosition && cursorPosition > oldPosition) {
          if (word[0] === "@") {
            setShowTagSuggestion(true);
            return;
          } else {
            setShowTagSuggestion(false);
          }
        }
        wordPosition++;
      });
    } else {
      if (val.split(" ")[val.split(" ").length - 1][0] === "@") {
        setShowTagSuggestion(true);
      } else {
        setShowTagSuggestion(false);
      }
    }

    handleCommentInput(e.target.value);
    // let valArr = val.split(" ");
    // let lastWord = valArr[valArr.length - 1];
    // if (lastWord[0] === "@") {
    //   setShowTagSuggestion(true);
    // } else setShowTagSuggestion(false);
    // });
  };

  return (
    <article className={`${styles["field-container"]} comment-box-area`}>
      {/* comment suggestion container */}
      {showTagSuggestion ? (
        <div className={styles["tag"]}>
          {tagSuggestions.length > 0 &&
            tagSuggestions.map((item, i) => (
              <>
                <section
                  className={styles["user"]}
                  onClick={(e) => handleTagUserClick(item, e)}
                  id={item?.username}
                  key={item?.username}
                >
                  <div className={styles["image"]} id={item?.user?.username}>
                    <Components.Avatar
                      src={item?.profileImage}
                      alt={item?.name}
                      loader={"md"}
                      errStyle={{ fontSize: "1.3rem" }}
                      className={styles["avatar"]}
                    />
                  </div>
                  <div className={styles["info"]} id={item?.username}>
                    <h1 id={item?.username}>{item?.name}</h1>
                    <p id={item?.username}>{item?.username}</p>
                  </div>
                </section>
              </>
            ))}
        </div>
      ) : null}
      <textarea
        ref={props.textRef}
        value={props.text}
        className={styles["field"]}
        spellCheck="false"
        autoComplete="off"
        autoCorrect="off"
        onKeyPress={handleKeypress}
        onKeyDown={handleKeyDown}
        placeholder="Engage in the comment or tag @cipherschools for any query."
        onChange={handleInputChange}
        id="comment-text-input"
        style={{ userSelect: "all" }}
      />
      <button disabled={!props.text} onClick={props.handleSubmit}>
        <Icon icon={"mdi:send"} />
      </button>
    </article>
  );
}

export default CommentField;
