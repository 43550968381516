import Image from "next/image";
import React from "react";

import styles from "./team.module.scss";

const Card = (props) => {
  return (
    <div className={styles["card-cont"]}>
      <div className={styles["card-img-cont"]}>
        <Image src={props.image} alt="card-image" />
      </div>
      <div className={styles["card-person-name"]}>
        <span>{props.name}</span>
      </div>
      <div className={styles["card-person-role"]}>
        <span>{props.designation}</span>
      </div>
    </div>
  );
};

export default Card;
