"use client";

import React, { useEffect } from "react";
import { Icon } from "@iconify/react";
import ReactDOM from "react-dom";

import styles from "./modal.module.scss";

// ModalProps {
// 	children?: React.ReactNode;
// 	showModal: boolean;
// 	heading?: string;
// 	className?: string;
// 	placement?: string;
// 	footer?: React.ReactNode;
// 	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
// }

const Modal = (props) => {
  const {
    children,
    heading,
    className,
    placement,
    showModal,
    setShowModal,
    footer,
    block,
    noCancel = false,
  } = props;

  useEffect(() => {
    if (document)
      if (showModal) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
  }, [showModal]);

  if (typeof window === "undefined") return null; // Prevent SSR issues

  return (
    <>
      {ReactDOM.createPortal(
        showModal ? (
          <article
            className={`${styles.background} ${placement === "top" ? styles.top : styles.center}`}
            onClick={() => (block ? {} : setShowModal(false))}
          >
            <section
              className={`${styles.modalContainer} ${className}`}
              onClick={(e) => e.stopPropagation()}
              role="dialog"
              aria-labelledby="modal-heading"
              aria-modal="true"
            >
              {heading && (
                <header
                  className={`${styles.header} ${styles["no-" + noCancel]}`}
                >
                  <h2 className={styles.heading}>{heading}</h2>
                  {noCancel ? null : (
                    <button
                      className={styles["cross-left"]}
                      onClick={() => setShowModal(false)}
                    >
                      <Icon icon="iconoir:cancel" />
                    </button>
                  )}
                  {/* <div className={styles.right}></div> */}
                </header>
              )}
              {children}
              {footer && <footer className={styles.footer}>{footer}</footer>}
            </section>
          </article>
        ) : null,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default Modal;
