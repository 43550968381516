"use client";

import React, { useState, useRef, useEffect } from "react";

import styles from "./otp-input.module.scss";

const OtpInput = ({ numInputs = 6, onChange }) => {
  const inputRefs = useRef([]);
  const [otp, setOtp] = useState(new Array(numInputs).fill(""));

  const handleChange = (index, e) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value.slice(-1);
    setOtp(newOtp);
    onChange(newOtp.join(""));

    //move the focus to next input if any number is entered
    if (e.target.value && index < numInputs - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
      onChange(newOtp.join(""));

      //move the focus to previous input if it is empty
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  return (
    <div className={styles["otp-container"]}>
      {otp.map((digit, index) => (
        <input
          key={index}
          ref={(el) => (inputRefs.current[index] = el)}
          type="number"
          value={digit}
          onChange={(e) => handleChange(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          maxLength="1"
        />
      ))}
    </div>
  );
};

export default OtpInput;
