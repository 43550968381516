import React from "react";
import PropTypes from "prop-types";

import styles from "./tooltip.module.scss";

function Tooltip(props) {
  const { placement, title, children, className } = props;

  return (
    <div
      className={`${styles.tooltip} ${styles[placement || "bottom"]} ${className || ""}`}
    >
      {children}
      <div className={styles["tooltip-content"]}>
        <span className={styles["tooltip-text"]}>{title}</span>
      </div>
    </div>
  );
}

Tooltip.propTypes = {
  placement: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Tooltip;
