"use client";

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { socketCreatorReq } from "@/socket";
import SocketContext from "@/socket/Context";
import services from "@/store/services";
import Components from "@/components";
import "./creator.scss";
import api from "@/api";

function CreatorReqModal() {
  const [reqText, setReqText] = useState({
    name: "",
    email: "",
    message: "",
    subject: "",
    contact: "",
    ytChannel: "",
  });
  const { socket } = useContext(SocketContext).SocketState;
  const user = useSelector((state) => state?.user?.users);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.email)
      setReqText((prev) => ({
        ...prev,
        name: user?.name,
        email: user.email,
        contact: user.mobileNo || "",
      }));
  }, [user]);

  const isEmpty = () => {
    if (
      reqText.name === "" ||
      reqText.email === "" ||
      reqText.subject === "" ||
      reqText.message === "" ||
      reqText.contact?.length < 10
    )
      return true;
    else return false;
  };

  async function finalSubmit(payload) {
    try {
      const res = await api.postRequest(payload);
      if (res.data?.success === 201) {
        socketCreatorReq(
          {
            userId: user?._id,
            entityId: res.data?.result._id,
          },
          socket
        );
        dispatch(services.successAlert("Creator access request submitted!"));
        setReqText({
          name: "",
          email: "",
          message: "",
          subject: "",
          contact: "",
          ytChannel: "",
        });
        dispatch(services.toggleCreatorModal());
      } else
        dispatch(
          services.failureAlert(
            res?.data?.error || res.data?.data?.error || "Something went wrong"
          )
        );
      setLoading(false);
    } catch (e) {
      setLoading(false);
      dispatch(services.failureAlert("Can't send creator access request!"));
    }
  }

  const handleOnSubmit = async () => {
    if (user.isCreator) {
      dispatch(services.infoAlert("You are already a creator!"));
      dispatch(services.toggleCreatorModal());
      setLoading(false);
      return;
    }

    if (!isEmpty()) {
      try {
        setLoading(true);
        const res = await api.checkCreatorAccess(reqText.email);
        if (!res.data?.result?.isCreator && res.status !== 404) {
          const newFeedback = {
            message: reqText.message,
            subject: reqText.subject,
            youtube: reqText.ytChannel,
            contact: reqText.contact,
            type: "creator",
            userEntity: {
              name: res.data?.result?.name,
              email: res.data?.result?.email,
              contact: res.data?.result?.mobileNo,
              id: res.data?.result?._id,
            },
          };
          finalSubmit(newFeedback);
        } else {
          setLoading(false);
          if (res.status === 404)
            dispatch(
              services.failureAlert(
                "No registered account found with this email!"
              )
            );
          else dispatch(services.infoAlert("You are already a creator!"));
        }
      } catch (e) {
        setLoading(false);
      }
    }
  };

  return (
    <section>
      <div className="ca-underline" />
      <div className="ca-main-cont">
        <div className="sn-textfield-row">
          <input
            placeholder="Enter Your Name"
            value={reqText.name}
            type="text"
            className="sn-textfield"
            disabled={user.email}
            onChange={(e) =>
              !user.email &&
              setReqText((prev) => ({ ...prev, name: e.target.value }))
            }
          />
          <input
            placeholder="Email Address"
            type="email"
            value={reqText.email}
            className="sn-textfield"
            disabled={user.email}
            onChange={(e) =>
              !user.email &&
              setReqText((prev) => ({ ...prev, email: e.target.value }))
            }
          />
        </div>
        <div className="sn-textfield-row">
          <input
            placeholder="Mobile No"
            value={reqText.contact}
            type="tel"
            className="sn-textfield"
            onChange={(e) =>
              setReqText((prev) => ({
                ...prev,
                contact: e.target.value,
              }))
            }
          />
          <input
            placeholder="Subject you teach on your channel"
            value={reqText.subject}
            type="text"
            className="sn-textfield"
            onChange={(e) =>
              setReqText((prev) => ({ ...prev, subject: e.target.value }))
            }
          />
        </div>
        <input
          placeholder="Youtube channel link (if any?)"
          value={reqText.ytChannel}
          type="url"
          className="sn-textfield"
          onChange={(e) =>
            setReqText((prev) => ({
              ...prev,
              ytChannel: e.target.value,
            }))
          }
        />
        <div className="sn-textfield-msg">
          <textarea
            className="sn-msg-cont"
            value={reqText.message}
            onChange={(e) =>
              setReqText((prev) => ({
                ...prev,
                message: e.target.value,
              }))
            }
            placeholder="Would you be needing any assistance in channel creation on CipherSchools? If yes, please share your contact number and a good time to call"
          />
        </div>
        <div className="sf-btns-cont">
          <Components.LoadingBtn
            loading={loading}
            className={"sf-msg-btn sf-btn-active"}
          >
            <button
              disabled={isEmpty()}
              className={isEmpty() ? "sf-msg-btn" : "sf-msg-btn sf-btn-active"}
              onClick={handleOnSubmit}
            >
              Submit
            </button>
          </Components.LoadingBtn>
        </div>
      </div>
    </section>
  );
}

export default CreatorReqModal;
