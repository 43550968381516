"use client";

import { useDispatch, useSelector } from "react-redux";
import React, { useContext, useEffect, useState } from "react";

import SocketContext from "@/socket/Context";
import styles from "./skills.module.scss";
import services from "@/store/services";
import Components from "@/components";
import api from "@/api";

function SkillsModal({ resetModalEdit, details, rewardModalToggle }) {
  const user = useSelector((state) => state?.user?.users);
  const { socket } = useContext(SocketContext).SocketState;
  const [initialLength, setInitialLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [skills, setSkills] = useState([]);
  const [alert, setAlert] = useState({
    showAlert: false,
    message: "",
  });

  const dispatch = useDispatch();

  // methods
  const handleAddSkill = (skill) => {
    setSkills([...skills, skill]);
  };
  const handleRemoveSkill = (skill, rating) => {
    const newFilteredSkills = skills.filter((e) => {
      return e.title !== skill;
    });
    setSkills([...newFilteredSkills]);
  };

  useEffect(() => {
    function setDefaultVals() {
      if (details && Array.isArray(details)) {
        setSkills([...details]);
        setInitialLength(details?.length);
      }
    }
    setDefaultVals();
  }, []);

  const chechUpdateCp = (slug) => {
    const cpStateArray = user?.profileCpStatus;
    if (Array.isArray(cpStateArray)) {
      if (cpStateArray.includes(slug)) {
        return false;
      }
    }
    return true;
  };

  const handleModalSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (Array.isArray(skills)) {
      const response = await api.updateSkills({ skills: skills });

      if (response.status < 400) {
        const updateCp = chechUpdateCp("skills");
        if (updateCp && initialLength < skills?.length) {
          const cpStateRes = await api.updateProfileCpStatus("skills");
          if (cpStateRes?.success === 200) {
            dispatch(services.updateEngagement(300, socket, user?._id));
            dispatch(services.fetchUser());
            rewardModalToggle(300);
          }
        }
        dispatch(services.successAlert("Skills updated!"));
        setLoading(false);
        dispatch(services.fetchUserDetails());
        resetModalEdit();
        dispatch(services.toggleAddSkillModal());
        return;
      } else {
        setAlert({
          showAlert: true,
          message:
            response.data.error ?? "Can't update skills, please try again",
        });
      }
      setLoading(false);
    } else {
      dispatch(services.failureAlert("Something went wrong!"));
      setLoading(false);
    }
  };

  return (
    <section>
      {loading ? <Components.PageLoader type="page" /> : null}
      {alert.showAlert && (
        <p className={styles.errorHeading}>
          {alert.message[0].toUpperCase() + alert.message.slice(1)}
        </p>
      )}

      <form className={styles.profileModal}>
        <Components.RatedSkillsInput
          label={"Skills"}
          allValues={skills}
          onAdd={handleAddSkill}
          onRemove={handleRemoveSkill}
        />
        <div className={styles["am-btns"]}>
          <button
            className={styles["am-cancel-btn"]}
            onClick={(e) => {
              e.preventDefault();
              resetModalEdit();
              dispatch(services.toggleAddSkillModal());
            }}
          >
            Cancel
          </button>
          <button className={styles["am-save-btn"]} onClick={handleModalSave}>
            Save
          </button>
        </div>
      </form>
    </section>
  );
}

export default SkillsModal;
