import React from "react";
import dynamic from "next/dynamic";
import "react-quill/dist/quill.snow.css";

import "./formatter.css";

const ReactQuill = dynamic(() => import("react-quill"), { ssr: false });

function TextFormatter(props) {
  const {
    id,
    title,
    className,
    value,
    setValue,
    validator = false,
    requiredField = false,
    placeholder,
    disabled = false,
  } = props;

  return (
    <article className={className} id="formatter">
      <p>
        {title}
        {requiredField ? <span className="requiredField"> *</span> : null}
      </p>
      <ReactQuill
        id={id}
        value={value}
        onChange={setValue}
        readOnly={disabled}
        className={`quillContainer ${validator ? "ql1" : "ql2"}`}
        placeholder={placeholder ?? "Insert your description here"}
      />
    </article>
  );
}

export default TextFormatter;
