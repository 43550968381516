"use client";

import React, { useEffect, useState } from "react";

import styles from "./cipher-map.module.scss";
import Components from "@/components";
import api from "@/api";

function CipherMap(props) {
  const { isCreator, userId, getCertificates } = props;
  const [certificatesCount, setCertificatesCount] = useState(0);
  const [learningHours, setLearningHours] = useState(0);
  const [enrollments, setEnrollments] = useState(0);

  useEffect(() => {
    const getCertificatesAndCP = async () => {
      const res = await api.getCertificatesAndCP(userId);
      if (res.status === 200) {
        setCertificatesCount(res?.data?.result?.certificates?.length);
        const cp = res?.data?.result?.totalPoints;
        setLearningHours((cp / 3600).toFixed(2));
        getCertificates(res?.data?.result?.certificates);
        setEnrollments(res?.data?.result?.enrollments);
      }
    };
    getCertificatesAndCP();
  }, [userId]);

  return (
    <article className={styles["full-section"]}>
      {/* All cards comes here! */}
      <div className={styles["cards-section"]}>
        <div className={styles["cards-left"]}>
          <div className={styles["card"]}>
            {/* <h5>{(learningHours / 60).toFixed(2)}</h5> */}
            <h5>{learningHours}</h5>
            <p>
              <span>Hours</span> spent on learning!
            </p>
          </div>
        </div>
        <div className={styles["cards-right"]}>
          <div className={styles["card-center"]}>
            <h5>{enrollments}</h5>
            <p>
              <span>Enrolled</span> courses
            </p>
          </div>
          <div className={styles["card-center"]}>
            <h5>{certificatesCount}</h5>
            <p>
              <span>Certificates</span> aquired
            </p>
          </div>
        </div>
      </div>
      <Components.HeatMap isDemo={isCreator} user={userId} dark={true} />
      <div className={styles["map-content"]}>
        <span>Less</span>
        <svg width="12" height="12">
          <rect width="12" height="12" fill="var(--heat-map-empty)"></rect>
        </svg>
        <svg width="12" height="12">
          <rect width="12" height="12" fill="#f3dcc4"></rect>
        </svg>
        <svg width="12" height="12">
          <rect width="12" height="12" fill="#e5b583"></rect>
        </svg>
        <svg width="12" height="12">
          <rect width="12" height="12" fill="#d48432"></rect>
        </svg>
        <svg width="12" height="12">
          <rect width="12" height="12" fill="#c87a2a"></rect>
        </svg>
        <span>More</span>
      </div>
    </article>
  );
}

export default CipherMap;
