"use client";

import React, { useState } from "react";
import { useDispatch } from "react-redux";

import styles from "./stage.module.scss";
import services from "@/store/services";
import utils from "@/utils";
import apis from "@/api";

const visibility = [
  {
    key: 0,
    value: "visible",
  },
  {
    key: 1,
    value: "hidden",
  },
];

function AddStage(props) {
  const { stages, stage } = props;

  const [stageTitle, setStageTitle] = useState(
    stage?.title || "Course Details"
  );
  const [stageVisibility, setStagesVisibility] = useState(
    visibility.find((v) => v.value === stage?.visibility)
  );

  const dispatch = useDispatch();

  const handleAddStage = async () => {
    if (
      stageTitle !== "" &&
      stages >= 0 &&
      stageVisibility &&
      utils.getFromLocalStorage("curr_course")
    ) {
      let response;
      if (stage) {
        response = await apis.updateStage({
          cid: utils.getFromLocalStorage("curr_course"),
          sid: stage?._id,
          details: {
            title: stageTitle,
            visibility: stageVisibility.value.toLowerCase(),
          },
        });
      } else {
        response = await apis.createStage({
          title: stageTitle,
          stageNumber: stages + 1,
          courseId: utils.getFromLocalStorage("curr_course"),
          visibility: stageVisibility.value.toLowerCase(),
        });
      }

      if (response?.status < 400) {
        dispatch(services.fetchStage());
        dispatch(
          services.successAlert(
            `Stages is successfuly ${response.status === 200 ? "updated" : "added"}!`
          )
        );
        dispatch(services.toggleStageModal());
      } else {
        dispatch(
          services.failureAlert(response.data?.error || "Something went wrong!")
        );
      }
    } else {
      dispatch(services.warningAlert("Some fields are empty or start over"));
    }
  };
  return (
    <section className={styles["container"]}>
      <input
        type="text"
        required={true}
        value={stageTitle}
        placeholder={`Stage title`}
        onChange={(e) => setStageTitle(e.target.value)}
      />
      <div className={styles["row"]}>
        <input
          type="number"
          readOnly={true}
          className={styles["curr-stage"]}
          value={stage?.stageNumber || stages + 1}
        />
        <div className={styles["visibility-displayer"]}>
          <div className={styles["selected"]}>
            <p className={styles["name"]}>{stageVisibility?.value}</p>
          </div>
          <div className={styles["options"]}>
            <ul>
              {visibility.map((item, index) => {
                return (
                  <li key={index} onClick={() => setStagesVisibility(item)}>
                    {item.value}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <button onClick={handleAddStage}>Add</button>
    </section>
  );
}

export default AddStage;
