"use client";

import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { Icon } from "@iconify/react";

import services from "@/store/services";
import Components from "@/components";
import utils from "@/utils";

import styles from "./video.module.scss";

function SuggestionVideo(props) {
  const {
    video,
    type,
    selected,
    className,
    nextVideoCb,
    stageId,
    showResource = true,
    cardPurpose = "video-sidebar",
  } = props;
  const { testLinkModal } = useSelector((state) => state.modal);
  const { isEnrolled } = useSelector((state) => state.video);
  const user = useSelector((state) => state?.user?.users);
  const [isCompleted, setIsCompleted] = useState(false);
  const dispatch = useDispatch();

  const router = useRouter();

  useEffect(() => {
    if (isEnrolled && isEnrolled.seenContents) {
      const status = isEnrolled.seenContents.some(
        (vd) => vd.entityId === video._id
      );

      setIsCompleted(status);
    }
  }, [isEnrolled, video._id]);

  const handleResource = (categoryItem) => {
    window.open(categoryItem.value, "_blank");
  };

  return (
    <a
      href={nextVideoCb(video._id, video.seoTitle, video.type)}
      onClick={(e) => {
        e.preventDefault();
        if (video.type === "mcq") {
          if (cardPurpose === "video-sidebar" && !isEnrolled) {
            dispatch(
              services.warningAlert("You're not enrolled in this course!")
            );
            return;
          } else if (
            cardPurpose === "batch-content" &&
            video?.type === "isLocked"
          ) {
            dispatch(services.warningAlert("You cannot access locked stage"));
          }

          dispatch(
            services.updateTestInfo({
              testInfo: {
                title: video?.title,
                description: video?.description,
                testLink: video?.attachment?.[0]?.testLink,
                stageId: stageId,
                courseId: video?.courseId,
              },
            })
          );
          !testLinkModal && dispatch(services.toggleTestLinkModal(true));
          return;
        }
        const newRoute = nextVideoCb(video._id, video.seoTitle, video.type);

        newRoute &&
          router.push(nextVideoCb(video._id, video.seoTitle, video.type));
      }}
    >
      <article
        id={video?._id}
        className={`${styles["container"]} ${
          selected ? `${styles["selected"]} ${className}` : className
        } `}
      >
        {video?.type === "isLocked" ? (
          <Icon icon="material-symbols:lock-outline" />
        ) : video?.type === "lecture" ? (
          <Icon icon="carbon:play-filled" />
        ) : video?.type === "article" ? (
          <Icon icon="material-symbols-light:docs" />
        ) : (
          <Icon icon="healthicons:i-exam-multiple-choice" />
        )}

        <div>
          <Components.Tooltip title={video.title}>
            <h3>{video.title}</h3>
          </Components.Tooltip>
          <div className={styles["metadata"]}>
            <div className={styles["duration"]}>
              {video?.type === "lecture" ? (
                <>
                  <Icon icon={"mdi:alarm"} />
                  <p>{utils.convertTime(Number(video.duration))}</p>
                </>
              ) : video?.type === "article" ? (
                <>
                  <Icon icon={"mdi:alarm"} />
                  <p>
                    {video.duration
                      ? utils.convertTime(Number(video.duration))
                      : "2 min"}
                  </p>
                </>
              ) : (
                <>
                  <Icon icon={"mdi:alarm"} />
                  <p>
                    {video.duration
                      ? utils.convertTime(Number(video.duration))
                      : "1 hr"}
                  </p>
                </>
              )}
            </div>
            {showResource && (
              <div className={styles["resource-container"]}>
                {video?.resources?.length > 0 ? (
                  <Components.Filter
                    filter={{ value: "Resource" }}
                    className={styles.resource}
                    optionsClass={styles.filterOptions}
                    list={video?.resources?.split(",")?.map((item) => ({
                      value: item.trim(),
                    }))}
                    handleChange={handleResource}
                  />
                ) : null}

                {type ? (
                  user?.email && isEnrolled ? (
                    <Icon
                      icon={isCompleted ? "icons8:checked" : "akar-icons:radio"}
                      className={isCompleted ? styles["checked"] : ""}
                    />
                  ) : null
                ) : (
                  video?.creatorInfo && (
                    <Components.Avatar
                      src={video?.creatorInfo?.[0]?.profileImage}
                      alt={video?.creatorInfo?.[0]?.name}
                      loader="vsm"
                      errStyle={{ fontSize: "0.6rem" }}
                      onClick={() =>
                        router.push(
                          `/profile/${video?.creatorInfo?.[0]?.username}`
                        )
                      }
                    />
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </article>
    </a>
  );
}

export default SuggestionVideo;
