"use client";

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import Link from "next/link";

import styles from "./profile-header.module.scss";
import services from "@/store/services";
import { Icon } from "@iconify/react";
import Components from "@/components";
import utils from "@/utils";
import apis from "@/api";

function ProfileHeader(props) {
  const { person, userId, showContact } = props;

  const [followers, setFollowers] = useState(person?.followers_count || 0);
  const userDetails = useSelector((state) => state?.userDetails);
  const user = useSelector((state) => state.user.users);
  const dispatch = useDispatch();
  const router = useRouter();

  const [socialLinks, setSocialLinks] = useState([]);

  useEffect(() => {
    const getFollowerCount = async () => {
      if (user?.email) {
        const res = await apis.getFollowerCount();
        if (res.data?.success === 200) {
          setFollowers(res.data?.result);
        }
      }
    };

    getFollowerCount();
  }, [user]);

  useEffect(() => {
    const socialLinks = [];
    userDetails?.social?.map((item) => {
      let linkIcon;
      if (item?.linkName === "linkedin") linkIcon = "mdi:linkedin";
      else if (item?.linkName === "github") linkIcon = "akar-icons:github-fill";
      else linkIcon = "streamline:web";
      let obj = {
        name: item?.linkName,
        link: item?.link,
        icon: linkIcon,
        active: item?.link === "" ? "link-greyed" : "link-active",
      };
      socialLinks.push(obj);
    });
    setSocialLinks([...socialLinks]);
  }, []);

  return (
    <section className={styles["container"]}>
      <div className={styles["box"]}>
        <div className={styles["content"]}>
          <div className={styles["user-avatar"]} id="profile-avatar-icon">
            <Components.Avatar
              src={userId ? person?.profileImage : user?.profileImage}
              alt={userId ? person?.name : user?.name || ""}
              loader={"xxl"}
              errStyle={{ fontSize: "2rem" }}
              className={styles["avatar"]}
            />
            {!userId ? (
              <div
                className={styles["pencil"]}
                onClick={() => dispatch(services.toggleProfileModal())}
              >
                <Icon icon="mdi:pencil" />
              </div>
            ) : null}
          </div>
          <div className={styles["user-details"]}>
            <div id="pf-user-side">
              {!showContact ? (
                <h2 className={styles["greeting"]}>Hello,</h2>
              ) : null}
              <Components.Tooltip
                placement="bottom"
                title={userId ? person?.name : user?.name || ""}
              >
                <h1>{userId ? person?.name : user?.name}</h1>
              </Components.Tooltip>
              <h2 className={styles["user-cred"]}>
                {userId ? person?.email : user?.email}
              </h2>
              {showContact ? (
                <Link
                  id="view-public-profile-btn"
                  className={styles["contact-text"]}
                  href={`/profile/${user?.username}`}
                >
                  View public profile
                </Link>
              ) : null}
            </div>
            <div className={styles["right-side"]}>
              {
                <Components.Tooltip placement="bottom" title="Followers">
                  <div
                    className={styles["follower-no"]}
                    onClick={() => router.push("/followers")}
                  >
                    {utils.formatNumber(followers)} Followers
                  </div>
                </Components.Tooltip>
              }
              {userId && (
                <Components.FollowBtn
                  user={person}
                  className={`${styles["follow-btn"]}`}
                />
              )}
              {/* for the icons */}
              <div className={styles["contact-link-container"]}>
                {socialLinks?.map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      {item.link !== "" ? (
                        <a target="_blank" href={item.link}>
                          <Icon
                            className={styles["contact-link-icon"]}
                            icon={item.icon}
                          />
                        </a>
                      ) : (
                        <button
                          className={styles["link-desabled-btn"]}
                          target="_blank"
                          onClick={(e) => {
                            dispatch(services.toggleEditAboutModal());
                          }}
                        >
                          <Icon
                            className={styles["contact-link-icon"]}
                            icon={item.icon}
                          />
                          <Icon
                            className={styles["contact-empty-link-icon"]}
                            icon="material-symbols:block"
                          />
                        </button>
                      )}
                    </React.Fragment>
                  );
                })}
                {/* <a target="_blank" href={""}>
                    <Icon
                      className={styles["contact-link-icon"]}
                      icon="mdi:linkedin"
                    />
                  </a>
                  <a target="_blank" href="#">
                    <Icon
                      className={styles["contact-link-icon"]}
                      icon="akar-icons:github-fill"
                    />
                  </a>
                  <a target="_blank" href="#">
                    <Icon
                      className={styles["contact-link-icon"]}
                      icon="streamline:web"
                    />
                  </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProfileHeader;
