"use client";

import React, { useState } from "react";

import styles from "./skills.module.scss";

function SkillBox({ onRemoval, skillName, rating }) {
  const handleRemoval = (e) => {
    e.preventDefault();
    onRemoval(skillName, rating);
  };

  return (
    <div className={styles["skill-box"]}>
      <span>{skillName}</span>
      <span className={styles["rating"]}>{rating}</span>
      <button onClick={handleRemoval} className={styles["skill-box-btn"]}>
        X
      </button>
    </div>
  );
}

function SkillsInput(props) {
  const { label, allValues, onAdd, onRemove } = props;

  const [skill, setSkill] = useState("");

  const handleAddSkill = (e) => {
    e.preventDefault();
    if (skill === "") return;
    onAdd(skill);
    setSkill("");
  };

  const handleRemoveSkill = (_skill) => {
    onRemove(_skill);
  };

  return (
    <div className={styles["profileModal-input-field-container"]}>
      <div className={styles["input-label"]}>{label}</div>
      <div className={styles["input-field"]}>
        <input
          className={styles["normal-text-input"]}
          placeholder={"python, react, etc..."}
          value={skill}
          onChange={(e) => setSkill(e.target.value)}
        />
        <button onClick={handleAddSkill} className={styles["btn-add-skill"]}>
          Add
        </button>
      </div>
      {allValues?.length ? (
        <div className={styles["skills-display"]}>
          {allValues?.map((skill, i) => (
            <SkillBox skillName={skill} key={i} onRemoval={handleRemoveSkill} />
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default SkillsInput;
