import { useDispatch } from "react-redux";
import React from "react";

import styles from "../policy.module.scss";
import services from "@/store/services";
import Components from "@/components";
import utils from "@/utils";

function PolicyFooter({ page, changePage, isEnabled }) {
  const dispatch = useDispatch();

  const CipherpointsIntroFooter = (
    <>
      <Components.Button
        name={"Next"}
        // disable={!isEnabled}
        className={styles.nextBtn}
        handleClick={() => changePage(2)}
      />
    </>
  );

  const PolicyUpdatesFooter = (
    <>
      <Components.Button
        name={"Back"}
        // disable={!isEnabled}
        className={styles.backBtn}
        handleClick={() => changePage(1)}
      />
      <Components.Button
        name={"I Understand"}
        // disable={!isEnabled}
        className={styles.nextBtn}
        handleClick={() => {
          utils.addToLocalStorage("policy-modal", true);
          dispatch(services.togglePolicyModal());
        }}
      />
    </>
  );
  return (
    <footer className={styles.footer} r>
      {page === 1 ? CipherpointsIntroFooter : PolicyUpdatesFooter}
    </footer>
  );
}

export default PolicyFooter;
