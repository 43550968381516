import Link from "next/link";
import React from "react";

import styles from "./users.module.scss";
import Components from "@/components";

function SuggestedUserCard({ user, showCP, points }) {
  return (
    <Link href={`/profile/${user?.username}`}>
      <article className={styles["card"]}>
        <div className={styles["image"]}>
          <Components.Avatar
            src={user?.profileImage ?? "none"}
            alt={user?.name || ""}
            loader={"md"}
            className={styles["avatar"]}
            errStyle={{ fontSize: "1rem" }}
          />
        </div>
        <div className={styles["details"]}>
          <h6>
            {user?.name?.length > 15
              ? user?.name?.slice(0, 15) + "..."
              : user.name}
          </h6>
          {showCP ? (
            <p>{(points / 3600).toFixed(2)} hours spent on learning</p>
          ) : (
            <p>{user.username}</p>
          )}
        </div>
      </article>
    </Link>
  );
}

export default React.memo(SuggestedUserCard);
