"use client";

import React, { useRef, useState } from "react";
import { Icon } from "@iconify/react";

import styles from "./dot.module.scss";

const DotMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const { options } = props;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (action) => {
    if (action) {
      action();
    }
    setIsOpen(false);
  };

  return (
    <article ref={menuRef} className={styles["dot-menu"]}>
      <Icon
        icon={"mage:dots"}
        onClick={toggleMenu}
        className={styles["menu-icon"]}
      />
      {isOpen && (
        <div
          className={styles["menu-options"]}
          onMouseLeave={() => setIsOpen(false)}
        >
          {options.map(({ id, title, action, icon }) => (
            <div
              key={id}
              className={styles["menu-option"]}
              onClick={() => handleOptionClick(action)}
            >
              {icon && <Icon icon={icon} className={styles["option-icon"]} />}
              <span className={styles["option-title"]}>{title}</span>
            </div>
          ))}
        </div>
      )}
    </article>
  );
};

export default DotMenu;
