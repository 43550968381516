"use client";

import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Components from "@/components";
import services from "@/store/services";
import "./upload.scss";

function UploadLinkModal({ saveCallback }) {
  const [filename, setFilename] = useState("");
  const dispatch = useDispatch();

  const handleModalSave = () => {
    saveCallback(filename);
    dispatch(services.toggleLinkUploadModal());
  };

  return (
    <section>
      <div className="pm-input-box">
        <Components.TextInput
          type="text"
          value={filename}
          label={"Folder Name"}
          placeholder="Enter folder name"
          onChange={(e) => setFilename(e)}
        />
      </div>
      <div className="pm-pass-btns">
        <button
          className="pm-cancel-btns"
          onClick={() => dispatch(services.toggleLinkUploadModal())}
        >
          Cancel
        </button>
        <button className="pm-save-btns" onClick={handleModalSave}>
          Save
        </button>
      </div>
    </section>
  );
}

export default UploadLinkModal;
