"use client";

import { useSelector } from "react-redux";
import React, { useMemo } from "react";
import Image from "next/image";

import styles from "./points.module.scss";
import Icons from "@/assets/icons";
import utils from "@/utils";

function TimePointModal() {
  const user = useSelector((state) => state?.user?.users);
  const points = useSelector((state) => state?.points);

  const setCPoints = useMemo(() => {
    if (user?.email) {
      const res = utils.calculateCipherPoints(points);
      let formatString = "";
      if (res < 10) formatString = "0" + res;
      else formatString = res;
      return formatString;
    } else {
      return "0";
    }
  }, [user, points]);

  return (
    <article className={styles["rpm-box"]}>
      <div className={styles["rpm-box"]}>
        <div className={styles["rpm-top"]}>
          <Image
            src={Icons.WatchPoints}
            className={styles["rpm-watchpoint"]}
            alt="watchpoint-icon"
            width={"25px"}
            height={"25px"}
          />
          <div className={styles["rpm-point-title"]}>
            Points : <span>{setCPoints}</span>
          </div>
        </div>
        <a className={styles["rpm-point-text"]} href="/rewards#updated-policy">
          Know more about cipherpoints
        </a>
        <a
          className={`${styles["rpm-redeam-btn"]} ${styles["rpm-disabled"]}`}
          href="/rewards"
        >
          <span>Redeem Now</span>
        </a>
      </div>
    </article>
  );
}

export default TimePointModal;
