"use client";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import styles from "./styles.module.scss";
import services from "@/store/services";
import Components from "@/components";
import apis from "@/api";

function AddResourceModal({ close, batchId, editData }) {
  const [data, setData] = useState({
    title: "",
    description: "",
    url: "",
  });
  const dispatch = useDispatch();

  const handleAddResource = async () => {
    const res = await apis.addResource({
      title: data.title,
      description: data.description,
      url: data.url,
      batchId: batchId,
    });

    if (res.status === 201) {
      dispatch(services.successAlert("Resources added successfully!"));
      close();
    } else {
      dispatch(
        services.failureAlert(
          res.data?.error ||
            res.data?.result?.error ||
            "Something went wrong, please try again!"
        )
      );
    }
  };

  const handleUpdateResource = async () => {
    const res = await apis.updateResource({
      _id: editData._id,
      title: data.title,
      description: data.description,
      url: data.url,
    });

    if (res.data?.success === 200) {
      dispatch(services.successAlert("Resources updated successfully!"));
      close();
    } else {
      dispatch(
        services.failureAlert(
          res.data?.error ||
            res.data?.result?.error ||
            "Something went wrong, please try again!"
        )
      );
    }
  };

  const isEmpty = () => {
    if (!data?.title?.length || !data?.description?.length) return true;
    return false;
  };

  useEffect(() => {
    if (editData !== null) {
      setData((p) => ({
        ...p,
        title: editData?.title,
        description: editData?.description,
        url: editData?.url || "",
      }));
    }
  }, [editData]);

  return (
    <section className={styles.container}>
      <Components.TextInput
        label={"Title *"}
        placeholder={
          "Linked List Practice Questions Based on Traversal & Loop "
        }
        value={data.title}
        onChange={(val) => setData((p) => ({ ...p, title: val }))}
      />
      <Components.TextArea
        label={"Description *"}
        placeholder={
          "This is a prctice question sheet based on linked list that contains 10 questions on Traversal and 5 questions based on Loop."
        }
        rows={2}
        value={data.description}
        onChange={(e) =>
          setData((p) => ({ ...p, description: e.target.value }))
        }
      />
      <Components.TextInput
        label={"Link *"}
        placeholder={"Add the resource url here"}
        value={data.url}
        onChange={(val) => setData((p) => ({ ...p, url: val }))}
      />
      <div className={styles.footer}>
        {editData !== null ? (
          <Components.Button
            name="Update"
            disable={isEmpty()}
            className={styles.btn}
            handleClick={handleUpdateResource}
          />
        ) : (
          <Components.Button
            name="Add"
            disable={isEmpty()}
            className={styles.btn}
            handleClick={handleAddResource}
          />
        )}
      </div>
    </section>
  );
}

export default AddResourceModal;
