"use client";

import InfiniteScroll from "react-infinite-scroll-component";
import React, { useEffect, useState } from "react";
import { useParams } from "next/navigation";

import styles from "./content.module.scss";
import Components from "@/components";
import sk from "@/skeletons";
import apis from "@/api";

function SuggestedVideoList(props) {
  const [sugVideos, setSugVideos] = useState(null);
  const [page, setPage] = useState(1);
  const params = useParams();

  const { video } = params;
  const { nextVideoCb } = props;

  useEffect(() => {
    const fetchVideos = async () => {
      const res = await apis.getsuggestionvideosV2(video, page, 20);
      if (res.status === 200) {
        setSugVideos(res.data?.result);
      }
    };

    fetchVideos();
  }, [video]);

  const handleLoadMore = async () => {
    const res = await apis.getsuggestionvideos(video, page + 1, 20);
    if (res.status === 200) {
      setPage((prev) => prev + 1);
      setSugVideos((prev) => ({
        ...res?.data?.result,
        data: [...prev.data, ...res.data?.result.data],
      }));
    }
  };

  return (
    <InfiniteScroll
      dataLength={sugVideos && sugVideos?.data?.length} //This is important field to render the next data
      next={handleLoadMore}
      hasMore={
        sugVideos &&
        sugVideos?.data?.length &&
        sugVideos?.totalCount > sugVideos?.data?.length
      }
      loader={<sk.RowCardSkeleton number={10} type="sk-side-video-card" />}
      className={styles["lists"]}
      id="next-videos"
    >
      {sugVideos?.data?.map((video, index) => (
        <Components.SuggestionCard
          key={index}
          id={index}
          video={video}
          type={"lecture"}
          selected={false}
          nextVideoCb={nextVideoCb}
        />
      ))}
    </InfiniteScroll>
  );
}

function Videos(props) {
  return <SuggestedVideoList nextVideoCb={props.nextVideoCb} />;
}

export default React.memo(Videos);
